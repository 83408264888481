import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawGisMapComponent } from './draw-gis-map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapShowModule } from '../map-show';
import { MapToolModule } from '../map-tool';

@NgModule({
  declarations: [DrawGisMapComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MapToolModule, MapShowModule],
  exports: [DrawGisMapComponent],
  entryComponents: [DrawGisMapComponent],
})
export class DrawGisModuleModule {}
