import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { AuthGuard } from '@service/common/acl/auth-guard.service';
import { NavGuard } from '@service/common/nav-guard.service';
import { NbAuthComponent } from './pages/auth/auth.component';
import { NbLoginComponent } from './pages/auth/login/login.component';
import { NbLogoutComponent } from './pages/auth/logout/logout.component';
import { NbRegisterComponent } from './pages/auth/register/register.component';
import { NbRequestPasswordComponent } from './pages/auth/request-password/request-password.component';
import { NbResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/pages', pathMatch: 'full' },
  {
    path: 'pages',
    canActivate: [AuthGuard, NavGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'auth/login' }, // 通配符路由,redirectTo重定向,当URL无效时跳转到'auth'
];

const config: ExtraOptions = {
  useHash: environment.useHash,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
