import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as JsEncryptModule from 'jsencrypt';
import { CacheService } from './cache/public_api';
import { isJson } from '@shared/utils/json';
import { Buffer } from 'buffer';
export interface EncryptionIndex {
  pubKey?: string;
  splitlen?: number;
  splitmode?: number;
}
@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  private privateKey = `-----BEGIN RSA PRIVATE KEY-----
    MIICWgIBAAKBgGnQ49OIMtgLXBEQXk12E3l2RlDHISATnDzDh4uCkjbl3X8CC3qc
    f4sys9TDuP6V7tl9WSTpJKulw+5jDrKylnvVzUYZXd7XNN8bFGnK/nfgM28oYv81
    j1KEF2muCNIvqZrJHwLlhYN5p+Q80SvdoFJTu62SocDE5c9IAXzzsuyTAgMBAAEC
    gYAtSAvGgLkpRKswTi3cvhOVXay3co9VV677Pb0qUUTao0FG0Ywx+viFi7vy55Kp
    t2WPwpk8N1zO60/OGqu2gr/sTNtZPzRU/YxHAqMhIijrWkfZb4FvMHnZV45I9Nam
    4cw6ahs6gPoB2SEpGc63gBjd22zcwaxjGFCZ7sUzIP+10QJBAMdamisDwslq2GgX
    oEI5krWU9v/LiUVxZbgxFs1bnwH/BE4uomH6t2BvwCk6X46TPHyx/ulYeKK9c/Qe
    o8P5YakCQQCH4ic4blqn8+IIMqLyX2Tp46LgD20VnXmMp2YCWqD+2M1IZkMQfraQ
    WedrhcVthtsR73k8/PaOuydr4gcbx/nbAkAeozX+Vl2hRuJrXPYsN1zsuzAar9Uw
    7vSLEkBG30XYsLCgs1oRZM6563LE40u8RbSOFWzvv8EKgcNISU0x2SnBAkAkAJOL
    rBFw9nko2uG5ffTq01lQLEYmt/aAgcnRTdniK+cYC6AxOqF8VZWvM14/yr84XU6u
    Dgf1gTjMqLJhdfaJAkAF/MAB8na7SwEveQAZ8OUaoZMYVOvJMJgd28PGIMDBjI60
    vdTn2oSOakfQh1PYIDj1YkFYRADnwk//Ire6zguB
    -----END RSA PRIVATE KEY-----`;
  private publicKey = `-----BEGIN PUBLIC KEY-----
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGnQ49OIMtgLXBEQXk12E3l2RlDH
    ISATnDzDh4uCkjbl3X8CC3qcf4sys9TDuP6V7tl9WSTpJKulw+5jDrKylnvVzUYZ
    Xd7XNN8bFGnK/nfgM28oYv81j1KEF2muCNIvqZrJHwLlhYN5p+Q80SvdoFJTu62S
    ocDE5c9IAXzzsuyTAgMBAAE=
    -----END PUBLIC KEY-----`;
  /**
   * 登录前密钥
   */
  public signPublicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAh1HGQexxHmBOoxx42gmUvcQs4lXRTQWvA4qNt3UrNKkqeNWCQP5EY9VKOxAYH0XQ98R9V30KSrTHQ6JHWw7PBjR7e9B+JppgcL0/yb40PLV8JCNFnox0AU8vfgYk7DZsarGQpYY21QS3yilP+szTlqGilruI2aAKAaGek4Ry+CNdXA9LnPt2nhtZYGSGhJ1eJaGT7wLVTxUxutBj327gaWrlVobn3Bz663MkEKM9a2jQWOgOyjfNnq6CP7WpJG/aFjeMQQyRsm7W64SWc99b9+jkwqNKCshtenjpY9RzyRTHPswFrFJHhgT7RIxy+fGs/H/1Br3HJ8Ch8bkPv25XMQIDAQAB`;

  /**
   * 登录后密钥
   */
  private _encryptionIndex: EncryptionIndex;

  get encryptionIndex(): EncryptionIndex {
    return this._encryptionIndex ? this._encryptionIndex : this.cacheSrv.get('AUTH_ENCRYPTION_INDEX', { mode: 'none' });
  }
  set encryptionIndex(v) {
    this.cacheSrv.set('AUTH_ENCRYPTION_INDEX', v);
    this._encryptionIndex = v;
  }


  constructor(private cacheSrv: CacheService) { }

  rsaEncrypt(text: string) {
    const encrypt = new JsEncryptModule.JSEncrypt();
    encrypt.setPublicKey(this.publicKey);
    return encrypt.encrypt(text);
  }
  rsaDecrypt(text: string) {
    const decrypt = new JsEncryptModule.JSEncrypt();
    decrypt.setPrivateKey(this.privateKey);
    return decrypt.decrypt(text);
  }

  rsaEncryptWithPk(text: string, pkey: string) {
    const encrypt = new JsEncryptModule.JSEncrypt();
    encrypt.setPublicKey(pkey);
    return encrypt.encrypt(text);
  }
  rsaDecryptWithPk(text: string, pkey: string) {
    const decrypt = new JsEncryptModule.JSEncrypt();
    decrypt.setPrivateKey(pkey);
    return decrypt.decrypt(text);
  }

  fineOneEncrypt(obj: any) {
    if (!isJson(obj)) {
      obj = JSON.stringify(obj);
    }
    const str = new Buffer(obj).toString('base64');
    return this.splitTwo(str);
  }

  splitTwo(str: string) {
    const limitLen = this.encryptionIndex.splitlen;
    const result: any = {};
    if (str.length <= limitLen) {
      result.pub_data = this.rsaEncryptWithPk(str, this.encryptionIndex.pubKey);
      result.data = null;
    } else {
      if (this.encryptionIndex.splitmode === 0) {
        result.pub_data = this.rsaEncryptWithPk(str.substring(0, limitLen), this.encryptionIndex.pubKey);
        result.data = str.substring(limitLen);
      } else {
        result.pub_data = this.rsaEncryptWithPk(str.substring(str.length - limitLen), this.encryptionIndex.pubKey);
        result.data = str.substring(0, str.length - limitLen);
      }
    }
    return result;
  }

  splitLen(str: string, limitLen: number) {
    let result = [];
    if (str.length < limitLen) {
      result[0] = str;
    } else {
      result = str.match(new RegExp(`.{${limitLen}}`, 'g'));
      if (str.length > result.join('').length) {
        result.push(str.substring(result.join('').length));
      }
    }
    return result;
  }
}
