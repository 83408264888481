import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from 'app/logic/services/common/pagination';

/**
 * pagination 分页
 */
@Component({
  selector: 'c-pagination',
  templateUrl: './c-pagination.html',
})
export class CPagination {
  @Input() pagination: Pagination = new Pagination(); //图表配置参数
  @Output() loadData: any = new EventEmitter(); //输出回调事件
  currPage: number = 1;
  constructor() {}

  /**
   * 首页
   */
  onFirstPage() {
    if (this.pagination.start > 1) {
      this.pagination.start = 1;
      this.currPage = this.pagination.start;
      this.loadData.emit();
    }
  }

  /**
   * 上一页
   */
  onPreviousPage() {
    if (this.pagination.start > 1) {
      this.pagination.start--;
      this.currPage = this.pagination.start;
      this.loadData.emit();
    }
  }

  /**
   * 下一页
   */
  onNextPage() {
    if (this.pagination.start < this.pagination.pageCount) {
      this.pagination.start++;
      this.currPage = this.pagination.start;
      this.loadData.emit();
    }
  }

  /**
   * 尾页
   */
  onLastPage() {
    if (this.pagination.start < this.pagination.pageCount) {
      this.pagination.start = this.pagination.pageCount;
      this.currPage = this.pagination.start;
      this.loadData.emit();
    }
  }

  /**
   * 页码跳转
   */
  onJumpPage() {
    const regx = /^[1-9]\d*$/;
    if (regx.test(this.currPage + '') && this.currPage <= this.pagination.pageCount) {
      this.pagination.start = this.currPage;
      this.loadData.emit();
    } else {
      this.currPage = this.pagination.start;
    }
  }
}
