import { NgModule } from '@angular/core';

import { ThemeModule } from '../../../@theme/theme.module';
// import { UtilsService } from "../../../providers/utils.service";
import { PasswordComponent } from './password/password.component';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
// import { ValidatorModule } from "../../../directive/validator/validator.module";
// import { DisabledModule } from "../../../pipes/disabled/disabled.module.pipe";
import { CalendarModule } from "primeng/primeng";
import { SharedModule } from '@shared';

@NgModule({
  imports: [
    ThemeModule,
    // PasswordComponent,
    NgxDatatableModule,
    // ValidatorModule,
    // DisabledModule,
    CalendarModule,
    SharedModule,
  ],
  declarations: [
    PasswordComponent,
  ],
  entryComponents: [
    PasswordComponent,
  ],
  providers: [
    // { provide: 'utilsService', useClass: UtilsService }
  ]
})
export class HeaderModule { }
