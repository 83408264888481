import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapDevicePreviewComponent } from './map-device-preview/map-device-preview.component';
import { MapShowComponent } from './map-show.component';

const COMPONENTS = [MapShowComponent, MapDevicePreviewComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: COMPONENTS,
  entryComponents: [MapDevicePreviewComponent],
})
export class MapShowModule {}
