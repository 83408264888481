import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { F1CalendarComponent } from './f1-calendar/f1-calendar.component';
import { ScheduleComponent } from './schedule/schedule.component';
import {
  NzButtonModule,
  NzDatePickerModule,
  NzGridModule,
  NzIconModule,
  NzInputModule,
  NzModalModule,
  NzSelectModule,
  NzSpinModule,
  NzTableModule,
  NzTreeModule,
  NzTreeSelectModule,
  NzTabsModule,
  NzInputNumberModule,
  NzDividerModule,
} from 'ng-zorro-antd';
import { F1DirectiveModule } from '../../directives/directive.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzFormModule } from 'ng-zorro-antd/form';
import { TimeRangeModule } from '../time-range/time-range.module';
import { MaterialSelectComponent } from './material/material.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

const COMPONENTS = [F1CalendarComponent, ScheduleComponent, MaterialSelectComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzTreeModule,
    NzSpinModule,
    NzFormModule,
    NzTreeSelectModule,
    NzInputNumberModule,
    NzTabsModule,
    NzDatePickerModule,
    NzModalModule,
    DragDropModule,
    F1DirectiveModule,
    TimeRangeModule,
    NzDividerModule,
    NzSelectModule,
    NzTableModule,
    NzGridModule,
    NzTimePickerModule,
  ],
  exports: COMPONENTS,
  entryComponents: COMPONENTS,
})
export class F1CalendarModule {}
