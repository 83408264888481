import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponseBase,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AppService } from '@service/common/app.service';
import { NotificationService } from '@service/common/notification.service';
import { CacheService, _HttpClient, toBoolean, isEmpty } from '@service/common';
import { NbTokenService } from '@nebular/auth';
import { EncryptService } from '@service/common/encrypt.service';
import { isNullOrUndefined } from 'util';
import { isUrlSerialize, urlDeserialization } from 'app/shared/utils/url';
import { CommonService } from '@shared/utils/common.service';

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '数据接口不存在',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  private get notification(): NotificationService {
    return this.injector.get(NotificationService);
  }
  private get cacheSrv() {
    return this.injector.get(CacheService);
  }
  private get appSrv() {
    return this.injector.get(AppService);
  }
  private get encryptSrv() {
    return this.injector.get(EncryptService);
  }

  private get commonSrv(): CommonService {
    return this.injector.get(CommonService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if (ev.status === undefined || (ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    this.notification.error(`请求错误 ${ev.status}: ${ev.url} ${errortext}`);
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    // console.log('ev', ev);
    if (this.appSrv.isNotEmpty(ev['body'])) {
      // console.log('ev', ev['body'])
      if (ev['body'].errcode == -10001) {
        this.goTo('/passport/login');
      }
    } else {
      // console.log('ev', ev)
      if (ev['errcode'] == -10001) {
        this.goTo('/passport/login');
      }
    }
    if (ev.status > 0) {
      this.injector.get(_HttpClient).end();
    }
    this.checkStatus(ev);
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        // 例如响应内容：
        //  错误内容：{ status: 1, msg: '非法参数' }
        //  正确内容：{ status: 0, response: {  } }
        // 则以下代码片断可直接适用
        if (ev instanceof HttpResponse) {
          // console.log('ev', ev)
          // console.log('body', ev.body)
          const body: any = ev.body;
          if (body == null || (body && body.errcode === undefined)) {
            return of(ev);
          }
          if (body && body.errcode !== 0) {
            this.notification.error(
              (body.Retdata && body.Retdata.message) || (body.retdata && body.retdata.message) || body.errmsg,
            );
            if (body && body.errcode === -1) {
              if (body.errmsg == '验证码不正确' || body.errmsg == '用户名或密码错误!') { 
                this.injector.get(NbTokenService).clear();
                this.goTo('/passport/login');
              } else {
                return of(new HttpResponse(Object.assign(ev, { body: body.Retdata || body.retdata })));
              }
            }
            if (body && body.errcode !== -2) {
              //2020-12-16 新增判断(后台更改错误返回代码默认errcode=0为-2) ling
              // this.notification.error(body.errmsg); //2020-12-16 注释 ling
              // this.notification.error(body.Retdata || body.retdata); // 2020-12-16 新增 ling
              // 继续抛出错误中断后续所有 Pipe、subscribe 操作，因此：
              // this.http.get('/').subscribe() 并不会触发

              return throwError({});
            } else {
              return of(new HttpResponse(Object.assign(ev, { body: body.Retdata || body.retdata })));
            }
          } else {
            // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
            return of(new HttpResponse(Object.assign(ev, { body: body.Retdata || body.retdata })));
            // 或者依然保持完整的格式
            // return of(ev);
          }
        }
        break;
      case 401:
        this.notification.error(`未登录或登录已过期，请重新登录。`);
        // 清空 token 信息
        this.injector.get(NbTokenService).clear();
        this.goTo('/passport/login');
        break;
      case 403:
      case 404:
      case 500:
      // this.goTo(`/exception/${ev.status}`);
      // break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    let body = null;
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      if (isUrlSerialize(url)) {
        const dd = urlDeserialization(url);
        let keyJson = this.cacheSrv.get(dd[1], { mode: 'none' });
        if (keyJson !== null && keyJson !== undefined) {
          if (keyJson.test) {
            // 测试环境
            url = keyJson.url + dd[0];
          } else {
            // 生产环境
            url = keyJson.pUrl + dd[0];
          }
        } else {
          const tUrl = this.appSrv.getApiaddress(dd[1]);
          url = tUrl + dd[0];
        }

        if (toBoolean(dd[2])) {
          // const loginUserInfo: any = this.appSrv.user || {};
          if (req.body !== null && req.body !== undefined) {
            body = this.encryptSrv.fineOneEncrypt({
              comCode: this.appSrv.comCode,
              ...req.body,
            });
          }
        }
      } else {
        url = environment.SERVER_URL + url;
      }
    }
    let newReq;
    if (body) {
      newReq = req.clone({ url, body });
    } else {
      newReq = req.clone({ url });
    }
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // console.log('event', event);
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase) return this.handleData(event);
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
}
