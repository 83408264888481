import { InjectionToken } from '@angular/core';
import { Scheduler } from 'rxjs';

export const GlobalConfig = {
  PwdMd5Num: 2, // md5 加密次数
  ExportRowDefault: 2000, // 导出默认行数
  ExportRows: [500, 1000, 2000], // 导出行数选择
  Debounce: 300, // 防抖动，设定毫秒时间内，只取最新数据进行发射，其他数据取消发射。
  DefalutPageSize: 30, // 默认分页 单页显示条数
  MiddlePageSize: 15, // 中等分页 单页显示条数
  DefalutModalPageSize: 10, // 模态框默认分页 单页显示条数
  Duration: 2000, // 消息提示显示时间
  MsgCount: 20, // 保留的历史消息数
  SortSN: 500, // 默认排序值
  ProductCustomPropertyCount: 6, // 产品自定义属性6个
  ResponseNoticeCount: 50, // 消息队列保持50个消息
  UploadlAttachmentFile_MaxCn: 6, // 上传附件最大个数
  MaxValue: Infinity, // number输入框最大值
  pageMaxSize: 2147483646,
};
// 点击防抖动
export const CLICK_DEBOUNCE = new InjectionToken<number>('Click Debounce');
// 点击调度
export const CLICK_SCHEDULER = new InjectionToken<Scheduler>('Click Scheduler');

export const CACHE_KEYS = {

};
