import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '@env/environment';
import { _HttpClient } from '@service/common';
import { point } from 'leaflet';
import { urlSerialize } from './url';
import { guid } from './util';
const { Cesium } = FMapSdk.Namespace;

@Injectable()
export class DrawGoogleMapService {
  layer: any;
  layerHtmlLayer: any;
  view: any;
  plot: any;
  label: any;
  labelNo: any;
  clusterLayer: any;
  cameraHeight = 2000;

  mapId = `map-${guid()}`;

  isLoadedScript = true;
  isViewInit = false;

  searchText = '';

  _defaultCenter = [89.527895, 43.817011];
  _center: any[] = [];

  isDrawLine = false;
  isEditDrawLine = false;

  /**
   * 地块信息
   */
  lands: any[] = [];

  /**
   * 当前标点的集合
   */
  markerPoints: any[] = [];

  rmItem: any[] = [];

  /**
   * 当前选中的对象
   */
  currentSelectedObj: any;

  /**
   * 当前标点
   */
  currentMarker: any;

  /**
   * 新增线集合
   */
  pointLines: any[] = [];

  /**
   * 当前操作的line
   */
  currentLine: any;

  @Input() set center(val: [any, any]) {
    let needMove = false;
    if (Array.isArray(val) && val.length === 2) {
      if (Array.isArray(this._center) && this._center.length == 0) {
        needMove = true;
      }
      this._center = FMapSdk.CoordTransform.GCJ02ToWGS84(val[0], val[1]);
      if (needMove) {
        this.view.flyToPosition(new FMapSdk.Position(this._center[0], this._center[1], this.cameraHeight), null, 1);
      }
    }
  }

  _initData = [];
  @Input() set initData(val: any) {
    try {
      const mArr = JSON.parse(val.mapList);
      if (Array.isArray(mArr) && mArr.length > 0) {
        this._initData = mArr;

        var points: any[] = [];

        mArr.forEach(c => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
          points.push(new FMapSdk.Position(wgs84[0], wgs84[1]));
        });

        let polygon = new FMapSdk.Polygon(mArr);
        polygon.setStyle({
          outline: true,
          outlineColor: Cesium.Color.YELLOW,
          outlineWidth: 10,
          material: Cesium.Color.fromRandom({ alpha: 0.5 }),
        });
        polygon.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          console.log('polygoneee', e);
        });
        this.layer.addOverlay(polygon);
        let label = new FMapSdk.Label(points[0], val.landName);
        label.setStyle({
          font: '12px',
        });
        this.layer.addOverlay(label);
      }
    } catch (error) { }
  }

  @Output() mapChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() lineChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() f1Close?: Function;

  tools = [
    // { name: '标点', type: 1, icon: 'iconfont icon-position', active: false },
    // { name: '画面', type: 2, icon: 'iconfont icon-xuanmianmianji', active: false },
    // { name: '测距', type: 3, icon: 'iconfont icon-zhixianjuli', active: false },
    // { name: '获取面图', type: 4, icon: 'iconfont icon-tuceng', active: false },
    { name: '连线', type: 5, icon: 'iconfont icon-xian', active: false },
    // { name: '删除', type: 6, icon: 'iconfont icon-qingchu', active: false },
    // { name: '隐藏', type: 7, icon: 'iconfont icon-xian', active: false },
  ];

  delTool = [
    // { name: '清空', type: 9, icon: 'iconfont icon-qingchu', active: false },
    // { name: '关闭', type: 10, icon: 'iconfont icon-close', active: false },
  ];
  get isInit() {
    return this.isLoadedScript && this.isViewInit;
  }

  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * areaRecNo: 地块recNo
   * deviceRecNo: 设备recNo
   * lat:
   * lng:
   *
   * }
   */
  drawMarkerCb = (item: any, params: any) => { };

  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * areaRecNo: 地块recNo
   * GroupNo: 分组recNo
   * lat:
   * lng:
   *
   * }
   */
  drawMarkerGroup = (item: any, params: any) => { };

  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * cpoint: 当前硬件
   * ppoint: 父级硬件
   * path: 路径
   *
   * }
   */
  drawLineCb = (params: any) => { };

  /**
   * 删除回调
   * @param params 原始对象
   */
  deleteCb = (params: any) => { };

  constructor(protected cdr: ChangeDetectorRef, protected zone: NgZone, protected http: HttpClient) { }

  initMap = (): void => {
    if (!this.isInit) {
      return;
    }
    const el = document.querySelector(`#${this.mapId}`);
    if (!el) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      const { Cesium } = FMapSdk.Namespace;
      let baseLayer = FMapSdk.ImageryLayerFactory.createArcGisImageryLayer({
        url:
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer'
      });
      this.layer = new FMapSdk.VectorLayer('layer');
      this.layerHtmlLayer = new FMapSdk.HtmlLayer('layer');
      this.view = new FMapSdk.Viewer(this.mapId, {
        enableFxaa: true,
        sceneMode: Cesium.SceneMode.SCENE2D,
      });
      this.plot = new FMapSdk.Plot(this.view, {});

      this.view.addBaseLayer(baseLayer);
      this.view.addLayer(this.layerHtmlLayer);
      this.view.addLayer(this.layer);
      this.clusterLayer = new FMapSdk.ClusterLayer('layer', {
        style: 'clustering',
      });
      this.view.addLayer(this.clusterLayer);
      this.view.flyToPosition(
        new FMapSdk.Position(this._defaultCenter[0], this._defaultCenter[1], this.cameraHeight),
        null,
        1,
      );
      this.view.scene.screenSpaceCameraController.minimumZoomDistance = 500;
      this.view.scene.screenSpaceCameraController.maximumZoomDistance = 5000;
    });
  };

  drawLand(landData: any[]) {
    // 准备模拟数据
    var points: any[] = [];
    let center: any[] = [];
    this.lands = landData;
    this.lands.forEach((c, i) => {
      points = [];
      let maxHeight = 0;
      if (Array.isArray(c.lngLats)) {
        c.lngLats.forEach(c => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
          let height = this.view.scene.sampleHeight(
            new Cesium.Cartographic(Cesium.Math.toRadians(c.lng), Cesium.Math.toRadians(c.lat)),
          );
          if (height > maxHeight) {
            maxHeight = height;
          }
          points.push(new FMapSdk.Position(wgs84[0], wgs84[1], height));
        });
      }

      points.push(points[0]);
      let polyline = new FMapSdk.Polyline(points);
      polyline.setStyle({
        width: 3,
        clampToGround: true,
        // material: Cesium.Color.RED,
        material: Cesium.Color.fromRandom({ alpha: 0.5 }),
      });
      this.layer.addOverlay(polyline);

      if (points.length > 0) {
        let label = new FMapSdk.Label(points[0], c.landName);
        label.setStyle({
          height: maxHeight + 10,
          font: '12px',
        });
        this.layer.addOverlay(label);
      }
    });
    if (points.length > 0) {
      this.view.flyToPosition(
        new FMapSdk.Position(points[0].lng, points[0].lat, this.cameraHeight),
        null,
        1,
      );
    }
  }

  /**
   * 绘制硬件坐标
   */
  drawCoordinate(hc: any[], clickCb?) {
    this.rmItem.forEach(x => {
      this.layer.removeOverlay(x);
    });
    this.rmItem = [];

    hc.filter(c => c.latitude && !c.detailNo).forEach(c => {
      const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.longitude, c.latitude);
      const style = {
        pixelOffset: { x: -15, y: -15 },
        scale: 0.4,
      };
      if (c.groupName && c.hardwareDcpType === 3) {
        const point = new FMapSdk.Billboard(new FMapSdk.Position(wgs84[0], wgs84[1], 0), 'assets/images/hardware/Valvenoopen-A.png');
        // const point = new FMapSdk.Point(new FMapSdk.Position(wgs84[0], wgs84[1], 0));
        point.setStyle({
          scale: 0.5,
          // color: Cesium.Color.WHITE,
        });
        point.groupName = c.groupName;
        point.setLabel(c.groupName, style);
        point.cpoint = c;
        point.recNo = c.recNo;
        point.f1Type = 'marker';
        //点击硬件标点
        point.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          if (clickCb) {
            clickCb(e);
          }
          console.log('CLICK', e);
          console.log(this.isDrawLine);
          console.log(this.pointLines);
          if (this.isDrawLine) {
            if (this.pointLines.length > 1) {
            } else {
              this.pointLines.push(e.overlay);
            }
          } else {
            this.currentSelectedObj = e;
          }
        });
        this.layer.addOverlay(point);
        this.rmItem.push(point);
      } else {
        const point = new FMapSdk.Point(new FMapSdk.Position(wgs84[0], wgs84[1], 0));
        point.setStyle({
          scale: 0.5,
          // color: Cesium.Color.WHITE,
        });
        if (c.groupName) {
          point.groupName = c.groupName;
        } else {
          point.hardwareName = c.hardwareName;
        }
        point.setLabel(c.hardwareName, style);
        point.cpoint = c;
        point.recNo = c.recNo;
        point.f1Type = 'marker';
        //点击硬件标点
        point.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          if (clickCb) {
            clickCb(e);
          }
          console.log('CLICK', e);
          console.log(this.isDrawLine);
          console.log(this.pointLines);
          if (this.isDrawLine) {
            if (this.pointLines.length > 1) {
              // this.pointLines.push(e.overlay);
              // this.pointLines.forEach(p => {
              //   const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(p.lng, p.lat);
              //   points.push(new T.LngLat(wgs84[0], wgs84[1]));
              // });
              // //创建线对象
              // const polyline = new FMapSdk.Polyline(points);
              // polyline.setStyle({
              //   width: 20,
              //   material: new FMapSdk.PolylineLightingTrailMaterialProperty({
              //     color: FMapSdk.Color.YELLOW,
              //     speed: 5.0,
              //   }),
              //   clampToGround: true,
              // });
              // this.plot.draw('polyline', (overlay: any) => {
              //   console.log(overlay);
              //   const positions: any[] = [];
              //   let maxHeight = 0;
              //   let height0 = this.view.scene.sampleHeight(
              //     new Cesium.Cartographic(
              //       Cesium.Math.toRadians(this.pointLines[0].longitude),
              //       Cesium.Math.toRadians(this.pointLines[0].latitude),
              //     ),
              //   );
              //   let height1 = this.view.scene.sampleHeight(
              //     new Cesium.Cartographic(
              //       Cesium.Math.toRadians(this.pointLines[1].longitude),
              //       Cesium.Math.toRadians(this.pointLines[1].latitude),
              //     ),
              //   );
              //   if (height1 > maxHeight) {
              //     maxHeight = height1;
              //   }
              //   positions.push(
              //     Cesium.Cartesian3.fromDegrees(this.pointLines[0].longitude, this.pointLines[0].latitude, height0),
              //     Cesium.Cartesian3.fromDegrees(this.pointLines[1].longitude, this.pointLines[1].latitude, height1),
              //   );
              //   this.view.entities.add({
              //     polygon: {
              //       hierarchy: {
              //         positions: positions,
              //       },
              //       height: maxHeight + 5,
              //       material: Cesium.Color.BLUE.withAlpha(0.5),
              //     },
              //   });
              // });
            } else {
              this.pointLines.push(e.overlay);
            }
          } else {
            this.currentSelectedObj = e;
          }
        });
        this.layer.addOverlay(point);
        this.rmItem.push(point);
      }
      this.markerPoints.push(c);
    });
  }

  /**
   * 绘制关系
   */
  drawRelationship(mapRs: any[]) {
    mapRs
      .filter(c => c.path)
      .forEach(c => {
        let path: any[];
        try {
          path = JSON.parse(c.path);
        } catch (error) {
          return;
        }
        var points = [];
        path.forEach(p => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(p.lng, p.lat);
          points.push(new T.LngLat(wgs84[0], wgs84[1]));
        });

        //创建线对象
        const polyline = new FMapSdk.Polyline(points);
        polyline.setStyle({
          width: 3,
          material: FMapSdk.Color.YELLOW,
          clampToGround: true,
        });

        polyline.f1Type = 'line';
        polyline.crs = c;
        polyline.hardwareName = c.hardwareName;
        polyline.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          console.log('CLICK', e);
          this.currentSelectedObj = e;
        });
        this.layer.addOverlay(polyline);
      });
  }

  clearLine() {
    this.pointLines = [];
    if (this.currentLine) {
      this.currentLine.disableEdit();
      this.layer.removeOverlay(this.currentLine);
    }
  }

  finishEditDrawLine() {
    this.isEditDrawLine = false;
    this.isDrawLine = false;
    const points = [];
    this.pointLines.forEach(p => {
      const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(p.cpoint.longitude, p.cpoint.latitude);
      points.push(new T.LngLat(wgs84[0], wgs84[1]));
    });
    //创建线对象
    const polyline = new FMapSdk.Polyline(points);
    polyline.setStyle({
      width: 3,
      material: FMapSdk.Color.YELLOW,
      clampToGround: true,
    });

    polyline.f1Type = 'line';
    polyline.cpoint = this.pointLines[0].cpoint;
    polyline.ppoint = this.pointLines[1].cpoint;
    polyline.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
      console.log('CLICK', e);
      this.currentSelectedObj = e;
    });
    this.layer.addOverlay(polyline);
    const lnglats = this.pointLines.map(x => {
      // const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.cpoint.longitude, x.cpoint.latitude);
      return {
        lng: x.cpoint.longitude,
        lat: x.cpoint.latitude,
      };
    });
    this.drawLineCb({
      cpoint: this.pointLines[0].cpoint,
      ppoint: this.pointLines[1].cpoint,
      path: lnglats,
    });
    // const lnglats = this.currentLine.getLngLats().map(x => {
    //   const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
    //   return {
    //     lng: gcjo2[0],
    //     lat: gcjo2[1],
    //   };
    // });

    // this.currentLine.disableEdit();
    // this.currentLine = undefined;
    this.pointLines = [];
  }

  drawGroupMarker(item: any): void {
    this.currentMarker = item;
    this.openGroupMarker();
  }

  openGroupMarker() {
    this.plot.draw(
      'point',
      (overlay: any) => {
        let height = this.view.scene.sampleHeight(
          new Cesium.Cartographic(
            Cesium.Math.toRadians(overlay.position.lng),
            Cesium.Math.toRadians(overlay.position.lat),
          ),
        );

        // const billboard = new FMapSdk.Billboard(
        //   new FMapSdk.Position(overlay.position.lng, overlay.position.lat, height),
        //   'assets/images/bg_icon_orange.png',
        // );

        const point = new FMapSdk.Point(new FMapSdk.Position(overlay.position.lng, overlay.position.lat, height));
        point.setStyle({
          scale: 0.5,
          color: Cesium.Color.WHITE,
        });

        point.cpoint = this.currentMarker;
        point.recNo = this.currentMarker.recNo;
        point.f1Type = 'marker';
        point.groupName = this.currentMarker.groupName;
        const style = {
          pixelOffset: { x: -15, y: -15 },
          scale: 0.4,
        };
        point.setLabel(this.currentMarker.groupName, style);
        const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(overlay.position.lng, overlay.position.lat);
        point.cpoint.longitude = gcjo2[0];
        point.cpoint.latitude = gcjo2[1];
        const area = this.fineArea({ lat: gcjo2[1], lng: gcjo2[0] });
        this.drawMarkerGroup(this.currentMarker, {
          areaRecNo: area ? area.recNo : '',
          groupNo: this.currentMarker.recNo,
          lat: gcjo2[1],
          lng: gcjo2[0],
        });
        this.layer.addOverlay(point);
        this.markerPoints.push(this.currentMarker);
        //点击硬件标点
        point.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          console.log('CLICK', e);
          console.log(this.isDrawLine);
          console.log(this.pointLines);

          if (this.isDrawLine) {
            if (this.pointLines.length > 1) {
            } else {
              this.pointLines.push(e.overlay);
            }
          } else {
            this.currentSelectedObj = e;
          }
        });
        // let label;
        // label = new FMapSdk.Label(
        //   new FMapSdk.Position(overlay.position.lng + 0.0005, overlay.position.lat + 0.0005, 0),
        //   this.currentMarker.groupName,
        // );
        // label.setStyle({
        //   font: '12px',
        // });
        // this.layer.addOverlay(label);
        this.currentMarker = undefined;
      },
      {
        material: FMapSdk.Color.WHITE,
      },
      false,
    );
  }

  drawMarker(item: any): void {
    this.currentMarker = item;
    this.openMarker();
  }

  /**
   * 打开标点
   */
  openMarker() {
    this.plot.draw(
      'point',
      (overlay: any) => {
        let height = this.view.scene.sampleHeight(
          new Cesium.Cartographic(
            Cesium.Math.toRadians(overlay.position.lng),
            Cesium.Math.toRadians(overlay.position.lat),
          ),
        );
        // const billboard = new FMapSdk.Billboard(
        //   new FMapSdk.Position(overlay.position.lng, overlay.position.lat, height),
        //   'assets/images/bg_icon_orange.png',
        // );
        const point = new FMapSdk.Point(new FMapSdk.Position(overlay.position.lng, overlay.position.lat, height));
        point.cpoint = this.currentMarker;
        point.recNo = this.currentMarker.recNo;
        point.f1Type = 'marker';
        point.setStyle({
          scale: 0.5,
          color: Cesium.Color.WHITE,
        });
        const style = {
          pixelOffset: { x: -15, y: -15 },
          scale: 0.4,
        };
        point.setLabel(this.currentMarker.hardwareName, style);
        point.hardwareName = this.currentMarker.hardwareName;

        const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(overlay.position.lng, overlay.position.lat);
        const area = this.fineArea({ lat: gcjo2[1], lng: gcjo2[0] });
        point.cpoint.longitude = gcjo2[0];
        point.cpoint.latitude = gcjo2[1];
        this.drawMarkerCb(this.currentMarker, {
          areaRecNo: area ? area.recNo : '',
          deviceRecNo: this.currentMarker.recNo,
          lat: gcjo2[1],
          lng: gcjo2[0],
        });
        this.layer.addOverlay(point);
        this.markerPoints.push(this.currentMarker);
        //点击硬件标点
        point.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          console.log('CLICK', e);
          console.log(this.isDrawLine);
          console.log(this.pointLines);

          if (this.isDrawLine) {
            if (this.pointLines.length > 1) {
            } else {
              this.pointLines.push(e.overlay);
            }
          } else {
            this.currentSelectedObj = e;
          }
        });
        this.currentMarker = undefined;
      },
      {
        material: FMapSdk.Color.WHITE,
      },
      false,
    );
  }
  canClickTypes = [1, 2, 3];
  clickTool(item: any) {
    this.tools.filter(t => t != item).forEach(t => (t.active = false));
    if (this.canClickTypes.includes(item.type)) {
      item.active = !item.active;
    }

    switch (item.type) {
      case 2:
        item.active ? this.openPolygon() : this.closePolygon();
        break;

      case 5:
        this.isDrawLine = !this.isDrawLine;
        this.pointLines = [];
        break;

      case 9:
        this.clear();
        break;

      case 10:
        this.f1Close && this.f1Close();
        break;

      default:
        break;
    }
  }

  /**
   * 打开测面
   */
  openPolygon(): void {
    this.plot.draw('polygon', (overlay: any) => {
      this.layer.addOverlay(overlay);
      this.mapChange.emit({
        currentLnglats: overlay.positions.map(x => {
          const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
          return {
            lng: gcjo2[0],
            lat: gcjo2[1],
          };
        }),
        currentArea: 0,
      });
      this.plot.edit(overlay, ov => {
        this.mapChange.emit({
          currentLnglats: ov.positions.map(x => {
            const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
            return {
              lng: gcjo2[0],
              lat: gcjo2[1],
            };
          }),
          currentArea: 0,
        });
      });
    });
  }

  fineArea(pt: any) {
    let a: any;
    for (let index = 0; index < this.lands.length; index++) {
      const l = this.lands[index];
      if (this.isInsidePolygon(pt, l.lngLats)) {
        a = l;
        break;
      }
    }
    return a;
  }

  isInsidePolygon(checkPoint: any, polygonPoints: any[]) {
    var counter = 0;
    var i;
    var xinters;
    var p1, p2;
    var pointCount = polygonPoints.length;
    p1 = polygonPoints[0];

    for (i = 1; i <= pointCount; i++) {
      p2 = polygonPoints[i % pointCount];
      if (checkPoint.lng > Math.min(p1.lng, p2.lng) && checkPoint.lng <= Math.max(p1.lng, p2.lng)) {
        if (checkPoint.lat <= Math.max(p1.lat, p2.lat)) {
          if (p1.lng != p2.lng) {
            xinters = ((checkPoint.lng - p1.lng) * (p2.lat - p1.lat)) / (p2.lng - p1.lng) + p1.lat;
            if (p1.lat == p2.lat || checkPoint.lat <= xinters) {
              counter++;
            }
          }
        }
      }
      p1 = p2;
    }
    if (counter % 2 == 0) {
      return false;
    } else {
      return true;
    }
  }

  getModelPointHeight(lng: number, lat: number) {
    return this.view.scene.sampleHeight(
      new Cesium.Cartographic(Cesium.Math.toRadians(lng), Cesium.Math.toRadians(lat)),
    );
  }

  getPolygonMaxHeight(lnglats: any[]) {
    let maxHeight = 0;
    lnglats.forEach(x => {
      const h = this.getModelPointHeight(x.lng, x.lat);
      if (maxHeight < h) {
        maxHeight = h;
      }
    });
    return maxHeight;
  }

  unSelected() {
    this.currentSelectedObj = null;
  }

  deleteSelected() {
    this.deleteCb(this.currentSelectedObj);
    if (this.currentSelectedObj) {
      this.layer.removeOverlay(this.currentSelectedObj.overlay);
    }
    this.currentSelectedObj = undefined;
  }

  /**
   * 关闭测面
   */
  closePolygon(): void {
    // this.polygonTool.close();
    // this.polygonTool.removeEventListener('draw');
  }
  /**
   * 清除所有图层
   */
  clear(): void {
    this.layer.clear();
  }

  closeSelected(): void {
    this.tools.forEach(c => (c.active = false));
    this.cdr.markForCheck();
  }

  /**
   * 位置跳转
   */
  flyToPosition(positionArr): void {
    let position = FMapSdk.CoordTransform.GCJ02ToWGS84(positionArr[0], positionArr[1]);
    this.view.flyToPosition(
      new FMapSdk.Position(position[0], position[1], this.cameraHeight),
      null,
      1,
    )
  }

  /**
   * 绘制面
   */
  drawPolygon(lnglats: any[]) {
    if (lnglats.length <= 0) return;

    let points: any[] = [];
    lnglats.forEach((c: any) => {
      const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
      points.push(new FMapSdk.Position(wgs84[0], wgs84[1]));
    })

    let polygon = new FMapSdk.Polygon(points);
    let height = this.getPolygonMaxHeight(points);

    polygon.setStyle({
      height: height,
      outline: true,
      outlineColor: FMapSdk.Color.fromCssColorString('#FFFFFF'),
      outlineWidth: 1,
      material: FMapSdk.Color.fromCssColorString('rgba(78, 238, 148, 0.3)')
    })

    this.layer.addOverlay(polygon);
    return polygon;
  }
}
