import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '@env/environment';
import { getCenter, guid } from '../../../utils/util';

/**
 * 功能：
 * 1.搜索坐标
 * 2.标点硬件
 * 3.画面
 * 4.测距
 * 5.清空
 */
@Component({
  selector: 'f1-draw-line',
  templateUrl: './draw-line.component.html',
  styleUrls: ['./draw-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawLineComponent implements OnInit, AfterViewInit, OnDestroy {
  map: any;
  zoom = 16;

  mapId = `map-${guid()}`;

  isLoadedScript = true;
  isViewInit = false;

  showSearch = false;

  searchText = '';

  handler: any;

  @Input() set initData(val: string) {
    try {
      const mArr = JSON.parse(val);
      if (Array.isArray(mArr) && mArr.length > 0) {
        var points: any[] = [];
        mArr.forEach(c => {
          points.push(new T.LngLat(c.lng, c.lat));
        });

        //创建面对象
        var polygon = new T.Polygon(points, {
          color: 'blue',
          weight: 3,
          opacity: 0.5,
          fillColor: '#FFFFFF',
          fillOpacity: 0.5,
        });
        setTimeout(() => {
          //向地图上添加面
          // 这里延时100ms才操作，是因为防止map没初始化的情况
          this.map.addOverLay(polygon);
          const center = getCenter(mArr);
          this.map.panTo(new T.LngLat(center[0], center[1]), this.zoom);
        }, 100);
      }
    } catch (error) {}
  }

  @Output() mapChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() lineChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() f1Close?: Function;

  tools = [
    // { name: '标点', type: 1, icon: 'iconfont icon-position', active: false },
    // { name: '画面', type: 2, icon: 'iconfont icon-xuanmianmianji', active: false },
    // { name: '测距', type: 3, icon: 'iconfont icon-zhixianjuli', active: false },
    // { name: '获取面图', type: 4, icon: 'iconfont icon-tuceng', active: false },
  ];

  delTool = [
    { name: '清空', type: 9, icon: 'iconfont icon-qingchu', active: false },
    // { name: '关闭', type: 10, icon: 'iconfont icon-close', active: false },
  ];

  get isInit() {
    return this.isLoadedScript && this.isViewInit;
  }

  //创建标注工具对象
  markerTool: any;
  // 测面工具
  polygonTool: any;
  // 测距工具
  lineTool: any;
  constructor(private cdr: ChangeDetectorRef, private zone: NgZone, private http: HttpClient) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.isViewInit = true;
    this.initMap();
  }

  initMap(): void {
    if (!this.isInit) {
      return;
    }
    const el = document.querySelector(`#${this.mapId}`);
    if (!el) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.map = new T.Map(this.mapId);

      this.map.centerAndZoom(new T.LngLat(89.527895, 43.817011), this.zoom);

      this.map.setMaxZoom(16);
      // this.map.defaultMapType = TMAP_HYBRID_MAP;

      //允许鼠标滚轮缩放地图
      this.map.enableScrollWheelZoom();
      //创建缩放平移控件对象
      const control = new T.Control.Zoom();
      control.setPosition(T_ANCHOR_BOTTOM_RIGHT);
      this.map.addControl(control);
      // 地图类别空间
      // const mapCtrl = new T.Control.MapType();
      // mapCtrl.position = T_ANCHOR_BOTTOM_RIGHT;
      // this.map.addControl(mapCtrl);
      this.map.setMapType(TMAP_HYBRID_MAP);

      this.markerTool = new T.MarkTool(this.map, { follow: true });
      const config = {
        showLabel: true,
        color: 'blue',
        weight: 3,
        opacity: 0.5,
        fillColor: '#FFFFFF',
        fillOpacity: 0.5,
      };
      //测面工具
      this.polygonTool = new T.PolygonTool(this.map, config);

      //测距工具
      this.lineTool = new T.PolylineTool(this.map, config);

      // 准备模拟数据
      // var points: any[] = [];
      // let center: any[] = [];
      // this.commonSrv.mockData.forEach(c => {
      //   points = []
      //   let cd = JSON.parse(c.mapList);

      //   let lngLat: any[] = [];
      //   let cc: any = [];
      //   if (Array.isArray(cd)) {
      //     cc = cd.map(c => {
      //       return [c.lng, c.lat];
      //     });
      //     lngLat = cd.map(c => {
      //       return { lng: c.lng, lat: c.lat };
      //     })
      //   }

      //   lngLat.forEach(l => {
      //     points.push(new T.LngLat(l.lng, l.lat));
      //   })
      //   //创建面对象
      //   var polygon = new T.Polygon(points, {
      //     color: 'blue',
      //     weight: 3,
      //     opacity: 0.5,
      //     fillColor: '#FFFFFF',
      //     fillOpacity: 0.5,
      //   });

      //   this.map.addOverLay(polygon);

      //   center = getCenter(lngLat);
      //   this.map.panTo(new T.LngLat(center[0], center[1]), this.zoom);
      // });
    });
  }

  search(): void {
    // http://api.tianditu.gov.cn/v2/search?postStr={"keyWord":"北京大学","level":12,"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":10}&type=query&tk=您的密钥
    this.http
      .get(
        `${location.protocol}//api.tianditu.gov.cn/v2/search?postStr={"keyWord":"${this.searchText}","level":${this.zoom},"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":1}&type=query&tk=${environment.mapKey}`,
      )
      .subscribe((res: any) => {
        let lonlat;
        switch (res.resultType) {
          case 1:
            if (Array.isArray(res.pois) && res.pois.length > 0) {
              lonlat = res.pois[0].lonlat;
            }
            break;
          case 3:
            if (res.area && res.area.lonlat) {
              lonlat = res.area.lonlat;
            }
            break;

          default:
            break;
        }
        if (lonlat) {
          const na = lonlat.split(',');
          this.map.panTo(new T.LngLat(na[0], na[1]), this.zoom);
        }
      });
  }

  canClickTypes = [1, 2, 3];
  clickTool(item: any) {
    this.tools.filter(t => t != item).forEach(t => (t.active = false));
    if (this.canClickTypes.includes(item.type)) {
      item.active = !item.active;
    }

    switch (item.type) {
      case 1:
        item.active ? this.openMarker() : this.closeMarker();
        break;

      case 2:
        item.active ? this.openPolygon() : this.closePolygon();
        break;

      case 3:
        item.active ? this.openLine() : this.closeLine();
        break;

      case 4:
        this.getPoints();
        break;

      case 9:
        this.clear();
        break;

      case 10:
        this.f1Close && this.f1Close();
        break;

      default:
        break;
    }
  }

  /**
   * 编辑标点
   */
  editMarker(): void {
    var markers = this.markerTool.getMarkers();
    for (var i = 0; i < markers.length; i++) {
      markers[i].enableDragging();
    }
  }
  /**
   * 结束编辑标点
   */
  endeditMarker() {
    var markers = this.markerTool.getMarkers();
    for (var i = 0; i < markers.length; i++) {
      markers[i].disableDragging();
    }
  }
  /**
   * 打开标点
   */
  openMarker(): void {
    this.endeditMarker();
    this.markerTool.open();
  }
  /**
   * 关闭标点
   */
  closeMarker(): void {
    this.endeditMarker();
    this.markerTool.open();
  }

  /**
   * 多边形工具
   */
  openRectangleTool(): void {
    if (this.handler) this.handler.close();
    this.handler = new T.RectangleTool(this.map, { follow: true });
    this.handler.open();
  }

  /**
   * 打开测面
   */
  openPolygon(): void {
    this.polygonTool.open();
    this.polygonTool.removeEventListener('draw');
    this.polygonTool.addEventListener('draw', (args: any) => {
      console.log(JSON.stringify(args.currentLnglats));
      console.log(args.currentArea);
      this.mapChange.emit({
        currentLnglats: args.currentLnglats,
        currentArea: args.currentArea,
      });
      this.closePolygon();
      this.closeSelected();
    });
  }

  /**
   * 关闭测面
   */
  closePolygon(): void {
    this.polygonTool.close();
    this.polygonTool.removeEventListener('draw');
  }

  /**
   * 打开测距
   */
  openLine(): void {
    this.lineTool.open();
    this.lineTool.removeEventListener('draw');
    this.lineTool.addEventListener('draw', (args: any) => {
      const ls = args.allPolylines.map((p: any) => {
        return p.getLngLats();
      });

      this.lineChange.emit({
        ls,
      });
      this.closeLine();
      this.closeSelected();
    });
  }

  /**
   * 关闭测距
   */
  closeLine(): void {
    this.lineTool.close();
    this.lineTool.removeEventListener('draw');
  }

  /**
   * 获取点位
   */
  getPoints(): void {
    const pgs = this.polygonTool.getPolygons();
    console.log(pgs);
  }
  /**
   * 清除所有图层
   */
  clear(): void {
    this.map.clearOverLays();
  }

  closeSelected(): void {
    this.tools.forEach(c => (c.active = false));
    this.cdr.markForCheck();
  }
}
