import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '@env/environment';
import { getCenter, guid } from '../../../utils/util';
const { Cesium } = FMapSdk.Namespace;
import { Page } from '@model/page';
import { isNullOrUndefined } from 'util';
import { DrawGoogleMapService } from '@shared/utils/draw-google-map.service';
import { BaseSetting, chunkArray, DrawMap, MapDevicePreviewComponent, numToArray, urlSerialize } from '@shared';
import { NotificationService, _HttpClient } from '@service/common';

@Component({
  selector: 'ngx-draw-area',
  templateUrl: './draw-area.component.html',
  styleUrls: ['./draw-area.component.scss'],
})
export class DrawAreaComponent extends DrawGoogleMapService implements OnInit, AfterViewInit, OnDestroy {
  layer: any;
  view: any;
  plot: any;
  cameraHeight = 2000;

  page = new Page(); //分页对象
  queryData: Object = {}; //查询对象
  harewareLoading = false;

  // drawMap = new DrawMap(this.cdr, this.zone, this.http);
  selectedIndex = 0;
  page2 = new Page(); //分页对象
  page3 = new Page(); //分页对象
  _originalRow: any[] = []; // 原始查询数据
  // rows: any[] = []; //表格数据
  radioValue = 'A';
  pageList: any[] = [];
  pageData: any[] = [];

  _originalMapData: any[] = [];
  mapData: any[] = [];
  rsData: any[] = [];

  landData: any[] = [];
  groupList: any[] = [];
  groupData: any[] = [];
  hardwareData: any[] = [];
  hardwareList: any[] = [];
  groupHardwareList: any[] = [];
  gridStyle = {
    width: '33.3%',
    padding: '8px',
  };
  gridStyle1 = {
    width: '50%',
    padding: '8px',
  };

  mapId = `map-${guid()}`;

  isLoadedScript = true;
  isViewInit = false;

  searchText = '';

  _defaultCenter = [89.527895, 43.817011];
  _center: any[] = [];

  @Input() set center(val: [any, any]) {
    let needMove = false;
    if (Array.isArray(val) && val.length === 2) {
      if (Array.isArray(this._center) && this._center.length == 0) {
        needMove = true;
      }
      this._center = FMapSdk.CoordTransform.GCJ02ToWGS84(val[0], val[1]);
      if (needMove) {
        // this.view.flyToPosition(new FMapSdk.Position(this._center[0], this._center[1], this.cameraHeight));
      }
    }
  }

  _initData = [];
  @Input() set initData(val: any) {
    try {
      const mArr = JSON.parse(val.mapList);
      if (Array.isArray(mArr) && mArr.length > 0) {
        this._initData = mArr;

        var points: any[] = [];

        mArr.forEach(c => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
          points.push(new FMapSdk.Position(wgs84[0], wgs84[1]));
        });

        let polygon = new FMapSdk.Polygon(mArr);
        polygon.setStyle({
          outline: true,
          outlineColor: Cesium.Color.YELLOW,
          outlineWidth: 10,
          material: Cesium.Color.fromRandom({ alpha: 0.5 }),
        });
        polygon.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
          console.log('polygon', e);
        });
        this.layer.addOverlay(polygon);
        let label = new FMapSdk.Label(points[0], val.landName);
        label.setStyle({
          font: '12px',
        });
        this.layer.addOverlay(label);

        //TODO: 去中间位置放置文字

        //创建面对象
        // var polygon = new T.Polygon(points, {
        //   color: 'blue',
        //   weight: 3,
        //   opacity: 0.5,
        //   fillColor: '#FFFFFF',
        //   fillOpacity: 0.5,
        // });

        // setTimeout(() => {
        //   //向地图上添加面
        //   // 这里延时100ms才操作，是因为防止map没初始化的情况
        //   this.map.addOverLay(polygon);
        //   if (points.length > 0) {
        //     var label = new T.Label({
        //       text: `${val.landName}`,
        //       position: points[0],
        //       offset: new T.Point(20, 20),
        //     });
        //     this.map.addOverLay(label);
        //   }
        //   this._center = getCenter(mArr);
        //   this.map.panTo(new T.LngLat(this._center[0], this._center[1]), this.zoom);
        // }, 100);
      }
    } catch (error) { }
  }

  @Output() mapChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() lineChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() f1Close?: Function;

  tools = [
    // { name: '标点', type: 1, icon: 'iconfont icon-position', active: false },
    { name: '画面', type: 2, icon: 'iconfont icon-xuanmianmianji', active: false },
    // { name: '测距', type: 3, icon: 'iconfont icon-zhixianjuli', active: false },
    // { name: '获取面图', type: 4, icon: 'iconfont icon-tuceng', active: false },
  ];

  delTool = [
    { name: '清空', type: 9, icon: 'iconfont icon-qingchu', active: false },
    { name: '关闭', type: 10, icon: 'iconfont icon-close', active: false },
  ];

  get isInit() {
    return this.isLoadedScript && this.isViewInit;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected zone: NgZone,
    protected http: HttpClient
  ) {
    super(cdr, zone, http);
  }

  ngOnInit() {
    this.loadLand();
    this.loadHarewareData();
  }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {
    this.isViewInit = true;
    FMapSdk.ready(this.initMap);
  }

  /**
   * 加载基地地块信息
   */
  async loadLand() {
    const result = await this.http
      .post(urlSerialize('BaseLandPc/selectBaseLandTreeByAreaAsy', 'Base_four'), {
        recNo: '0',
      })
      .toPromise();
    if (Array.isArray(result)) {
      let landData = result.map(c => {
        let lngLats = [];
        try {
          lngLats = JSON.parse(c.mapList);
        } catch (error) { }
        return {
          landName: c.landName,
          id: c.id,
          recNo: c.recNo,
          area: `${c.grownArea}${c.unitArea}`,
          lngLats: lngLats,
        };
      });
      this.drawLand(landData);
    }
  }

  drawLand(landData: any[]) {
    // 准备模拟数据
    var points: any[] = [];
    let center: any[] = [];
    let polygon: any;
    landData.forEach(c => {
      points = [];
      if (Array.isArray(c.lngLats)) {
        c.lngLats.forEach(c => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
          points.push(new FMapSdk.Position(wgs84[0], wgs84[1]));
          // points.push(new FMapSdk.Position(c.lng, c.lat));
        });
      }
      //创建面对象
      polygon = new FMapSdk.Polygon(points);
      polygon.setStyle({
        outline: true,
        outlineColor: Cesium.Color.YELLOW,
        outlineWidth: 10,
        material: Cesium.Color.fromRandom({ alpha: 0.5 }),
      });
      polygon.on(FMapSdk.MouseEventType.CLICK, (e: any) => {
        console.log('polygon', e);
      });
      this.layer.addOverlay(polygon);

      if (points.length > 0) {
        let label = new FMapSdk.Label(points[0], c.landName);
        label.setStyle({
          font: '12px',
        });
        this.layer.addOverlay(label);
        //TODO: 去中间位置放置文字

        // var label = new T.Label({
        //   text: `${c.landName}`,
        //   position: points[0],
        //   offset: new T.Point(20, 20),
        // });
        // this.map.addOverLay(label);
      }
    });
    if (landData.length == 0) {
      this.view.flyToPosition(new FMapSdk.Position(this._center[0], this._center[1], this.cameraHeight), null, 1);
    } else {
      this.view.flyTo(polygon, 1);
    }
  }

  initMap = (): void => {
    if (!this.isInit) {
      return;
    }
    const el = document.querySelector(`#${this.mapId}`);
    if (!el) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const { Cesium } = FMapSdk.Namespace;
        let baseLayer = FMapSdk.ImageryLayerFactory.createArcGisImageryLayer({
          url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
        });
        // new FMapSdk.ImageryLayerFactory.createWMSImageryLayer({
        //   url: 'http://192.168.0.52:8080/geoserver/fine1/wms',
        //   layers: 'fine1:qt_google_15',
        //   parameters: {
        //     service: 'WMS',
        //     format: 'image/png',
        //     transparent: true,
        //     // srs:'EPSG:900913'
        //   },
        // });

        this.layer = new FMapSdk.VectorLayer('layer');

        this.view = new FMapSdk.Viewer(this.mapId, {
          enableFxaa: true,
          sceneMode: Cesium.SceneMode.SCENE2D,
        });
        this.plot = new FMapSdk.Plot(this.view, {});

        this.view.addBaseLayer(baseLayer);
        this.view.addLayer(this.layer);
        // 113.86765814887153
        // 22.559384494357637
        // const tr = FMapSdk.CoordTransform.GCJ02ToWGS84(113.86765814887153, 22.559384494357637);
        // this.view.flyToPosition(new FMapSdk.Position(this._defaultCenter[0], this._defaultCenter[1], this.cameraHeight));
      });
    });
  };

  search(): void {
    // http://api.tianditu.gov.cn/v2/search?postStr={"keyWord":"北京大学","level":12,"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":10}&type=query&tk=您的密钥
    this.http
      .get(
        // `https://api.map.baidu.com/place/v2/search?query=${this.searchText}&region=全国&output=json&ak=${environment.mapKey}`
        `${location.protocol}//api.tianditu.gov.cn/v2/search?postStr={"keyWord":"${this.searchText}","level":16,"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":1}&type=query&tk=${environment.mapKey}`,
      )
      .subscribe((res: any) => {
        let lonlat;
        switch (res.resultType) {
          case 1:
            if (Array.isArray(res.pois) && res.pois.length > 0) {
              lonlat = res.pois[0].lonlat;
            }
            break;
          case 3:
            if (res.area && res.area.lonlat) {
              lonlat = res.area.lonlat;
            }
            break;

          default:
            break;
        }
        if (lonlat) {
          const na = lonlat.split(',');

          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(na[0], na[1]);
          this.view.flyToPosition(new FMapSdk.Position(wgs84[0], wgs84[1], this.cameraHeight), null, 1);
        }
      });
  }

  canClickTypes = [1, 2, 3];
  clickTool(item: any) {
    this.tools.filter(t => t != item).forEach(t => (t.active = false));
    if (this.canClickTypes.includes(item.type)) {
      item.active = !item.active;
    }

    switch (item.type) {
      case 2:
        item.active ? this.openPolygon() : this.closePolygon();
        break;

      case 9:
        this.clear();
        break;

      case 10:
        this.f1Close && this.f1Close();
        break;

      default:
        break;
    }
  }

  /**
   * 打开测面
   */
  openPolygon(): void {
    this.plot.draw(
      'polygon',
      (overlay: any) => {
        this.layer.addOverlay(overlay);
        this.mapChange.emit({
          currentLnglats: overlay.positions.map(x => {
            const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
            return {
              lng: gcjo2[0],
              lat: gcjo2[1],
            };
          }),
          currentArea: overlay.area,
        });
        this.plot.edit(overlay, ov => {
          this.mapChange.emit({
            currentLnglats: ov.positions.map(x => {
              const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
              return {
                lng: gcjo2[0],
                lat: gcjo2[1],
              };
            }),
            currentArea: ov.area,
          });
        });
      },
      {
        extrudedHeight: 1,
      },
    );
    // this.polygonTool.open();
    // this.polygonTool.removeEventListener('draw');
    // this.polygonTool.addEventListener('draw', (args: any) => {
    //   console.log(JSON.stringify(args.currentLnglats));
    //   console.log(args.currentArea);
    //   this.mapChange.emit({
    //     currentLnglats: args.currentLnglats,
    //     currentArea: args.currentArea,
    //   });
    //   this.closePolygon();
    //   this.closeSelected();
    // });
  }

  /**
   * 关闭测面
   */
  closePolygon(): void {
    // this.polygonTool.close();
    // this.polygonTool.removeEventListener('draw');
  }
  /**
   * 清除所有图层
   */
  clear(): void {
    this.layer.clear();
    // this.mapChange.emit({});
  }

  closeSelected(): void {
    this.tools.forEach(c => (c.active = false));
    this.cdr.markForCheck();
  }

  /**
   * 加载硬件数据
   */
  loadHarewareData(deleteCb = false) {
    let paramJson: Object = Object.assign(
      { start: this.page.start * this.page.limit, limit: this.page.limit },
      this.queryData,
    );
    this.harewareLoading = true;
    this.http
      .post(urlSerialize('HardwaresbasefacilityPc/getAllHardwareAndGroupStatus', 'Base_6806'), paramJson)
      .subscribe(
        result => {
          console.log('result====>hardware', result);
          if (!isNullOrUndefined(result)) {
            // this.pageList = numToArray(result.pageCount);
            this.page.pageCount = result['pageCount'];
            this.page.totalSize = result['totalSize'];

            if (Array.isArray(result['dataList'])) {
              result['dataList'].forEach(c => {
                if (c.detailNo) {
                  console.log(c);
                }
                if (c.latitude) {
                  c.color = 'blue';
                } else {
                  c.color = '#d9d9d9';
                }
              });
              // this.mapData = result.dataList.filter(t => t.latitude);
              if (!deleteCb) {
                this.drawCoordinate(result['dataList'].filter(c => !c.detailNo));
              }
              this.pageList = chunkArray(result['dataList'].filter(c => !c.detailNo), 27);
              this.pageData = this.pageList[0];
            }
          }
        },
        err => { },
        () => {
          this.harewareLoading = false;
        },
      );
  }


}
