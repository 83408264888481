import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService, _HttpClient } from '@service/common';
import { isNullOrUndefined } from 'util';
import { urlSerialize } from '../../utils/url';
import { m2ToMu, numberAbbr } from '../../utils/util';

@Component({
  selector: 'ngx-draw-gis-map',
  templateUrl: './draw-gis-map.component.html',
  styleUrls: ['./draw-gis-map.component.scss'],
})
export class DrawGisMapComponent implements OnInit {
  @Input() modalData: any = {}; //弹框数据
  currentArea_: any = '';

  lnglat = [];

  constructor(private notifySrv: NotificationService, private activeModal: NgbActiveModal, private http: _HttpClient) {
    // 打开的时候，获取基地位置信息
    const paramJson: Object = {
      recNo: '0',
    };
    this.http.post(urlSerialize('CropAreaTreePc/getAllData', 'Base_four'), paramJson).subscribe(result => {
      if (Array.isArray(result) && result.length > 0) {
        this.lnglat = [result[0].longitude, result[0].latitude];
      }
    });
  }

  ngOnInit() {
    //
  }
  /**
   *
   * @param $event
   */
  mapChange($event: any) {
    if ($event.currentArea != undefined) {
      this.notifySrv.confirm(
        () => {
          this.activeModal.dismiss($event);
        },
        {
          message: `当前地块面积${numberAbbr(m2ToMu($event.currentArea))} 亩 ,是否提交当前地块？`,
        },
      );
    } else {
      this.currentArea_ = $event.currentArea;
      this.f1Close();
    }
  }

  f1Close = () => {
    if (isNullOrUndefined(this.currentArea_)) {
      this.activeModal.dismiss(0);
    } else {
      this.activeModal.close();
    }
  };
}
