// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  SERVER_URL: `./`,
  production: false,
  useHash: false,
  hmr: false,
  // dnsUrl: 'http://192.168.0.38:6801/',
  // dnsUrl: "http://qtsystem.fineone.xyz:6801/",
  mapKey: '5c232a84e61bb48c1b7be191a3e7c88b',
  dnsUrl: "http://farmsystem.fine1.cn:6801/",
  accessToken:
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNzliM2Q4MC0yZjVkLTQ0OTYtODgzMy1kM2ZjNTk3Mzk2OWMiLCJpZCI6ODQ4NDUsImlhdCI6MTY0NjYzNzA2NH0.27zR06jjOmPqpx__7JsVizIHo9nwb45c8Buro-AUvPs',
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
