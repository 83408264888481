import { guid } from './util';

export class F1Echart {
  mapId = `map-${guid()}`;
  f1chart: any;

  mapName = '';
  regionsData: any[] = [];

  zoom = 8;

  mapboxgl_center: any[] = [];
  mapboxgl_style = 'mapbox://styles/anberm/cku59zit02ec018nz80nxnhnj';

  currentOption: any = {};

  legendData: any[] = [];

  get baseOption(): any {
    return {
      legend: {
        show: false,
        data: this.legendData,
        // data: [{
        //     name: '系列1',
        //     // // 强制设置图形为圆。
        //     // icon: 'circle',
        //     // // 设置文本为红色
        //     // textStyle: {
        //     //     color: 'red'
        //     // }
        // }]
      },
      tooltip: {
        show: true,
        trigger: 'item',
        showContent: true,
        formatter: '{b} ',
        //    ==> {a} <br/>{b} : {c} ({d}%)  -- 替换为   {b}:  ({d}%) 取消了a} <br/>
        //    ==> 看下a代表系列名，并且<br/>为换行，所以就感觉应该是修改这里，还真的隐藏掉了
      },

      mapbox3D: {
        center: this.mapboxgl_center,
        zoom: this.zoom,
        style: this.mapboxgl_style,
        pitch: 60,
        bearing: -40,
        antialias: true,
        shading: 'realistic',
        light: {
          main: {
            intensity: 3,
            shadow: true,
            shadowQuality: 'high',
            alpha: 30,
          },
          ambient: {
            intensity: 0,
          },
        },
      },

      series: [],
    };
  }
  get qitaiSeries(): any {
    return [
      {
        name: 'bar3D', // scatter3D 无法选中。。。
        type: 'bar3D',
        coordinateSystem: 'mapbox3D',
        shading: 'lambert',
        silent: false,
        barSize: 2,
        show: false,
        label: {
          show: false,
        },

        itemStyle: {
          opacity: 0.01,
          color: '#fff', // 图形的颜色
        },
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            opacity: 0.01,
            color: '#fff', // 图形的颜色
          },
        },
        data: [
          {
            name: '涨坝村',
            value: [89.527895, 43.817011, 9000],
          },
          {
            name: '小屯村',
            value: [89.480645, 44.011488, 9000],
          },
          {
            name: '老葛根村',
            value: [89.755342, 43.782179, 9000],
          },
        ],
      },
      {
        name: 'scatter3D', // scatter3D 无法选中。。。
        type: 'scatter3D',
        coordinateSystem: 'mapbox3D',
        shading: 'lambert',
        silent: false,
        symbol: 'pin',
        symbolSize: 40,
        label: {
          show: true,
          formatter: '{b}',
          textStyle: {
            color: '#fff',
            backgroundColor: '#032042',
            padding: 5,
          },
        },

        itemStyle: {
          color: '#F4E925', // 图形的颜色
        },
        emphasis: {
          label: {
            show: true,
          },
          itemStyle: {
            color: '#0265B6',
            borderWidth: 6.4,
            borderColor: '#3298DC', //省市边界线
          },
        },
        data: [
          {
            name: '涨坝村',
            value: [89.527895, 43.817011, 7000],
          },
          {
            name: '小屯村',
            value: [89.480645, 44.011488, 7000],
          },
          {
            name: '老葛根村',
            value: [89.755342, 43.782179, 7000],
          },
        ],
      },
    ];
  }

  get mapSeries(): any {
    return {
      type: 'map3D',
      map: this.mapName,
      coordinateSystem: 'mapbox3D',
      shading: 'lambert',
      silent: false,
      itemStyle: {
        color: '#124879',
        borderWidth: 6.4,
        borderColor: '3298DC', //省市边界线
      },
      label: {
        show: false,
        distance: 0,
      },
      emphasis: {
        label: {
          show: false,
          distance: 0,
        },
        itemStyle: {
          color: '#0265B6',
          borderWidth: 6.4,
          borderColor: '#3298DC', //省市边界线
        },
      },
      data: this.regionsData,
    };
  }

  init(name: string, geoJson: any, zoom: number = 8, height = 4700): void {
    const el = document.querySelector(`#${this.mapId}`);
    if (!el) {
      return;
    }
    if (!geoJson || !geoJson.features) {
      console.error('geojson 不正确');

      return;
    }
    if (zoom) {
      this.zoom = zoom;
    }
    mapboxgl.accessToken =
      'pk.eyJ1IjoiYW5iZXJtIiwiYSI6ImNrYnJqNXVnaDJ3ejMyb214a2U4MXlkazkifQ.nsXT5HbJUuhQ6ECvR9i2Sw';

    if (!(this.mapboxgl_center && this.mapboxgl_center.length == 2)) {
      this.mapboxgl_center = geoJson.features[0].properties.center;
    }

    this.regionsData = geoJson.features.map((feature: any) => {
      return {
        name: feature.properties.name,
        height: height,
        recNo:feature.properties.recNo
        // label: {
        //     show: true,
        //     distance: 0,

        // }
      };
    });
    this.mapName = name;
    echarts.registerMap(this.mapName, geoJson);
    if (this.f1chart) {
      try {
        this.f1chart.dispose();
        this.f1chart = null;
      } catch (error) {}
    }

    this.f1chart = echarts.init(el);
  }

  updateOption(option: any): void {
    if (!this.f1chart) {
      console.error('请先调用 init');

      return;
    }
    const tOption = { ...this.baseOption, ...option };
    this.currentOption = tOption;
    this.f1chart.setOption(tOption);
  }

  dispose(): void {
    try {
      if (this.f1chart && !this.f1chart.isDisposed()) {
        this.f1chart.clear();
        this.f1chart.dispose();
        this.f1chart = undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
