import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { startOfDay, endOfDay } from 'date-fns';

@Component({
  selector: 'f1-time-range',
  templateUrl: './time-range.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeRangeComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .f-time-range {
        display: flex;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimeRangeComponent implements ControlValueAccessor {
  now = new Date();
  value = [null, null];
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(val: [Date, Date]): void {
    if (Array.isArray(val)) {
      if (val[0]) {
        this.value[0] = val[0];
      }
      if (val[1]) {
        this.value[1] = val[1];
      }
    }
    this.setValue();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(): void {
    this.setValue();
  }

  setValue(): void {
    this.onChange(this.value);
  }
  reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): void {
    this.value = [null, null];
    this.setValue();
    this.cdr.markForCheck();
  }
}
