/**
 * 分页对象
 */
export class Pagination {
  start: number = 1; //当前页
  limit: number = 20; //每页显示条数 默认为20条
  pageCount: number = 0; //总的页数
  totalSize: number = 0; //总记录数
  data: any[] = []; //数据
  queryParam: any = {}; //查询对象

  /**
   * 构造函数
   * @param paramJson
   */
  constructor(paramJson: any = {}) {
    this.initPagination(paramJson);
  }

  /**
   * 页面参数初始化
   * @param paramJson
   */
  initPagination(paramJson: any = {}) {}

  /**
   * 设置当前页 默认为1
   * @param start
   */
  setStart(start: number = 1) {
    this.start = start;
    return this;
  }

  /**
   * 设置每页显示条数 默认为20条
   * @param limit
   */
  setLimit(limit: number = 20) {
    this.limit = limit;
    return this;
  }

  /**
   * 获取分页所需要的参数对象，不同的数据库分页请求参数索引不一样，需根据实际情况进行修改
   * @returns {{start: number, limit: number}}
   */
  getPaginationParam() {
    if (isNaN(this.start)) {
      this.start = 1;
    }
    return { start: (this.start - 1) * this.limit, limit: this.limit };
  }

  /**
   * 获取表格查询参数
   * @param paramJson
   * @returns {any}
   */
  getQueryParam(paramJson: any = {}) {
    paramJson = Object.assign({}, { isPagination: true }, paramJson); //默认有分页参数，如不需要则传{isPagination:false}
    let tableQueryParam: any = this.queryParam; //当前查询参数
    if (paramJson['isPagination']) {
      tableQueryParam = Object.assign({}, tableQueryParam, this.getPaginationParam());
    }
    return tableQueryParam;
  }

  /**
   * 数据加载
   */
  loadData(result) {
    if (this.isJson(result)) {
      this.pageCount = result['pageCount'] || 0;
      this.totalSize = result['totalSize'] || 0;
      this.data = result['dataList'] || [];
    }
  }

  /**
   * 清除表格查询参数
   */
  clearQueryParam() {
    this.queryParam = {};
  }

  /**
   * 判断是否JSON对象
   * @param value
   */
  isJson(value): boolean {
    return (
      typeof value == 'object' &&
      Object.prototype.toString.call(value).toLowerCase() == '[object object]' &&
      !value.length
    );
  }
}
