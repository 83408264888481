import { Injectable } from '@angular/core';

export interface ITokenModel {
  [key: string]: any;

  token: string | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private TOKEN_KEY = 'FINE1_TOKEN';

  mockData = [
    {
      landName: '1-1#',
      mapList:
        '[{"lat":43.82029,"lng":89.53126},{"lat":43.81677,"lng":89.53061},{"lat":43.81801,"lng":89.5381},{"lat":43.82179,"lng":89.53933}]',
      mlandRecNo: '0',
      recNo: 'BaseLand166bdc33397e454cb593fed58c12da5c',
      expandable: false,
      grownArea: '0',
      landArea: '846.49',
      typeName: '子地块',
      noGrownArea: '1000.0',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '1-1#',
      isUser: 1,
    },
    {
      landName: '1-2#',
      mapList:
        '[{"lat":43.81573,"lng":89.5316},{"lat":43.81708,"lng":89.53892},{"lat":43.81528,"lng":89.54006},{"lat":43.81425,"lng":89.53993},{"lat":43.81309,"lng":89.53508},{"lat":43.81231,"lng":89.53484},{"lat":43.81157,"lng":89.53128},{"lat":43.81158,"lng":89.53128}]',
      mlandRecNo: '0',
      recNo: 'BaseLand565dee2894eb42eea97d17458ad59a78',
      expandable: false,
      grownArea: '0',
      landArea: '390.66',
      typeName: '子地块',
      noGrownArea: '500.0',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '1-2#',
      isUser: 1,
    },
    {
      landName: '1-4#',
      mapList:
        '[{"lat":43.82669,"lng":89.53297},{"lat":43.82903,"lng":89.54044},{"lat":43.82564,"lng":89.53995},{"lat":43.82326,"lng":89.53214}]',
      mlandRecNo: '0',
      recNo: 'BaseLand6fd63a47143142a2a4141c1dd02d0d7f',
      expandable: false,
      grownArea: '0',
      landArea: '328.48',
      typeName: '子地块',
      noGrownArea: '500.0',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '1-4#',
      isUser: 1,
    },
    {
      landName: '2-2#',
      mapList:
        '[{"lat":43.82303,"lng":89.53203},{"lat":43.82542,"lng":89.53995},{"lat":43.8219,"lng":89.53907},{"lat":43.82025,"lng":89.53134}]',
      mlandRecNo: '0',
      recNo: 'BaseLand1aa9c9b0147c4d79b1bea6e1da66e261',
      expandable: false,
      grownArea: '0',
      landArea: '309.3',
      typeName: '子地块',
      noGrownArea: '1000.0',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '2-2#',
      isUser: 1,
    },
    {
      landName: '3-1#',
      mapList:
        '[{"lat":43.81758,"lng":89.50867},{"lat":43.81789,"lng":89.51494},{"lat":43.81447,"lng":89.51477},{"lat":43.81437,"lng":89.50902}]',
      mlandRecNo: '0',
      recNo: 'BaseLand3c43e41e2bff4bf092897d090e33ebe7',
      expandable: false,
      grownArea: '0',
      landArea: '267.47',
      typeName: '子地块',
      noGrownArea: '267.47',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-1#',
      isUser: 1,
    },
    {
      landName: '3-2#',
      mapList:
        '[{"lat":43.8179,"lng":89.51539},{"lat":43.81912,"lng":89.52174},{"lat":43.81725,"lng":89.52163},{"lat":43.81697,"lng":89.52097},{"lat":43.81703,"lng":89.51975},{"lat":43.81696,"lng":89.5196},{"lat":43.81623,"lng":89.51953},{"lat":43.81613,"lng":89.51951},{"lat":43.81536,"lng":89.5153},{"lat":43.81536,"lng":89.5153}]',
      mlandRecNo: '0',
      recNo: 'BaseLand10b0ff9ffa8e402ca0503e27bdb4db57',
      expandable: false,
      grownArea: '0',
      landArea: '196.91',
      typeName: '子地块',
      noGrownArea: '196.91',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-2#',
      isUser: 1,
    },
    {
      landName: '3-3#',
      mapList:
        '[{"lat":43.81993,"lng":89.52499},{"lat":43.82063,"lng":89.53057},{"lat":43.81875,"lng":89.5301},{"lat":43.81864,"lng":89.52881},{"lat":43.81728,"lng":89.52815},{"lat":43.81737,"lng":89.52703},{"lat":43.81679,"lng":89.52446},{"lat":43.81689,"lng":89.52446}]',
      mlandRecNo: '0',
      recNo: 'BaseLand0378c476a6c941c0bba94d83dd7b4da2',
      expandable: false,
      grownArea: '0',
      landArea: '193.07',
      typeName: '子地块',
      noGrownArea: '193.07',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-3#',
      isUser: 1,
    },
    {
      landName: '3-5#',
      mapList:
        '[{"lat":43.82044,"lng":89.50886},{"lat":43.82073,"lng":89.50937},{"lat":43.82112,"lng":89.51135},{"lat":43.82128,"lng":89.51495},{"lat":43.81812,"lng":89.51484},{"lat":43.81787,"lng":89.5089},{"lat":43.81942,"lng":89.50873},{"lat":43.82002,"lng":89.50847},{"lat":43.82001,"lng":89.50849}]',
      mlandRecNo: '0',
      recNo: 'BaseLandafd6273922214f858811032dc3e6d3ad',
      expandable: false,
      grownArea: '0',
      landArea: '251.86',
      typeName: '子地块',
      noGrownArea: '251.86',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-5#',
      isUser: 1,
    },
    {
      landName: '3-6#',
      mapList:
        '[{"lat":43.82123,"lng":89.51534},{"lat":43.82227,"lng":89.52141},{"lat":43.81937,"lng":89.5216},{"lat":43.81812,"lng":89.51538},{"lat":43.81815,"lng":89.5154}]',
      mlandRecNo: '0',
      recNo: 'BaseLand1428d8d36a2541b693a4d7cfc3e65e83',
      expandable: false,
      grownArea: '0',
      landArea: '249.0',
      typeName: '子地块',
      noGrownArea: '249.0',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-6#',
      isUser: 1,
    },
    {
      landName: '3-7#',
      mapList:
        '[{"lat":43.82259,"lng":89.52351},{"lat":43.81965,"lng":89.52366},{"lat":43.82094,"lng":89.53068},{"lat":43.82386,"lng":89.53119},{"lat":43.82386,"lng":89.53119}]',
      mlandRecNo: '0',
      recNo: 'BaseLande4bec4be4d3d41d38d7dac6db6ad8c56',
      expandable: false,
      grownArea: '0',
      landArea: '285.73',
      typeName: '子地块',
      noGrownArea: '285.73',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '3-7#',
      isUser: 1,
    },
    {
      landName: '4-1#',
      mapList:
        '[{"lat":43.82202,"lng":89.51162},{"lat":43.82343,"lng":89.51508},{"lat":43.82146,"lng":89.51504},{"lat":43.82135,"lng":89.5115},{"lat":43.82168,"lng":89.51135},{"lat":43.82169,"lng":89.51132}]',
      mlandRecNo: '0',
      recNo: 'BaseLanda48f9e9641334111a62d79d4342edaca',
      expandable: false,
      grownArea: '0',
      landArea: '62.17',
      typeName: '子地块',
      noGrownArea: '62.17',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '4-1#',
      isUser: 1,
    },
    {
      landName: '4-2#',
      mapList:
        '[{"lat":43.82346,"lng":89.51534},{"lat":43.82488,"lng":89.51879},{"lat":43.82542,"lng":89.52171},{"lat":43.82255,"lng":89.5218},{"lat":43.82148,"lng":89.5154},{"lat":43.82152,"lng":89.5154}]',
      mlandRecNo: '0',
      recNo: 'BaseLand79ad4ae8e20447bda413d844b0ee78df',
      expandable: false,
      grownArea: '0',
      landArea: '224.47',
      typeName: '子地块',
      noGrownArea: '224.47',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '4-2#',
      isUser: 1,
    },
    {
      landName: '4-3#',
      mapList:
        '[{"lat":43.83159,"lng":89.52362},{"lat":43.83524,"lng":89.53375},{"lat":43.83165,"lng":89.53289},{"lat":43.82784,"lng":89.52272},{"lat":43.83071,"lng":89.5234}]',
      mlandRecNo: '0',
      recNo: 'BaseLandbf95069e2df54ccc9d4bd7867b618bcd',
      expandable: false,
      grownArea: '0',
      landArea: '455.7',
      typeName: '子地块',
      noGrownArea: '455.7',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '4-3#',
      isUser: 1,
    },
    {
      landName: '4-4#',
      mapList:
        '[{"lat":43.83521,"lng":89.52465},{"lat":43.83182,"lng":89.52364},{"lat":43.83552,"lng":89.53385},{"lat":43.83903,"lng":89.53444},{"lat":43.83901,"lng":89.53442}]',
      mlandRecNo: '0',
      recNo: 'BaseLandc95d4cf3ffd344fea7a318d7930b0f3a',
      expandable: false,
      grownArea: '0',
      landArea: '421.45',
      typeName: '子地块',
      noGrownArea: '421.45',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '4-4#',
      isUser: 1,
    },
    {
      landName: '4-5#',
      mapList:
        '[{"lat":43.84347,"lng":89.52688},{"lat":43.84381,"lng":89.53452},{"lat":43.84312,"lng":89.53452},{"lat":43.84302,"lng":89.53555},{"lat":43.8394,"lng":89.53482},{"lat":43.83567,"lng":89.52478},{"lat":43.83892,"lng":89.52566}]',
      mlandRecNo: '0',
      recNo: 'BaseLand8d0f61e3c45643139c870e8190bf10de',
      expandable: false,
      grownArea: '0',
      landArea: '722.84',
      typeName: '子地块',
      noGrownArea: '722.84',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '4-5#',
      isUser: 1,
    },
    {
      landName: '5-1#',
      mapList:
        '[{"lat":43.82541,"lng":89.52206},{"lat":43.82734,"lng":89.53212},{"lat":43.82411,"lng":89.53141},{"lat":43.82256,"lng":89.52212},{"lat":43.82454,"lng":89.52206}]',
      mlandRecNo: '0',
      recNo: 'BaseLandcb1bb9804e7a4165b8982e3e680f1e08',
      expandable: false,
      grownArea: '0',
      landArea: '387.2',
      typeName: '子地块',
      noGrownArea: '387.2',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '5-1#',
      isUser: 1,
    },
    {
      landName: '5-2#',
      mapList:
        '[{"lat":43.82742,"lng":89.52238},{"lat":43.83142,"lng":89.53314},{"lat":43.82749,"lng":89.53198},{"lat":43.82553,"lng":89.52208},{"lat":43.82624,"lng":89.52219},{"lat":43.82624,"lng":89.52219}]',
      mlandRecNo: '0',
      recNo: 'BaseLand6722aef517f64d95b5abe1e73e140168',
      expandable: false,
      grownArea: '0',
      landArea: '373.96',
      typeName: '子地块',
      noGrownArea: '373.96',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '5-2#',
      isUser: 1,
    },
    {
      landName: '6-1#',
      mapList:
        '[{"lat":43.83081,"lng":89.53386},{"lat":43.83258,"lng":89.54107},{"lat":43.82926,"lng":89.54055},{"lat":43.827,"lng":89.53304}]',
      mlandRecNo: '0',
      recNo: 'BaseLandf95e1dc22f5042ffb94eba9b1ff8bbd6',
      expandable: false,
      grownArea: '0',
      landArea: '333.74',
      typeName: '子地块',
      noGrownArea: '333.74',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '6-1#',
      isUser: 1,
    },
    {
      landName: '6-2#',
      mapList:
        '[{"lat":43.83425,"lng":89.5344},{"lat":43.83626,"lng":89.54174},{"lat":43.83295,"lng":89.54123},{"lat":43.83115,"lng":89.53376}]',
      mlandRecNo: '0',
      recNo: 'BaseLanda428a7e161714395adca6bb6cb87d80e',
      expandable: false,
      grownArea: '0',
      landArea: '303.54',
      typeName: '子地块',
      noGrownArea: '303.54',
      type: '1',
      authorizationCodeName: '',
      checked: false,
      isNotLand: 1,
      landTypeName: '大棚',
      landType: 1,
      mobilePhoneTypeName: '是',
      mobilePhoneType: 1,
      leaf: true,
      isshowChecked: true,
      plantType: 1,
      unitArea: '亩',
      plantTypeName: '种植大户',
      isNotLandName: '是',
      landNickName: '6-2#',
      isUser: 1,
    },
  ];

  currentCom = {
    comCode: '',
    name: '新疆奇台县农业数据平台',
  };
  /**
   * 设置为奇台县
   */
  setHome(): void {
    this.currentCom = {
      comCode: '',
      name: '新疆奇台县农业数据平台',
    };
  }
  /**
   * 设置为涨坝村
   */
  setZbc(): void {
    this.currentCom = {
      comCode: '',
      name: '涨坝村农业数据平台',
    };
  }

  /**
   * 设置为小屯村
   */
  setXtc(): void {
    this.currentCom = {
      comCode: '',
      name: '小屯村农业数据平台',
    };
  }

  /**
   * 设置为老葛根村
   */
  setLggc(): void {
    this.currentCom = {
      comCode: '',
      name: '老葛根村农业数据平台',
    };
  }

  _hardwareTypeMaps: any[] = [];

  setHardwareTypeMap(val: any[]) {
    this._hardwareTypeMaps = val;
  }
  getHardwareTypeMap() {
    return this._hardwareTypeMaps;
  }

  constructor() {}

  clearToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  setToken(data: ITokenModel) {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(data));
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.TOKEN_KEY) || '{}') || {};
  }
}
