import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapShowModule } from '../map-show/map-show.module';
import { MapToolModule } from '../map-tool/map-tool.module';
import { DrawMapComponent } from './draw-map.component';

const COMPONENTS = [DrawMapComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MapToolModule, MapShowModule],
  exports: COMPONENTS,
  entryComponents: [DrawMapComponent],
})
export class DrawMapModule {}
