import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { urlSerialize } from '../../../utils/url';
import {
  NzFormatEmitEvent,
  NzModalRef,
  NzModalService,
  NzTreeComponent,
  NzTreeNode,
  NzTreeNodeOptions,
  NzTreeSelectComponent,
  NzTreeSelectService,
} from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService, deepCopy, NotificationService } from '@service/common';
import { ArrayService } from '@shared';
import { format, isDate } from 'date-fns';
import { MaterialSelectComponent } from '../material/material.component';

export interface Timeline {
  name?: string;
  subName?: string;
  author?: string;
  dataRange?: [Date, Date];
  recNo?: string;
  landRecNo?: string;
  [key: string]: any;
}

export interface Proline {
  proName?: string;
  proNo?: string;
  dilutionRatio?: string;
  proNum?: string;
  author?: string;
  proType?: string;
  proTypeName?: string;
  proSpe?: string;
  proUnitName?: string;
  proUnit?: any;
  proUsage?: string;
}

export interface TableData {
  name: string;
  timelines: Timeline[];
  prolines: Proline[];
}

@Component({
  selector: 'f1-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  @ViewChild('treeSelectCom', { static: false }) treeSelectCom: NzTreeSelectComponent;
  private treeView!: NzTreeComponent;
  validateForm: FormGroup;
  prodateForm: FormGroup;
  private _period = 7;
  @ViewChild('echartsRef', {
    static: false,
  })
  echartsRef: ElementRef;
  oECharts: any = null; //图表对象
  @Input()
  get period() {
    return this._period;
  }

  set period(value) {
    this._period = value;
    this.genTable();
  }

  private _record: any;
  @Input()
  get record(): any {
    return this._record;
  }
  set record(value: any) {
    this._record = value;
    this.refreshTree().then();
  }

  tableData: TableData[] = [];

  dropList: string[] = [];

  proUnitData: any[] = [];
  rulesData: any[] = [];
  searchValue = '';

  landTree: NzTreeNodeOptions[] = [];

  selectLandTree: NzTreeNodeOptions[] = [];
  
  time: Date | null = null;

  loading = false;
  /**
   * 当前数据
   */
  currentData: any;

  /**
   * 当前数据
   */
  currentDataTwo;
  /**
   * 当前容器
   */
  currentContainer: any;

  isVisible = false;
  isVisibleTwo = false;

  index = 0;
  optionList = [];
  selectedValue = [];
  prolines = [];
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalSrv: NzModalService,
    private notifySrv: NotificationService,
    private appSrv: AppService,
    private zone: NgZone,
    @Optional()
    private modalRef?: NzModalRef,
  ) {
    this.validateForm = this.fb.group({
      groupRecNo: [null, [Validators.required]],
      timeRange: [null, [Validators.required]],
      planWaterNum: [0],
      adviceWaterNum: [0],
      deviceNo: [null],
    });
    this.prodateForm = this.fb.group({
      proName: [null],
      proNo: [null],
      proNum: [null],
      dilutionRatio: [null],
      proType: [null],
      proTypeName: [null],
      proSpe: [null],
      proUnit: [null],
      proUnitName: [null],
      proUsage: [null],
    });
  }

  ngOnInit(): void {}

  async refreshTree() {
    this.loading = true;
    this.landTree = await this.loadLand(this.record && this.record.landNo ? this.record.landNo : '0');
    this.selectLandTree = [...this.landTree];
    this.loading = false;
    this.getProUnit();
    this.getRules();
    this.cdr.markForCheck();
  }

  async getProUnit() {
    const result = await this.http
      .post(urlSerialize('ImportHelper/selectBySearchkey', 'Base_four'), {
        key: 'proUnit',
      })
      .toPromise();
    if (Array.isArray(result)) {
      this.proUnitData = result;
    }
  }

  //规则
  async getRules() {
    const result: any = await this.http.post(urlSerialize('IrrigationProcess/Page ', 'Base_6806'), {}).toPromise();
    console.log(result);
    if (result.dataList.length > 0) {
      this.rulesData = result.dataList;
    }
  }

  // /IrrigationProcess/Page

  genTable(): void {
    this.tableData = [];
    this.dropList = [];
    for (let index = 0; index < this.period; index++) {
      this.tableData.push({
        name: `第${index + 1}天`,
        timelines: this.genSimple(index + 1),
        prolines: this.genSinpleTwo(index + 1),
      });
      this.dropList.push(`f1-drop-list-${index}`);
    }
    console.log(this.tableData);
  }

  /**
   * 生成数据
   */
  genSimple(cycleNum: number): Timeline[] {
    const td: Timeline[] = [];
    if (Array.isArray(this.record.scheduleData)) {
      const ds: any[] = this.record.scheduleData.filter(c => c.cycleNum == cycleNum);
      const prefix = format(new Date(), 'YYYY-MM-DD');
      ds.forEach(d => {
        td.push({
          name: d.groupName,
          subName: d.landName,
          author: this.record.planWorker,
          // dataRange: [new Date(`${prefix} ${d.startTime}`), new Date(`${prefix} ${d.endTime}`)],
          recNo: d.recNo,
          deviceGroupNo: d.deviceGroupNo,
          edit: true,
          startTime: d.startTime,
          endTime: d.endTime,
          adviceWaterNum: d.adviceWaterNum,
          deviceNo: d.deviceNo,
          planType: d.planType,
          groupName: d.groupName,
          masterNo: d.masterNo,
          proInfoData: d.proInfoData,
          realUseProNum: d.realUseProNum,
          realWaterNum: d.realWaterNum,
          planWaterNum: d.planWaterNum,
          planUseProNum: d.planUseProNum,
        });
      });
    }
    return td;
  }

  genSinpleTwo(cycleNum: number): Proline[] {
    const td: Proline[] = [];
    if (Array.isArray(this.record.proData)) {
      const ds: any[] = this.record.proData.filter(c => c.cycleNum == cycleNum);
      ds.forEach(d => {
        td.push({
          proName: d.proName,
          proNo: d.proNo,
          dilutionRatio: d.dilutionRatio,
          proNum: d.proNum,
          proType: d.proType,
          proTypeName: d.proTypeName,
          proSpe: d.proSpe,
          proUnit: d.proUnit,
          proUnitName: d.proUnitName,
          proUsage: d.proUsage,
        });
      });
    }
    return td;
  }

  log($event) {
    console.log($event);
    this.getEchart(this.selectedValue[$event]);
  }

  nzEvent(event: NzFormatEmitEvent): void {
    console.log(event);
  }

  /*
   * 加载基地地块信息
   */
  async loadLand(recNo = '0') {
    const result = await this.http
      .post(urlSerialize('HardwareValvegroupLand/getLandAndValveGroup', 'Base_6806'), {
        recNo,
      })
      .toPromise();
    const lt: NzTreeNodeOptions[] = [];
    if (Array.isArray(result)) {
      for (const c of result) {
        const n: NzTreeNodeOptions = {
          title: c.landName,
          key: c.recNo,
          type: 'land',
          disabled: true,
          expanded: true,
          children: [],
        };
        if (!c.leaf) {
          if (Array.isArray(c.groupList)) {
            n.children.push(
              ...c.groupList.map(g => {
                return {
                  title: g.groupName,
                  key: g.groupRecNo,
                  deviceGroupNo: g.groupRecNo,
                  isLeaf: true,
                  type: 'group',
                  disabled: false,
                  landName: c.landName,
                  landRecNo: c.recNo,
                  mlandRecNo: c.mlandRecNo,
                };
              }),
            );
          }
          n.children.push(...(await this.loadLand(c.recNo)));

          n.isLeaf = false;
        } else {
          if (Array.isArray(c.groupList)) {
            n.children = c.groupList.map(g => {
              return {
                title: g.groupName,
                key: g.groupRecNo,
                deviceGroupNo: g.groupRecNo,
                isLeaf: true,
                type: 'group',
                disabled: false,
                landName: c.landName,
                landRecNo: c.recNo,
                mlandRecNo: c.mlandRecNo,
              };
            });
            n.isLeaf = false;
          } else {
            n.isLeaf = true;
          }
        }
        lt.push(n);
      }
    }
    return lt;
  }

  async getEchartTab() {
    console.log(this.selectLandTree);
    console.log(this.validateForm.value.groupRecNo);
    let a;
    this.selectLandTree.forEach(x => {
      x.children.forEach(y => {
        if (y.key === this.validateForm.value.groupRecNo) {
          a = y.landRecNo;
        }
      });
    });
    if (a) {
      const result = await this.http
        .post(urlSerialize('WorkPlan/selectGreenDetailByLandNo', 'Base_four'), {
          landNo: a,
        })
        .toPromise();
      if (Array.isArray(result) && result.length > 0) {
        console.log(result);
        this.zone.run(() => {
          this.optionList = result[0].flow.detailList;
          this.selectedValue = [];
          this.selectedValue.push(result[0].flow.detailList[0]);
          this.getEchart(result[0].flow.detailList[0]);
        });
      }
    }
  }

  async getEchart(item) {
    console.log(item);
    console.log(Number(item.floorNum.split(',')[0]));
    const result: any = await this.http
      .post(urlSerialize('HardwareValveLand/getSoilByNumber', 'Base_6806'), {
        landNo: this.record.landNo,
        hardwareDecpType: 5,
        number: Number(item.floorNum.split(',')[0]),
      })
      .toPromise();
    if (result.length > 0) {
      this.loadEChartsData(result[0]);
    }
  }

  async loadEChartsData(item) {
    const result = await this.http
      .post(urlSerialize('influxdb/getTimeData', 'Base_6806'), {
        hardwaresbasefacility: [
          {
            hardwaresbasefacilityName: item.hardwareName,
            hardwaresbasefacilityRecNo: item.valveNo,
          },
        ],
        time: 2,
      })
      .toPromise();
    if (result) {
      this.updateEChartsData(result);
    }
    console.log(result);
  }

  onPro() {
    const tmodalRef = this.modalSrv.create({
      nzContent: MaterialSelectComponent,
      nzWrapClassName: 'f1-modal',
      nzTitle: '物料',
      nzStyle: {
        top: '64px',
      },
      // nzBodyStyle: {
      //   height: '80vh',
      // },
      nzComponentParams: {},
      nzFooter: null,
      nzWidth: '80vw',
    });
    tmodalRef.afterClose.subscribe(result => {
      if (result) {
        this.prodateForm.patchValue({
          proName: result.proName,
          proNo: result.recNo,
          author: this.appSrv.user.realName,
          dilutionRatio: result.dilutionRatio,
          proNum: result.proNum,
          proType: result.proType,
          proTypeName: result.proTypeName,
          proSpe: result.proSpe,
          // proUnit: result.proUnit,
          proUsage: result.proUsage,
        });
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleCancelTwo(): void {
    this.isVisibleTwo = false;
  }

  addTask(container: any): void {
    this.currentData = { edit: false };
    this.currentContainer = container;
    this.validateForm.reset();
    // this.getEchartTab();
    this.isVisible = true;
    // /
  }

  addTaskTwo(container: any): void {
    this.currentContainer = container;
    this.prodateForm.reset();
    this.isVisibleTwo = true;
  }

  edit(item: any, container: any): void {
    console.log(item);
    console.log(container);

    this.currentContainer = container;
    const prefix = format(new Date(), 'YYYY-MM-DD');
    this.zone.run(() => {
      if (item.d) {
        this.currentData = item;
        this.validateForm.patchValue({
          groupRecNo: this.currentData.deviceGroupNo,
          // timeRange: [
          //   new Date(`${prefix} ${this.currentData.startTime}`),
          //   new Date(`${prefix} ${this.currentData.endTime}`),
          // ],
          startTime: this.currentData.startTime,
          planWaterNum: this.currentData.planWaterNum,
          adviceWaterNum: this.currentData.adviceWaterNum,
          deviceNo: this.currentData.deviceNo,
        });
        this.prolines = this.currentData.proInfoData;
      } else {
        this.currentData = item;
        this.validateForm.patchValue({
          groupRecNo: this.currentData.deviceGroupNo,
          // timeRange: [
          //   new Date(`${prefix} ${this.currentData.startTime}`),
          //   new Date(`${prefix} ${this.currentData.endTime}`),
          // ],
          startTime: this.currentData.startTime,
          planWaterNum: this.currentData.planWaterNum,
          adviceWaterNum: this.currentData.adviceWaterNum,
          deviceNo: this.currentData.deviceNo,
        });
        this.prolines = this.currentData.proInfoData;
      }
      this.currentData.edit = true;
      console.log(this.validateForm.value);
      this.isVisible = true;
    });
  }

  editTwo(item: any, container: any): void {
    this.currentDataTwo = item;
    this.currentContainer = container;
    this.prodateForm.patchValue({
      proName: this.currentDataTwo.proName,
      proNo: this.currentDataTwo.proNo,
      dilutionRatio: this.currentDataTwo.dilutionRatio,
      proNum: this.currentDataTwo.proNum,
      proType: this.currentDataTwo.proType,
      proTypeName: this.currentDataTwo.proTypeName,
      proSpe: this.currentDataTwo.proSpe,
      proUnit: this.currentDataTwo.proUnit,
      proUsage: this.currentDataTwo.proUsage,
    });
    this.isVisibleTwo = true;
  }

  del(item: any, container: any): void {
    this.currentData = item;
    this.currentContainer = container;
    if (this.record && this.record.recNo) {
      this.http
        .post(urlSerialize('WorkPlanDetail/delete', 'Base_four'), {
          recNo: item.recNo,
        })
        .subscribe(res => {
          this.currentContainer.timelines = this.currentContainer.timelines.filter(c => c != item);
        });
    } else {
      this.currentContainer.timelines = this.currentContainer.timelines.filter(c => c != item);
    }
  }

  delTwo(item: any, container: any): void {
    this.currentDataTwo = item;
    this.currentContainer = container;
    // if (this.record && this.record.recNo) {
    //   this.http
    //     .post(urlSerialize('WorkPlanDetail/delete', 'Base_four'), {
    //       recNo: item.recNo,
    //     })
    //     .subscribe(res => {
    //       this.currentContainer.timelines = this.currentContainer.timelines.filter(c => c != item);
    //     });
    // } else {
    this.currentContainer.prolines = this.currentContainer.prolines.filter(c => c != item);
    // }
  }

  /**
   * 拖动到指定容器
   * @param $event
   */
  async cdkDropListDropped($event: any): Promise<void> {
    if ($event && $event.item.data.origin && $event.item.data.origin.type === 'group') {
      this.currentData = $event.item.data.origin;
      this.currentContainer = $event.container.data;
      console.log($event);
      // await this.getEchartTab($event.item.data.origin.landRecNo);
      await this.validateForm.patchValue({
        groupRecNo: this.currentData.key,
        timeRange: [null, null],
      });
      this.isVisible = true;
    } else {
      this.notifySrv.warning('请选择轮灌组！');
    }
  }

  onChange($event): void {
    console.log($event);
    setTimeout(() => {
      if ($event) {
        this.getEchartTab();
      }
    }, 100);
  }

  generate() {
    if (!this.prodateForm.value.proNo) {
      this.notifySrv.warning('请选择物料！');
      return;
    }
    if (!this.prodateForm.value.proNum) {
      this.notifySrv.warning('请输入用量！');
      return;
    }
    if (!this.prodateForm.value.dilutionRatio) {
      this.notifySrv.warning('请输入稀释比例！');
      return;
    }

    this.prolines.push({
      proName: this.prodateForm.value.proName,
      proNo: this.prodateForm.value.proNo,
      author: this.appSrv.user.realName,
      dilutionRatio: this.prodateForm.value.dilutionRatio,
      proNum: this.prodateForm.value.proNum.toString(),
      proType: this.prodateForm.value.proType,
      proTypeName: this.prodateForm.value.proTypeName,
      proSpe: this.prodateForm.value.proSpe,
      proUnit: this.prodateForm.value.proUnit,
      proUnitName: this.proUnitData.find(p => p.value === this.prodateForm.value.proUnit).valueName,
      proUsage: this.prodateForm.value.proUsage,
      type: 2,
    });
    this.prodateForm.reset();
    console.log(this.prolines);
  }

  getDetails(item) {
    this.prodateForm.patchValue(item);
  }

  submitForm = (): void => {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) {
      return;
    }
    if (
      !Array.isArray(this.validateForm.value.timeRange) ||
      !isDate(this.validateForm.value.timeRange[0]) ||
      !isDate(this.validateForm.value.timeRange[1])
    ) {
      this.notifySrv.warning('请设置时间！');
      return;
    }
    // if (this.prolines.length == 0) {
    //   this.notifySrv.warning('请选择物料！');
    //   return;
    // }
    // if (this.prolines.length == 0) {
    //   this.notifySrv.warning('请选择物料！');
    //   return;
    // }
    const t = this.treeSelectCom.getTreeNodeByKey(this.validateForm.value.groupRecNo);
    if (this.currentData && this.currentData.edit) {
      this.currentData = Object.assign(this.currentData, {
        name: t.origin.title,
        subName: t.origin.landName,
        author: this.appSrv.user.realName,
        // dataRange: deepCopy(this.validateForm.value.timeRange),
        deviceGroupNo: t.origin.key,
        landRecNo: t.origin.landRecNo,
        adviceWaterNum: this.validateForm.value.adviceWaterNum,
        planWaterNum: this.validateForm.value.planWaterNum,
        deviceNo: this.validateForm.value.deviceNo,
        // startTime: format(deepCopy(this.validateForm.value.timeRange)[0], 'HH:mm:ss'),
        // endTime: format(deepCopy(this.validateForm.value.timeRange)[1], 'HH:mm:ss'),
        startTime: format(deepCopy(this.validateForm.value.startTime), 'HH:mm:ss'),
        proInfoData: this.prolines,
      });
    } else {
      this.currentContainer.timelines.push({
        name: t.origin.title,
        subName: t.origin.landName,
        author: this.appSrv.user.realName,
        // dataRange: deepCopy(this.validateForm.value.timeRange),
        // startTime: format(deepCopy(this.validateForm.value.timeRange)[0], 'HH:mm:ss'),
        // endTime: format(deepCopy(this.validateForm.value.timeRange)[1], 'HH:mm:ss'),
        startTime: format(deepCopy(this.validateForm.value.startTime), 'HH:mm:ss'),
        deviceGroupNo: t.origin.key,
        landRecNo: t.origin.landRecNo,
        adviceWaterNum: this.validateForm.value.adviceWaterNum,
        planWaterNum: this.validateForm.value.planWaterNum,
        deviceNo: this.validateForm.value.deviceNo,
        proInfoData: this.prolines,
      });
    }
    console.log(this.currentData);
    console.log(this.currentContainer);
    this.isVisible = false;
    this.prolines = [];

    this.cdr.markForCheck();
  };

  submitFormTwo = (): void => {
    for (const i in this.prodateForm.controls) {
      this.prodateForm.controls[i].markAsDirty();
      this.prodateForm.controls[i].updateValueAndValidity();
    }
    if (this.prodateForm.invalid) {
      return;
    }
    console.log(this.prodateForm.value);

    if (!this.prodateForm.value.proNo) {
      this.notifySrv.warning('请选择物料！');
      return;
    }
    if (!this.prodateForm.value.proNum) {
      this.notifySrv.warning('请输入用量！');
      return;
    }
    if (!this.prodateForm.value.dilutionRatio) {
      this.notifySrv.warning('请输入稀释比例！');
      return;
    }
    this.currentContainer.prolines.push({
      proName: this.prodateForm.value.proName,
      proNo: this.prodateForm.value.proNo,
      author: this.appSrv.user.realName,
      dilutionRatio: this.prodateForm.value.dilutionRatio,
      proNum: this.prodateForm.value.proNum,
      proType: this.prodateForm.value.proType,
      proTypeName: this.prodateForm.value.proTypeName,
      proSpe: this.prodateForm.value.proSpe,
      proUnit: this.prodateForm.value.proUnit,
      proUnitName: this.proUnitData.find(p => p.value === this.prodateForm.value.proUnit).valueName,
      proUsage: this.prodateForm.value.proUsage,
    });

    this.isVisibleTwo = false;
    this.cdr.markForCheck();
  };

  onDeleta(item) {
    this.prolines.forEach((data, i) => {
      if (data.proNo == item.proNo) {
        this.prolines.splice(i, 1);
      }
    });
  }

  onClose(): void {
    this.modalRef.close();
  }

  onSave(): void {
    const ts: any[] = this.tableData.map((t, i) => {
      return t.timelines.map(c => {
        return {
          deviceGroupNo: c.deviceGroupNo,
          cycleNum: i + 1,
          startTime: format(c.startTime, 'HH:mm:ss'),
          // endTime: format(c.dataRange[1], 'HH:mm:ss'),
          deviceNo: c.deviceNo,
          adviceWaterNum: c.adviceWaterNum,
          planWaterNum: c.planWaterNum,
          proInfoData: c.proInfoData.map(x => {
            return {
              proName: x.proName,
              cycleNum: i + 1,
              proNo: x.proNo,
              dilutionRatio: x.dilutionRatio,
              proNum: x.proNum,
              proType: x.proType,
              proTypeName: x.proTypeName,
              proSpe: x.proSpe,
              proUnit: x.proUnit,
              proUnitName: x.proUnitName,
              proUsage: x.proUsage,
            };
          }),
        };
      });
    });
    const result: any = [];
    ts.forEach(i => {
      result.push(...i);
    });
    this.modalRef.close({ result: result });
  }

  /**
   * 初始化图表
   */
  initECharts() {
    if (!this.echartsRef.nativeElement) {
      return;
    }
    this.oECharts = echarts.init(this.echartsRef.nativeElement as HTMLDivElement, 'halloween');
    let option = {
      legend: {
        // x: "center",
        // y: "bottom",
        data: [],
        textStyle: {
          // color: "#deedff",
          fontSize: '13',
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        // formatter: params => {
        //   let arrTooltip: string[] = [];
        //   params.forEach((item, index) => {
        //     if (index == 0) {
        //       arrTooltip = [...arrTooltip, format(item.value[0], 'yyyy-MM-dd hh:mm')];
        //     }
        //     let curTooltip: string = item['marker'] + item['seriesName'] + '：' + Number(item['value'][1]).toFixed(3);
        //     arrTooltip = [...arrTooltip, curTooltip];
        //   });
        //   return arrTooltip.join('<br/>');
        // },
      },
      grid: {
        left: '0',
        right: '14',
        bottom: '20',
        top: '25',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'cfd0d1',
            },
          },
          minInterval: 2600 * 1000,
          maxInterval: 15 * 3600 * 24 * 1000,
          axisLabel: {
            interale: 0,

            // rotate: 45,
            show: true,
            //rotate: 35,
            textStyle: {
              color: '#000000',
              fontSize: 14,
              fontWeight: 0,
            },
            // formatter: val => {
            //   if (this.cycleType == '3h' || this.cycleType == 'd') {
            //     return format(val, 'hh:mm');
            //   } else {
            //     return format(val, 'MM-dd') + '\n' + format(val, 'hh:mm');
            //   }
            // }
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#3f465e',
            },
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            formatter: '{value}',
            color: '#cfd0d1',
            fontSize: 14,
          },
        },
      ],
      series: [],
    };
    this.oECharts.setOption(option);
  }

  /**
   * 更新图表数据
   */
  updateEChartsData(data) {
    setTimeout(() => {
      this.initECharts();
      if (this.oECharts != null) {
        const oLegend: any = {};
        let oLegendData: any[] = [];
        let oSeries: any[] = [];
        for (let index = 0; index < data.length; index++) {
          let eChartsdata: any;
          this.getEChartsDataByInfluxDb(data[index], (d: any) => {
            console.log(d);
            eChartsdata = d;
          });
          oLegendData = [...oLegendData, data[index].hardwaresbasefacilityName];
          oSeries = [
            ...oSeries,
            {
              name: data[index].hardwaresbasefacilityName,
              type: 'line',
              no: data[index].hardwaresbasefacilityRecNo,
              showSymbol: false,
              data: eChartsdata,
              smooth: true,
            },
          ];
        }
        oLegend.data = oLegendData;
        this.oECharts.setOption({
          legend: oLegend,
          series: oSeries,
        });
      }
    }, 500);
  }

  /**
   * 获取influxDb转图表数据
   * @param result
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getEChartsDataByInfluxDb(result, callback?: Function) {
    let echartsData: any[] = [];
    const results: any[] = result.results;
    if (results != null && results.length > 0) {
      const series: any[] = results[0].series;
      if (series != null && series.length > 0) {
        const values: any[] = series[0].values;
        values.forEach(item => {
          echartsData = [...echartsData, { value: item }];
        });
      }
    }
    if (callback != null) {
      callback(echartsData);
    }
  }
}
