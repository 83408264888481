import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrawAreaComponent } from './draw-area/draw-area.component';
import { DrawLandComponent } from './draw-land/draw-land.component';
import { DrawLineComponent } from './draw-line/draw-line.component';
import { MapToolComponent } from './map-tool.component';

const COMPONENTS = [MapToolComponent, DrawLandComponent, DrawLineComponent, DrawAreaComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: COMPONENTS,
})
export class MapToolModule {}
