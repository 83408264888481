import { deepCopy } from '@service/common';
import { Injectable } from '@angular/core';
import { CacheService } from './cache/cache.service';
import { NbMenuService, NbMenuItem } from '@nebular/theme';
import { _HttpClient } from './http/http.client';
import { NbAuthService } from '@nebular/auth';
import { toNumber } from './util/other/check';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'util';
import { urlSerialize } from 'app/shared/utils/url';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LmUser {
  access_token: string;
  comType: number;
  companyNo: string;
  customName: string;
  customNo: string;
  expires_in: number;
  granularityRoleNo: string;
  ip: string;
  loginUsername: string;
  logo: string;
  mapType: number;
  parentMenus: LmMenu[];
  realName: string;
  target: string;
  tel: string;
  token_type: string;
  token: string;
  isButtonAuth?: any;
}
export interface LmMenu {
  expandable: boolean;
  iconCls: string;
  id: number;
  isSystem: string;
  isValue: boolean;
  leaf: boolean;
  messageView: string;
  orderIndex: number;
  parentId: number;
  recNo: string;
  scope: string;
  text: string;
  textVisual: string;
  view: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private abilities: Array<number | string> = [];

  private aclChange = new BehaviorSubject<any>(null);
  /** ACL变更通知 */
  get change(): Observable<any> {
    return this.aclChange.asObservable();
  }

  getAbilitys() {
    return this.abilities;
  }

  setAbilitys(btns: any[]): void {
    if (Array.isArray(btns)) {
      for (const val of btns) {
        if (!this.abilities.some(a => a == val)) {
          this.abilities.push(val);
        }
      }
    }
    this.aclChange.next(btns);
  }

  can(ability: string): boolean {
    if (this.user.isButtonAuth) {
      return this.abilities.some(a => a == ability);
    }
    return true;
  }

  _stylet: boolean = false;
  set stylet(v: boolean) {
    this._stylet = v;
    if (this._stylet) {
      this.cacheSrv.set(this.STYLET_KEY, true);
    }
  }
  get stylet() {
    return this._stylet;
  }
  styletInv;

  fmtYMD: string = 'yyyy-MM-dd'; //时间格式 YMD

  private STYLET_KEY = 'stylet';
  private USER_KEY = 'loginUserInfo';
  private COM_KEY = 'comCode';
  private isButtonAuth_KEY = 'isButtonAuth';
  private LOGIN_KEY = 'login';
  private API_KEY = 'httpApiaddress';
  private REDIRECT_URL_KEY = 'redirect_uri';
  public REQUEST_KEY = {
    dashboard: 'dashboard',
    dashboard2: 'dashboard2',
    dashboard3: 'dashboard3',
    dashboard4: 'dashboard4',
    interface6205: 'interface6205',
    interface7203: 'interface7203',
    interface7206: 'interface7206',
    interface3000: 'interface3000',
    interface6103: 'interface6103',
    interface6218: 'interface6218',
    interface6601: 'interface6601',
    interface8087: 'interface8087',
    influx8087: 'influx8087',
    interface6637: 'interface6637',
    //新加
    interface6636: 'interface6636',
    interface7880: 'interface7880',
    interface28082: 'interface28082',
    interface28081: 'interface28081',
  };

  get user() {
    return this.cacheSrv.get(this.USER_KEY, { mode: 'none' }) || {};
  }

  set user(value: LmUser) {
    this.cacheSrv.set(this.USER_KEY, value);
  }

  get comCode() {
    return this.cacheSrv.get(this.COM_KEY, { mode: 'none' });
  }

  set comCode(value: LmUser) {
    this.cacheSrv.set(this.COM_KEY, value);
  }

  get isButtonAuth() {
    return this.cacheSrv.get(this.isButtonAuth_KEY, { mode: 'none' });
  }

  set isButtonAuth(value: LmUser) {
    this.cacheSrv.set(this.isButtonAuth_KEY, value);
  }

  get dashboard() {
    return this.cacheSrv.get(this.REQUEST_KEY.dashboard, { mode: 'none' });
  }

  get dashboard2() {
    return this.cacheSrv.get(this.REQUEST_KEY.dashboard2, { mode: 'none' });
  }
  get interface6205() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6205, { mode: 'none' });
  }
  get interface7203() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface7203, { mode: 'none' });
  }
  get interface7206() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface7206, { mode: 'none' });
  }
  get interface3000() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface3000, { mode: 'none' });
  }
  get interface6103() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6103, { mode: 'none' });
  }
  get interface6218() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6218, { mode: 'none' });
  }
  get interface6601() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6601, { mode: 'none' });
  }
  get interface8087() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface8087, { mode: 'none' });
  }
  get interface6637() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6637, { mode: 'none' });
  }
  get interface6636() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6636, { mode: 'none' });
  }
  get interface7880() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface7880, { mode: 'none' });
  }
  get interface28082() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface28082, { mode: 'none' });
  }
  get interface28081() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface28081, { mode: 'none' });
  }

  get isAuth() {
    return this.user.realName && this.user.token;
  }

  get redirectUrl() {
    return this.cacheSrv.get(this.REDIRECT_URL_KEY, { mode: 'none' });
  }
  /**
   * 菜单数组
   */
  private menuList: any[] = [];

  getMenus() {
    return new Promise((resolve, reject) => {
      if (this.isAuth) {
        const paramJson: Object = Object.assign({ functionType: 1 }); //functionType: 3 ==>functionType: 1 2021-04-23 小智update
        this.http
          .post(
            urlSerialize('pcGroupAuthority/getPcAuthorityTreeByGroupNoArrays', this.REQUEST_KEY.dashboard),
            paramJson,
          )
          .subscribe(x => {
            this.abilities = [];
            const menus = this.generateMenus(x, '/pages');

            this.aclChange.next([]);
            // const menus = this.generateMenus(x, '');

            this.menuList = this.treeToArr(deepCopy(menus));
            resolve(menus);
          });
      } else {
        resolve([]);
      }
    });
  }

  styletCheck() {
    if (this.styletInv) {
      clearInterval(this.styletInv);
    }
    setInterval(() => {
      if (this.stylet) {
        const result = this.cacheSrv.get(this.STYLET_KEY, { mode: 'none' });
        if (!result) {
          window.location.reload();
        }
      }
    }, 1000);
  }

  constructor(
    private cacheSrv: CacheService,
    private http: _HttpClient,
    private menuService: NbMenuService,
    private authService: NbAuthService,
    private modalService: NgbModal,
  ) {
    this.styletCheck();
  }

  private convertListToTree(data, options?: any) {
    options = options || {};
    const ID_KEY = options.idKey || 'id';
    const PARENT_KEY = options.parentKey || 'parent';
    const CHILDREN_KEY = options.childrenKey || 'children';

    const tree = [];
    const childrenOf = {};
    let item, id, parentId;

    for (let i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = toNumber(item[PARENT_KEY] || 0);
      //  every item may have children
      childrenOf[id] = childrenOf[id] || [];
      //  init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId !== 0) {
        //  init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        //  push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    return tree;
  }
  private generateMenus(data: any, pLink: string): NbMenuItem[] {
    const menu: NbMenuItem[] = [];
    data.forEach((d: any) => {
      const m: NbMenuItem = {
        title: d.label,
        link: `${pLink}/${d.routerLink}`,
        // link: d.routerLink,
        // icon: d.icon,
        hidden: false,
        group: false,
        expanded: d.expanded,
        url: d.externalLink,
        target: d.target,
        data: d,
      };
      if (Array.isArray(d.buttionList)) {
        this.abilities.push(...d.buttionList);
      }
      if (d.items && d.items.length > 0) {
        m.children = this.generateMenus(d.items, m.link);
      }
      menu.push(m);
    });
    // console.log('menu', menu)
    return menu;
  }

  private currentPath: string = '';
  private _currentPage: any = {};
  public get currentPage() {
    return this._currentPage;
  }
  public get currentPageData() {
    return this._currentPage['data'];
  }
  setCurrentPath(url: string) {
    this.currentPath = url;

    const p = this.menuList.find(x => x.link == this.currentPath);
    if (p) {
      this._currentPage = p;
    }
  }

  treeToArr<T extends object = any>(tree: readonly T[]): T[] {
    const opt = {
      deepMapName: 'deep',
      parentMapName: 'parent',
      childrenMapName: 'children',
      clearChildren: true,
      cb: null,
    };
    const result: Array<{ [key: string]: any }> = [];
    const inFn = (list: ReadonlyArray<{ [key: string]: any }>, parent: T | null, deep: number = 0) => {
      for (const i of list) {
        i[opt.deepMapName!] = deep;
        i[opt.parentMapName!] = parent;
        if (opt.cb) {
          opt.cb(i, parent, deep);
        }
        result.push(i);
        const children = i[opt.childrenMapName!];
        if (children != null && Array.isArray(children) && children.length > 0) {
          inFn(children, i as T, deep + 1);
        }
        if (opt.clearChildren) {
          delete i[opt.childrenMapName!];
        }
      }
    };
    inFn(tree, null);
    return result as T[];
  }

  loginContinue(data, apiAddress) {
    this.cacheSrv.set(this.LOGIN_KEY, data);
    this.cacheSrv.set(this.API_KEY, apiAddress);
  }

  /**
   * 随机4位16进制
   * @returns {string}
   * @constructor
   */
  private S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  /**
   * 生成guid
   * @returns {string}
   */
  guid() {
    return this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4();
  }

  /**
   * 日历汉化
   */
  getCalendarLocale() {
    return {
      firstDayOfWeek: 0,
      dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
      dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
      monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      monthNamesShort: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'],
      today: '今天',
      clear: '取消',
    };
  }

  /**
   * 判断是否非null、空
   * @param value
   * @returns {boolean}
   */
  public isNotEmpty(value): boolean {
    return !this.isEmpty(value);
  }

  /**
   * 获取批量保存标识
   * @param isMulti 是否可批量保存
   * @param rows 表格当前页数据
   * @returns {any}
   */
  isBatch(isMulti, rows) {
    let isWrite: boolean = false; //  行是否处在可编辑状态
    if (!isMulti) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i]['disabled'] == false) {
          isWrite = true;
          break;
        }
      }
    }
    return !isMulti && isWrite;
  }

  /**
   * 判断行是否处在可编辑状态
   * @param rows 表格当前页数据
   * @returns {boolean}
   */
  isRowEdit(rows) {
    let isEdit: boolean = false; // 行是否处在可编辑状态
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]['disabled'] == false) {
        isEdit = true;
        break;
      }
    }
    return isEdit;
  }

  /**
   * 关闭行编辑
   * @param rows 表格当前页数据
   */
  closeRowEdit(rows) {
    for (let i = 0; i < rows.length; i++) {
      rows[i]['disabled'] = true;
    }
  }
  /**
   * HttpPara 参数
   */
  getparam() {
    const s = this.cacheSrv.get(this.LOGIN_KEY, { mode: 'none' });
    if (this.isNotEmpty(s)) {
      return s.s_req;
    } else {
      return '';
    }
  }

  /**
   *
   * @param address
   */
  getApiaddress(address: any) {
    const _arr: any[] = this.cacheSrv.get(this.API_KEY, { mode: 'none' }) || [];
    const tg = _arr.find(x => Object.keys(x)[0] === address);
    if (tg) {
      return tg[address] + '/';
    } else {
      return '/';
    }
  }

  /**
   * 封装参数
   * @param passdata
   */
  getparamJson(passdata) {
    const req = this.getparam();
    const paramJson: any = Object.assign({ passdata: passdata }, req);
    // console.log('paramJson',paramJson)
    return paramJson;
  }

  isEmpty(value) {
    return (
      value == null ||
      (typeof value === 'string' && (value.length === 0 || value === undefined || value === 'undefined'))
    );
  }

  /**
   * ajax post提交
   * @param url
   * @param data
   * @param headers
   * @param doSuccess
   * @param doFail
   */
  ajaxPost(url, data: Object = {}, headers: Object = {}, doSuccess?: Function, doFail?: Function) {
    $.ajax(url, {
      type: 'post',
      data: data,
      headers: headers,
      success: result => {
        if (doSuccess != null) {
          doSuccess(result);
        }
      },
      failure: result => {
        if (doFail != null) {
          doFail(result);
        }
      },
    });
  }

  /**
   * 获取influxDb转图表数据
   * @param result
   */
  getEChartsDataByInfluxDb2(result, callback?: Function) {
    let echartsData: Object[] = [];
    const results: Object[] = JSON.parse(result['data'] || '{}')['results'];
    if (results != null && results.length > 0) {
      const series: Object[] = results[0]['series'];
      if (series != null && series.length > 0) {
        const values: Object[] = series[0]['values'];
        values.forEach(item => {
          echartsData = [...echartsData, { value: item }];
        });
      }
    }
    if (callback != null) {
      callback(echartsData);
    }
  }

  /**
   * 请求结果处理，返回data
   * @param result 结果
   * @param callback 回调
   * @param paramJson参数配置
   */
  resultToData(result, callback?: Function, paramJson: Object = {}) {
    paramJson = Object.assign({ showSuccessMsg: false }, paramJson);
    if (this.isJson(result)) {
      if (callback != null) {
        callback(result);
      }
    }
  }
  /**
   * 判断是否JSON对象
   * @param value
   */
  public isJson(value): boolean {
    return (
      typeof value == 'object' &&
      Object.prototype.toString.call(value).toLowerCase() == '[object object]' &&
      !value.length
    );
  }

  /**
   * 格式化日期
   * @param date 日期
   * @param fmt 格式
   * @returns {string}
   */
  format(date: Date, fmt: string): string {
    const o = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (const k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    return fmt;
  }

  /**
   * 获取当前日期
   * @returns {string}
   */
  public getCurrentDay(): string {
    return this.format(new Date(), this.fmtYMD);
  }

  /**
   * 格式化日期
   * @param date 日期
   * @param fmt 格式
   * @returns {string}
   */
  formatago(date: Date, fmt: string): string {
    const o = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate() - 30, //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (const k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    return fmt;
  }

  /**
   * 获取当前日期
   * @returns {string}
   */
  public getCurrentDayago(): string {
    return this.formatago(new Date(), this.fmtYMD);
  }

  /**
   * 获取下一个月
   *
   * @date 格式为yyyy-mm-dd的日期
   */
  public getNextMonth(date) {
    const arr = date.split('-');
    const year = arr[0]; //获取当前日期的年份
    const month = arr[1]; //获取当前日期的月份
    const day = arr[2]; //获取当前日期的日
    const days = new Date(year, month, 0);
    // days = days.getDate(); //获取当前日期中的月的天数
    // var year2 = year;
    // var month2 = parseInt(month) + 1;
    // if (month2 == 13) {
    //     year2 = parseInt(year2) + 1;
    //     month2 = 1;
    // }
    // var day2 = day;
    // var days2 = new Date(year2, month2, 0);
    // days2 = days2.getDate();
    // if (day2 > days2) {
    //     day2 = days2;
    // }
    // if (month2 < 10) {
    //     month2 = '0' + month2;
    // }

    // var t2 = year2 + '-' + month2 + '-' + day2;
    // return t2;
  }

  /**
   * 拍照、选择照片选择base64数组
   * @param arrBase64 base64数组
   * @param event 待操作的数组或索引
   */
  getArrBase64(arrBase64, base64OrIndex) {
    if (isNumber(base64OrIndex)) {
      //整形为索引时删除图片
      arrBase64 = [...arrBase64.slice(0, base64OrIndex), ...arrBase64.slice(base64OrIndex + 1)];
    } else {
      arrBase64 = [...arrBase64, base64OrIndex];
    }
    return arrBase64;
  }

  /**
   * 获取influxDb转图表数据
   * @param result
   */
  getEChartsDataByInfluxDb(result) {
    let echartsData: Object[] = [];
    const results: Object[] = JSON.parse(result['data'] || '{}')['results'];
    if (results != null && results.length > 0) {
      const series: Object[] = results[0]['series'];
      if (series != null && series.length > 0) {
        const values: Object[] = series[0]['values'];
        values.forEach(item => {
          echartsData = [...echartsData, { value: item }];
        });
      }
    }
    return echartsData;
  }

  /**
   * 设置token到cookie
   */
  setTokenToCookie() {
    const exp = new Date();
    exp.setTime(exp.getTime() + 30 * 1000); //默认30S
    const token: string = sessionStorage.getItem('token') || '';
    document.cookie = 'token=' + token + ';expires=' + exp.toUTCString();
  }

  /**
   * 清除缓存对象
   * @param key
   */
  clearSessionStorage(key: string = null) {
    if (key != null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.clear();
    }
  }

  /**
   * 移除cookie中的token
   */
  deleteTokenByCookie() {
    this.delCookie('token');
  }
  /**
   * 删除cookies
   * @param name
   */
  delCookie(name) {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = this.getCookie(name);
    if (cval != null) document.cookie = name + '=' + cval + ';expires=' + exp.toUTCString();
  }

  /**
   * 读取cookies
   * @param name
   */
  getCookie(name) {
    let arr,
      reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if ((arr = document.cookie.match(reg))) {
      return unescape(arr[2]);
    } else {
      return null;
    }
  }
}
