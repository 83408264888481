import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbToastrModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
  NbWindowModule,
} from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NotificationService } from 'app/logic/services/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CalendarModule, FileUploadModule, CarouselModule } from 'primeng/primeng';
import { ThemeModule } from '../@theme/theme.module';
import { DrawMapModule, SHARED_COMPONENTS, SHARED_ENTITY } from './components';
import { F1CalendarModule } from './components/f1-calendar/f1-calendar.module';
import { MapShowModule } from './components/map-show';
import { MapToolModule } from './components/map-tool';
import { SHARED_DIRECTIVES } from './directives';
import { F1DirectiveModule } from './directives/directive.module';
import { HttpService } from './http.service';
import { SHARED_PIPES } from './pipes';
import { TimeRangeModule } from './components/time-range/time-range.module';
import { DrawGisModuleModule } from './components/draw-gis-map/draw-gis-module.module';

const THIRDMODULES = [
  FileUploadModule,
  CarouselModule,
  CalendarModule,
  NgxDatatableModule,
  MapShowModule,
  MapToolModule,
  DrawMapModule,
  DrawGisModuleModule,
  NgZorroAntdModule,
  F1CalendarModule,
  F1DirectiveModule,
  TimeRangeModule,
];

const COMPONENTS_ENTRY = [...SHARED_ENTITY];
const COMPONENTS = [...COMPONENTS_ENTRY, ...SHARED_COMPONENTS];
const DIRECTIVES = [...SHARED_DIRECTIVES];
const PIPES = [...SHARED_PIPES];
// #endregion
const NB_MODULES = [
  //#region nebular
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarKitModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbToastrModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
  NbWindowModule,
  AgmCoreModule,
  //#endregion
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ThemeModule,
    ReactiveFormsModule,
    ...NB_MODULES,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  entryComponents: COMPONENTS_ENTRY,
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ThemeModule,
    ...NB_MODULES,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  providers: [NotificationService, { provide: 'httpService', useClass: HttpService }],
})
export class SharedModule {}
