import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

import { TimeRangeComponent } from './time-range.component';

@NgModule({
  declarations: [TimeRangeComponent],
  imports: [CommonModule, FormsModule, NzTimePickerModule, NzIconModule, NzTagModule],
  exports: [TimeRangeComponent]
})
export class TimeRangeModule {}
