/**
 * 数组分块
 */
export const chunkArray = (arr: any[], size: number) => {
  const objArr = [];
  let index = 0;
  const objArrLen = arr.length / size;
  for (let i = 0; i < objArrLen; i++) {
    const arrTemp = [];
    for (let j = 0; j < size; j++) {
      arrTemp[j] = arr[index++];
      if (index === arr.length) {
        break;
      }
    }
    objArr[i] = arrTemp;
  }
  return objArr;
};
/**
 * 数组去重
 */
export const uniq = (array: any[], prop: string = '') => {
  const temp = [];
  const index = [];
  const l = array.length;
  for (let i = 0; i < l; i++) {
    for (let j = i + 1; j < l; j++) {
      if (prop) {
        if (array[i][prop] === array[j][prop]) {
          i++;
          j = i;
        }
      } else {
        if (array[i] === array[j]) {
          i++;
          j = i;
        }
      }
    }
    temp.push(array[i]);
    index.push(i);
  }
  return temp;
};

//分组
export const groupBy = (list: any[], fn: (item: any) => {}):any => {
  const groups: any = {};
  list.forEach(function (o) {
    const group = JSON.stringify(fn(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return groups;
};

