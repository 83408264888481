import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@shared';
declare const QRious: any;

export interface QRConfig {
  /**
   * [qrious](https://github.com/neocotic/qrious) 外网地址，默认：`https://cdn.bootcdn.net/ajax/libs/qrious/4.0.2/qrious.min.js`
   *
   * 若在 `angular.json` 配置 `"scripts": [ "node_modules/qrious/dist/qrious.min.js" ]` 则优先使用
   */
  lib?: string;
  /** 背景，默认：`white` */
  background?: string;
  /** 背景透明级别，范围：`0-1` 之间，默认：`1` */
  backgroundAlpha?: number;
  /** 前景，默认：`black` */
  foreground?: string;
  /** 前景透明级别，范围：`0-1` 之间，默认：`1` */
  foregroundAlpha?: number;
  /** 误差校正级别，默认：`L` */
  level?: 'L' | 'M' | 'Q' | 'H';
  /** 二维码输出图片MIME类型，默认：`image/png` */
  mime?: string;
  /** 内边距（单位：px），默认：`10` */
  padding?: number;
  /** 大小（单位：px），默认：`220` */
  size?: number;
  delay?: number;
}

export interface QROptions extends QRConfig {
  value: string;
}

@Component({
  selector: 'f1-qr',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  host: {
    '[style.display]': `'inline-block'`,
    '[style.height.px]': 'size',
    '[style.width.px]': 'size'
  },
})
export class QrCodeComponent implements OnChanges, AfterViewInit {
  private qr: any;
  private option: QROptions;
  private inited = false;
  dataURL: string;

  @Input() background: string = 'white';
  @Input() backgroundAlpha: number = 1;
  @Input() foreground: string = 'black';
  @Input() foregroundAlpha: number = 1;
  @Input() level: string = 'L';
  @Input() mime: string = 'image/png';
  @Input() padding: number = 10;
  @Input() size: number = 195;
  @Input() value = '';
  @Input() delay: number = 0;
  @Output() readonly change = new EventEmitter<string>();


  constructor(private cdr: ChangeDetectorRef,
    private platform: Platform) { }

  private init(): void {
    if (!this.inited) {
      return;
    }

    if (this.qr == null) {
      this.qr = new (window as any).QRious();
    }
    this.qr.set(this.option);
    this.dataURL = this.qr.toDataURL();
    this.change.emit(this.dataURL);
    this.cdr.detectChanges();
  }


  private initDelay(): void {
    this.inited = true;
    setTimeout(() => this.init(), this.delay);
  }

  ngAfterViewInit(): void {
    if (!this.platform.isBrowser) {
      return;
    }
    if ((window as any).QRious) {
      this.initDelay();
      return;
    }
  }

  ngOnChanges(): void {
    const option: QROptions = {
      background: this.background,
      backgroundAlpha: this.backgroundAlpha,
      foreground: this.foreground,
      foregroundAlpha: this.foregroundAlpha,
      level: this.level as any,
      mime: this.mime,
      padding: this.padding,
      size: this.size,
      value: this.toUtf8ByteArray(this.value)
    };
    this.option = option;
    this.init();
  }

  private toUtf8ByteArray(str: string): string {
    str = encodeURI(str);
    const result: number[] = [];
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) !== '%') {
        result.push(str.charCodeAt(i));
      } else {
        result.push(parseInt(str.substr(i + 1, 2), 16));
        i += 2;
      }
    }
    return result.map(v => String.fromCharCode(v)).join('');
  }

}
