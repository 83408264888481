import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { _HttpClient, NotificationService } from '@service/common';
import { urlSerialize } from 'app/shared/utils/url';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {

  private url_four: any;
  modalData: Object = {}; //弹框数据
  modalHeader: string = ''; //modal 标题
  formData: Object = {}; //表单数据
  statusData: Object[] = []; //状态数据
  currentStatus: string = ''; //当前状态
  landData: Object = {};

  constructor(private http: _HttpClient, private notifySrv: NotificationService, private activeModal: NgbActiveModal) { }

  ngOnInit() {
    // this.url_four = this.utilsService.getApiaddress('Base_One') + '/';
  }

  /**
   * 提交询问对话框
   */
  submitComfirm() {
    this.notifySrv.confirm(() => {
      this.onSubmit();
    }, { msgIndex: 3 });
  }

  /**
   * 数据提交
   */
  onSubmit() {
    // let url = this.url_four + 'pcUser/updatePasswordFromPcUserFromPc';
    const paramJson: Object = Object.assign({ 'loginUsername': this.modalData, functionType: 1 }, this.formData); //2020-08-13

    this.http.post(urlSerialize('pcUser/updatePasswordFromPcUserFromPc', 'Base_One'), paramJson).subscribe(result => {
      const message_ = result['message'];
      if (result['result'] == 1) {
        this.notifySrv.tooltip({ message: message_ });
        this.dismissModal();
      } else {
        this.notifySrv.tooltip({ message: message_ });
      }
    });
  }

  /**
   * 取消 关闭窗口
   */
  closeModal() {
    this.activeModal.close();
  }

  /**
   * 确定 关闭窗口
   */
  dismissModal() {
    // for (let i = 0; i < this.statusData.length; i++) {
    //   if (this.statusData[i]['valueValue'] == this.formData['status']) {
    //     this.formData['statusName'] = this.statusData[i]['valueKey'];
    //     break;
    //   }
    // }
    this.activeModal.dismiss(this.formData);
  }
}

