import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConfig } from './config';
import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from './app.service';
import { ComfirmComponent } from 'app/shared/components/comfirm/comfirm';
import { TooltipComponent } from 'app/shared/components/tooltip/tooltip';
export enum MsgType {
  NaN = 0,
  Success = 2,
  Error = 3,
  Warning = 4,
  Info = 5,
}
export interface LmMessage {
  Msg: string;
  MsgType: MsgType;
  Duration?: number;
  retry?: Function;
  refresh?: Function;
}
@Injectable({ providedIn: 'root' })
@Injectable()
export class NotificationService {
  constructor(private notificationServ: NbToastrService, private appSrv: AppService, private modalService: NgbModal) {}
  msg(m: LmMessage): void {
    m.Duration = m.Duration || GlobalConfig.Duration;
    switch (m.MsgType) {
      case MsgType.Error:
        this.notificationServ.default(m.Msg, '执行失败!', {
          duration: m.Duration,
        });
        break;

      case MsgType.Success:
        this.notificationServ.success(m.Msg, '执行成功!', {
          duration: m.Duration,
        });
        break;
      case MsgType.Warning:
        this.notificationServ.warning(m.Msg, '警告提示!', {
          duration: m.Duration,
        });
        break;
      case MsgType.Info:
        this.notificationServ.info(m.Msg, '温馨提示!', {
          duration: m.Duration,
        });
        break;
    }
  }
  success(m: string = '') {
    this.msg({
      Msg: m,
      MsgType: MsgType.Success,
    });
  }
  error(m: string = '') {
    this.msg({
      Msg: m,
      MsgType: MsgType.Error,
    });
  }
  info(m: string = '') {
    this.msg({
      Msg: m,
      MsgType: MsgType.Info,
    });
  }
  warning(m: string = '') {
    this.msg({
      Msg: m,
      MsgType: MsgType.Warning,
    });
  }

  /**
   * 提示框
   * @param param.modalContent 提示框内容（优先级高）
   * @param param.msgIndex 提示框内容索引
   */
  tooltip(param: any = {}) {
    const activeModal = this.modalService.open(TooltipComponent, {
      size: 'sm',
      container: 'nb-layout',
      backdrop: 'static',
      keyboard: false,
    });
    if (this.appSrv.isNotEmpty(param['message'])) {
      activeModal.componentInstance['modalContent'] = param['message'];
    }

    if (this.appSrv.isNotEmpty(param['msgIndex'])) {
      activeModal.componentInstance['msgIndex'] = param['msgIndex'];
    }
  }

  /**
   * 询问对话框
   * @param dismiss 点击确定回调
   * @param param.modalContent 询问对话框内容（优先级高）
   * @param param.msgIndex 询问对话框内容索引（默认2）
   */
  confirm(dismiss, param: any = {}) {
    const activeModal = this.modalService.open(ComfirmComponent, {
      size: 'sm',
      container: 'nb-layout',
      backdrop: 'static',
      keyboard: false,
    });
    if (this.appSrv.isNotEmpty(param['message'])) {
      activeModal.componentInstance['modalContent'] = param['message'];
    }

    if (this.appSrv.isNotEmpty(param['msgIndex'])) {
      activeModal.componentInstance['msgIndex'] = param['msgIndex'];
    }

    activeModal.result.then(
      () => {
        // 关闭
      },
      () => {
        // 确定
        dismiss();
      },
    );
  }

  /**
   * 弹框
   * @param dismiss 点击确定回调
   * @param param.component 弹框面板
   * @param param.size 弹框大小，默认为lg
   * @param param.modalData 弹框参数 默认为{}
   */
  openModal(dismiss, param: any = {}) {
    const activeModal = this.modalService.open(param['component'], {
      size: param['size'],
      //       size: param['size'] || 'lg',
      container: 'nb-layout',
      backdrop: 'static',
      keyboard: false,
    });
    activeModal.componentInstance.modalData = param['modalData'] || {}; // 传参
    activeModal.componentInstance.selectedList = param['selectedList'] || {}; // 传参已选
    activeModal.result.then(
      () => {
        // 关闭
      },
      dismissData => {
        // 确定
        dismiss(dismissData);
      },
    );
  }
}
