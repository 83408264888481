/**
 * 公共组件Module
 */
import { NgModule } from '@angular/core';
import { HeaderModule } from './header/header.module';

@NgModule({
  imports: [
    HeaderModule
  ],
  declarations: [],
  exports: [
    HeaderModule
  ]
})
export class ComponentsModule { }
