import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NotificationService } from '@service/common';
import { urlSerialize, fine1Url } from '@shared/utils/url';
import { asyncForEach } from '@shared/utils/util';

import { F1CanvasComponent } from './f1-canvas/f1-canvas.component';


@Component({
  selector: 'f1-editor',
  templateUrl: './f1-editor.component.html',
  styleUrls: ['./f1-editor.component.scss']
})

export class F1EditorComponent implements AfterViewInit {
  @ViewChild('fcanvas', { static: true }) canvas: F1CanvasComponent;
  /**
   * 选择的泵站
   */
  selectedBz?: any;
  /**
   * 选择的设备
   */
  selectedSb?: any;
  /**
   * 分组列表
   */
  groups: any[] = [];
  /**
   * 设备items
   */
  sbItems: any[] = [];

  isDraw = false;

  sucai = [
    {
      url: './assets/f1-editor/gx-01.svg'
    }, {
      url: './assets/f1-editor/gx-02.svg'
    }, {
      url: './assets/f1-editor/gx-03.svg'
    }, {
      url: './assets/f1-editor/gx-04.svg'
    }, {
      url: './assets/f1-editor/gx-05.svg'
    }, {
      url: './assets/f1-editor/gx-06.svg'
    }, {
      url: './assets/f1-editor/gx-07.svg'
    }, {
      url: './assets/f1-editor/gx-08.svg'
    }, {
      url: './assets/f1-editor/gx-09.svg'
    }, {
      url: './assets/f1-editor/gx-10.svg'
    }, {
      url: './assets/f1-editor/gx-11.svg'
    }, {
      url: './assets/f1-editor/ys-01.svg'
    }, {
      url: './assets/f1-editor/ys-02.svg'
    }, {
      url: './assets/f1-editor/ys-03.svg'
    }, {
      url: './assets/f1-editor/ys-04.svg'
    }, {
      url: './assets/f1-editor/ys-05.svg'
    }, {
      url: './assets/f1-editor/ys-06.svg'
    }, {
      url: './assets/f1-editor/ys-07.svg'
    }, {
      url: './assets/f1-editor/ys-08.svg'
    }, {
      url: './assets/f1-editor/ys-09.svg'
    }, {
      url: './assets/f1-editor/ys-10.svg'
    }, {
      url: './assets/f1-editor/ys-11.svg'
    }, {
      url: './assets/f1-editor/ys-12.svg'
    },
    { url: './assets/f1-editor/zsf-01.svg' },
    { url: './assets/f1-editor/zsf-02.svg' },
    { url: './assets/f1-editor/zsf-03.svg' },
    { url: './assets/f1-editor/zsf-04.svg' },
    { url: './assets/f1-editor/zsf-05.svg' },
  ]

  nzBodyStyle = {
    'padding': '8px'
  }


  constructor(private http: HttpClient, private notifySrv: NotificationService) { }

  ngAfterViewInit(): void {

    this.getBengfangData();

  }

  selectedChange($event: any) {
    const s = this.sbItems.find(x => x.valveNo == $event.recNo);
    if (s) {
      this.selectedSb = s;
    } else {
      this.selectedSb = null;
    }
  }

  bzChange($event: any) {

    if (this.selectedBz.jsonMsg) {
      this.canvas.loadFromJSON(this.selectedBz.jsonMsg);
    }
    this.getGroupDetails(this.selectedBz.recNo);
  }


  sbChange($event: any) {
    if (this.canvas.selected && this.canvas.selected.type != 'group') {

      if (this.selectedSb) {
        this.canvas.selected.recNo = this.selectedSb.valveNo;
        this.canvas.setRecNo(this.selectedSb.valveNo)
      } else {
        this.canvas.selected.recNo = '';
        // this.canvas.setRecNo('')
      }
    }

    // if(this.canvas.selected && this.canvas.selected.type != 'group'){
    //   if (this.selectedSb) {
    //     this.canvas.props.recNo = this.selectedSb.valveNo;
    //   } else {
    //     this.canvas.props.recNo = '';
    //   }
    // }


  }

  /**
   * 获取泵房数据
   */
  async getBengfangData() {

    let res: any = await this.http
      .post(urlSerialize('HarewareslineGroup/Page', 'Base_6806'), {
        hardwareDcpType: 14,
      })
      .toPromise();

    if (Array.isArray(res.dataList)) {
      this.groups = res.dataList;

      if (this.groups.length > 0) {
        this.selectedBz = this.groups[0];
        this.bzChange(this.selectedBz);
      } else {
        this.selectedBz = null;
      }
      this.selectedSb = null;
    }

  }

  async getGroupDetails(groupNo: string) {
    if (groupNo) {
      const res: any = await this.http
        .post(urlSerialize('HarewareslineGroupDetail/Page', 'Base_6806'), {
          groupNo,
        })
        .toPromise();
      if (Array.isArray(res.dataList)) {
        this.sbItems = res.dataList;

      }
    } else {
      this.sbItems = [];
    }

  }

  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    this.canvas.rasterizeSVG();
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  public addText() {
    this.canvas.addText();
  }

  public getImgPolaroid($event: any, url: string) {
    this.canvas.getImgPolaroid($event, url);
  }

  public addImageOnCanvas(url) {
    this.canvas.addImageOnCanvas(url);
  }

  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    if (!this.canvas.selected) {
      return;
    }
    this.canvas.removeSelected();
  }

  public sendToBack() {
    if (!this.canvas.selected) {
      return;
    }
    this.canvas.sendToBack();
  }

  public bringToFront() {
    if (!this.canvas.selected) {
      return;
    }
    this.canvas.bringToFront();
  }

  public clone() {
    if (!this.canvas.selected) {
      return;
    }
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage() {
    this.canvas.setCanvasImage();
  }

  public setRecNo() {
    this.canvas.setRecNo();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    this.canvas.setBold();
  }

  public setFontStyle() {
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }



  public drawMode() {
    this.canvas.drawingMode();
  }

  save() {
    this.rasterizeJSON();
    if (this.selectedBz) {


      this.http.post(urlSerialize('HarewareslineGroup/update', 'Base_6806'), {
        ...this.selectedBz,
        jsonMsg: this.canvas.json
      }).subscribe(result => {
        if (result['result'] == 1) {
          this.notifySrv.success();
        }
      });
    }
  }

}
