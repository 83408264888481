import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-component-comfirm',
  templateUrl: './comfirm.html',
  styleUrls: ['./comfirm.css'],
})
export class ComfirmComponent implements OnInit {

  modalHeader: string = '温馨提示'; //提示框标题
  modalContent: string = ''; //提示框内容
  createMsg: string = '您是否要新增该记录吗?'; //索引0
  updateMsg: string = '您是否要修改该记录吗?'; //索引1
  deleteMsg: string = '您是否要删除该记录吗?'; //索引2
  submitMsg: string = '您是否要提交该记录吗?'; //索引3
  multiMsg: string = '您是否要批量提交吗?'; //索引4
  createRootMsg: string = '您确定要增加根节点吗?'; //索引5
  auditMsg: string = '您确定要审核该记录吗?'; //索引6
  createQrMsg: string = '您确定要生成二维码吗?'; //索引7
  msgIndex: number = 2; //信息索引 默认为2

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initData();
  }

  /**
   * 初始化数据
   */
  initData() {
    if (this.modalContent == '') {
      const arrTooltipMsg: string[] = [this.createMsg, this.updateMsg, this.deleteMsg, this.submitMsg, this.multiMsg, this.createRootMsg, this.auditMsg, this.createQrMsg];
      this.modalContent = arrTooltipMsg[this.msgIndex];
    }
  }

  /**
   * 取消 关闭窗口
   */
  closeModal() {
    this.activeModal.close();

  }

  /**
   * 确定 关闭窗口
   */
  dismissModal() {
    this.activeModal.dismiss();
  }
}
