import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, forwardRef, Attribute } from '@angular/core';
import { isNullOrUndefined } from 'util';

/**
 * 表单校验指令
 * @param c
 * @returns {{errObj: {valid: boolean, errMsg: string}}}
 */
@Directive({
  selector: '[validateType][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidatorDirective), multi: true },
  ],
})
export class ValidatorDirective implements Validator {
  constructor(@Attribute('validateType') public validateType: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    let value = c.value;
    if (isNullOrUndefined(value)) {
      value = '';
    }

    if (this.validateType == 'required') {//非空校验
      value = value + '';
      return value.replace(/(^\s*)|(\s*$)/g, '') != '' ? null : { valid: false, errMsg: '不能为空!' };
    } else if (this.validateType == 'mobile') {//手机号非空校验
      const mobileRegexp = /^((0\d{2,3}-\d{7,8})|1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$)/;
      return mobileRegexp.test(value) ? null : { valid: false, errMsg: '手机/电话号码不能为空或格式有误!' };
    } else if (this.validateType == 'mobileE') {//手机号校验
      if (value != '') {
        const mobileRegexp = /^((0\d{2,3}-\d{7,8})|1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$)/;
        return mobileRegexp.test(value) ? null : { valid: false, errMsg: '手机/电话号码格式有误!' };
      }
    } else if (this.validateType == 'ridE') {//rid验证
      if (value != '') {
        const ridRegexp = /^[1-9]$/;
        return ridRegexp.test(value) ? null : { valid: false, errMsg: '请输入1-9的数字!' };
      }
    } else if (this.validateType == 'intNum') {//rid验证
      if (value != '') {
        // let ridRegexp = /^\d{0,}$/;
        const ridRegexp = /^\d{1}$/;
        return ridRegexp.test(value) ? null : { valid: false, errMsg: '请输入0-9的数字!' };
      }
    } else if (this.validateType == 'rid0') {//rid验证
      if (value != '') {
        // let ridRegexp = /^[0-9]$/;
        const ridRegexp = /^(0|[1-9][0-9]*)$/;
        return ridRegexp.test(value) ? null : { valid: false, errMsg: '请输入数字!' };
      }
    } else if (this.validateType == 'ride') {//rid验证
      if (value != '') {
        const ridRegexp = /^[1-9]\d*$/gi;
        return ridRegexp.test(value) ? null : { valid: false, errMsg: '只能为数字且首位不能为0!' };
      }
    }
    else if (this.validateType == 'email') {//邮箱非空校验
      const emailRegexp = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
      return emailRegexp.test(value) ? null : { valid: false, errMsg: '邮箱格式有误!' };
    } else if (this.validateType == 'emailE') {//邮箱校验
      if (value != '') {
        const emailRegexp = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
        return emailRegexp.test(value) ? null : { valid: false, errMsg: '邮箱格式有误!' };
      }
    } else if (this.validateType == 'identity') {//验证身份证
      const identityRegx = /^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/;
      return identityRegx.test(value) ? null : { valid: false, errMsg: '身份证格式有误!' };
    } else if (this.validateType == 'identityE') {//验证身份证
      if (value != '') {
        const identityRegx = /^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/;
        return identityRegx.test(value) ? null : { valid: false, errMsg: '身份证格式有误!' };
      }
    } else if (this.validateType == 'nonnegativeInteger') {//非负整数
      const nonnegativeIntegerRegx = /^([1-9]\d*|[0]{1,1})$/;
      return nonnegativeIntegerRegx.test(value) ? null : { valid: false, errMsg: '请输入正整数!' };
    } else if (this.validateType == 'nonnegativeIntegerE') {//非负整数
      if (value != '') {
        const nonnegativeIntegerRegx = /^([1-9]\d*|[0]{1,1})$/;
        return nonnegativeIntegerRegx.test(value) ? null : { valid: false, errMsg: '请输入非负整数!' };
      }
    } else if (this.validateType == 'Int') {//数字类型
      if (value != '') {
        const nonnegativeIntegerRegx = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/;
        return nonnegativeIntegerRegx.test(value) ? null : { valid: false, errMsg: '请输入数字类型数据!' };
      }
    } else if (this.validateType == 'ipv4') {//IPv4
      const ipRegx = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}$/;
      return ipRegx.test(value) ? null : { valid: false, errMsg: 'IP地址格式有误!' };
    } else if (this.validateType == 'positive') {//正数
      const positiveRegx = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      return positiveRegx.test(value) ? null : { valid: false, errMsg: '请输入正数!' };
    } else if (this.validateType == 'positiveE') {//正数
      if (value != '') {
        const positiveRegx = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
        return positiveRegx.test(value) ? null : { valid: false, errMsg: '请输入正数!' };
      }
    } else if (this.validateType == 'positiveD') {//0-12的正整数
      if (value != '') {
        const positiveRegx = /^(?:1[0-2]|[1-9])$/;
        return positiveRegx.test(value) ? null : { valid: false, errMsg: '请输入0-12的正整数!' };
      }
    } else if (this.validateType == 'time') {//时间格式
      if (value != '') {
        const timeRegx = /^(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
        return timeRegx.test(value) ? null : { valid: false, errMsg: '请输入带时分秒的正确时间格式!' };
      }
    } else if (this.validateType == 'dateTime') {//时间格式(年月)
      if (value != '') {
        // let dateTimeRegx = /^(d{4})-(d{2})$/;
        const dateTimeRegx = /^\d{4}-((0([1-9]))|(1(0|1|2)))$/;
        return dateTimeRegx.test(value) ? null : { valid: false, errMsg: '请输入正确的年月格式!' };
      }
    } else if (this.validateType == 'floatData') {//包含0的小数类型
      if (value != '') {
        const floatDataRegx = /^(0(\.\d{1,4})?)$/;
        return floatDataRegx.test(value) ? null : { valid: false, errMsg: '请输入0-1的小数类型(可包含0)!' };
      }
    } else if (this.validateType == 'conversionRatioData') {//1*100
      if (value != '') {
        const conversionRatioDataRegx = /^[1-9]\d*\*[1-9]\d*$/;
        return conversionRatioDataRegx.test(value) ? null : { valid: false, errMsg: '请输入a*b的类型，例：1:*100' };
      }
    } else if (this.validateType == 'yearTime') {//时间格式(年月日)
      if (value != '') {
        const yearRegx = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        return yearRegx.test(value) ? null : { valid: false, errMsg: '请输入正确的年月日格式!' };
      }
    }
    return null;
  }
}
