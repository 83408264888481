import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { urlSerialize } from '../../../utils/url';
import { F1Echart } from '../../../utils/f1-echart';
import { getCenter } from '../../../utils/util';
import { uniq } from '../../../utils/array';
import { BaseSetting } from '../../../utils/base';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'f1-map-device-preview',
  templateUrl: './map-device-preview.component.html',
  styleUrls: ['./map-device-preview.component.scss'],
})
export class MapDevicePreviewComponent implements OnInit, OnDestroy, AfterViewInit {
  visible = false;

  f1Echart = new F1Echart();

  landData: any[] = [];
  deviceData: any[] = [];
  rsData: any[] = []; // 关系数据

  lineData: any[] = [
    // [
    //   { lat: 43.8382, lng: 89.54544 },
    //   { lat: 43.83731, lng: 89.53975 },
    //   { lat: 43.83666, lng: 89.53675 },
    //   { lat: 43.83321, lng: 89.53649 },
    //   { lat: 43.83374, lng: 89.53933 },
    //   { lat: 43.83485, lng: 89.54076 },
    // ],
  ];

  mapPoints = {
    name: 'scatter3D', // scatter3D 无法选中。。。
    type: 'scatter3D',
    coordinateSystem: 'mapbox3D',
    shading: 'lambert',
    silent: false,
    symbol: 'pin',
    symbolSize: 20,
    zlevel: 11,
    label: {
      show: true,
      formatter: '{b}',
      textStyle: {
        color: '#fff',
        backgroundColor: '#032042',
        padding: 5,
      },
    },

    itemStyle: {
      color: '#F4E925', // 图形的颜色
    },
    emphasis: {
      label: {
        show: true,
      },
      itemStyle: {
        color: '#0265B6',
        borderWidth: 6.4,
        borderColor: '#3298DC', //省市边界线
      },
    },
    data: [
      // {
      //   name: '水泵房',
      //   value: [89.53975, 43.83762, 40],
      // },
      // {
      //   name: '阀门1',
      //   value: [89.53338, 43.83799, 40],
      // },
      // {
      //   name: '灌溉点1',
      //   value: [89.52993, 43.83699, 40],
      // },
      // {
      //   name: '灌溉点2',
      //   value: [89.52705, 43.83595, 40],
      // },
      // {
      //   name: '灌溉点3',
      //   value: [89.52735, 43.83549, 40],
      // },
      // {
      //   name: '灌溉点4',
      //   value: [89.53267, 43.83558, 40],
      // },
    ],
  };

  mapBaseLine = {
    type: 'lines3D',
    coordinateSystem: 'mapbox3D',
    z: 2,
    zlevel: 11,
    animation: true,
    effect: {
      show: true,
      period: 5,
      trailLength: 0.71,
      symbolSize: 14,
      symbol: 'circle',
      loop: true,
      color: 'yellow',
    },
    lineStyle: { normal: { color: 'green', width: 0, curveness: 0 } },
    data: [],
  };

  mapDrawLine = {
    type: 'lines3D',
    coordinateSystem: 'mapbox3D',
    z: 1,
    zlevel: 11,
    animation: true,
    effect: {
      show: true,
      period: 5,
      trailLength: 0.71,
      symbolSize: 5,
      symbol: 'circle',
      loop: true,
      trailColor: 'yellow',
    },
    lineStyle: { normal: { width: 1, curveness: 0 } },
    polyline: true,
    data: [
      // [
      //   [89.53975, 43.83762, 20],
      //   [89.53338, 43.83799, 20],
      //   [89.52993, 43.83699, 20],
      //   [89.52705, 43.83595, 20],
      //   [89.52735, 43.83549, 20],
      //   [89.53267, 43.83558, 20],
      // ],
    ],
  };

  tte = [
    {
      type: 'lines3D',
      coordinateSystem: 'mapbox3D',
      z: 1,
      zlevel: 11,
      animation: true,
      effect: {
        show: true,
        period: 5,
        trailLength: 0.71,
        symbolSize: 5,
        symbol: 'circle',
        loop: true,
        trailColor: 'yellow',
      },
      lineStyle: { normal: { width: 1, curveness: 0 } },
      polyline: true,
      data: [
        [
          [89.53975, 43.83762, 20],
          [89.53338, 43.83799, 20],
          [89.52993, 43.83699, 20],
          [89.52705, 43.83595, 20],
          [89.52735, 43.83549, 20],
          [89.53267, 43.83558, 20],
        ],
      ],
    },
  ];

  constructor(private http: HttpClient, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    // this.loadTreeData();
  }

  ngAfterViewInit(): void {
    this.init3DMap();
  }

  ngOnDestroy(): void {
    this.f1Echart && this.f1Echart.dispose();
  }

  async init3DMap(): Promise<void> {
    const geoJson: any = {
      type: 'FeatureCollection',
      features: [],
    };

    const ft = {
      type: 'Feature',
      properties: {
        name: '',
        center: '',
        height: 0,
        color: '',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[]],
      },
    };
    await this.loadLand();
    this.landData
      .filter(l => l.lngLats.length > 0)
      .map(c => {
        let lngLat: any = [];
        let cc: any = [];
        if (Array.isArray(c.lngLats)) {
          cc = c.lngLats.map(c => {
            return [c.lng, c.lat];
          });
          lngLat = c.lngLats.map(c => {
            return { lng: c.lng, lat: c.lat };
          });
        }
        const center = getCenter(lngLat);
        geoJson.features.push({
          type: 'Feature',
          properties: {
            name: c.landName,
            desc: c.area,
            center,
          },
          geometry: {
            type: 'MultiPolygon',
            coordinates: [[cc]],
          },
        });
      });

    this.f1Echart.init('zbc', geoJson, 15, 20);
    const option = { ...this.f1Echart.baseOption };
    await this.loadHarewareData();
    // 画点
    this.mapPoints.data = this.deviceData.map(c => {
      return {
        name: c.hardwareName,
        value: [c.longitude, c.latitude, 28],
      };
    });

    // 划线
    await this.loadTreeData();
    this.mapDrawLine.data = this.lineData;
    option.series = [this.f1Echart.mapSeries, this.mapPoints, this.mapBaseLine, this.mapDrawLine];

    // option.series = [...this.tte];

    this.f1Echart.updateOption(option);
  }

  /**
   * 加载基地地块信息
   */
  async loadLand(): Promise<void> {
    const result = await this.http
      .post(urlSerialize('BaseLandPc/selectBaseLandTreeByAreaAsy', 'Base_four'), {
        recNo: '0',
      })
      .toPromise();
    if (Array.isArray(result)) {
      this.landData = result.map(c => {
        let lngLats = [];
        try {
          lngLats = JSON.parse(c.mapList);
        } catch (error) {}
        return {
          landName: c.landName,
          recNo: c.recNo,
          area: `${c.grownArea}${c.unitArea}`,
          lngLats: lngLats,
        };
      });
    }
  }

  /**
   * 加载硬件数据
   */
  async loadHarewareData(): Promise<void> {
    const result: any = await this.http
      .post(urlSerialize('HardwaresbasefacilityPc/HardwaresbasefacilityPcPage', 'Base_6806'), {
        start: 0,
        limit: BaseSetting.pageMaxSize,
      })
      .toPromise();
    if (result && Array.isArray(result.dataList)) {
      this.deviceData = result.dataList.filter(c => c.latitude);
    }
  }

  async loadTreeData(): Promise<void> {
    const result: any = await this.http
      .post(urlSerialize('Harewaresline/Page', 'Base_6806'), { start: 0, limit: BaseSetting.pageMaxSize })
      .toPromise();
    if (result && Array.isArray(result.dataList)) {
      this.rsData = result.dataList;
      // 父级id
      const pids = this.rsData.map(i => i.parentRecNo);
      //当前id
      const cids = this.rsData.map(i => i.hardwaresbasefacilityRecNo);
      // 找到父级id不在当前id的集合
      const nps = uniq(pids.filter(e => !cids.includes(e)));
      nps.forEach(n => {
        const dv = this.deviceData.find(d => d.recNo == n);
        if (dv) {
          dv.hardwaresbasefacilityRecNo = dv.recNo;
          this.rsData.push(dv);
        }
      });

      const tg = this.arrToTree(this.rsData);
      const ep = this.treeToArr(tg);
      ep.forEach(e => {
        const cl = e.paths.map(p => {
          let c: any = [];
          try {
            c = JSON.parse(p);
            c = c.map(t => {
              return [t.lng, t.lat,20];
            });
          } catch (error) {}

          return c;
        });

        this.lineData.push(...cl);
      });
    }
  }

  arrToTree<T extends object = any>(arr: readonly T[]): T[] {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }

    const opt: any = {
      idMapName: 'hardwaresbasefacilityRecNo',
      parentIdMapName: 'parentRecNo',
      childrenMapName: 'children',
    };
    const tree: T[] = [];
    const childrenOf: { [key: string]: T[] } = {};
    let rootPid = opt.rootParentIdValue;
    const arrType = arr as ReadonlyArray<{ [key: string]: any }>;
    if (!rootPid) {
      const pids = arrType.map(i => i[opt.parentIdMapName!]);
      const emptyPid = pids.findIndex(w => w == null);

      rootPid = emptyPid !== -1 ? pids[emptyPid] : pids.sort()[0];
    }
    for (const item of arrType) {
      const id = item[opt.idMapName!];
      const pid = item[opt.parentIdMapName!];
      if (id == pid) {
        continue;
      }
      childrenOf[id] = childrenOf[id] || [];
      item[opt.childrenMapName!] = childrenOf[id];

      if (pid !== rootPid) {
        childrenOf[pid] = childrenOf[pid] || [];
        childrenOf[pid].push(item as T);
      } else {
        tree.push(item as T);
      }
    }
    return tree;
  }

  /**
   * 获取路线
   * @param tree
   * @returns
   */
  treeToArr<T extends object = any>(tree: readonly T[]): T[] {
    const opt = {
      deepMapName: 'deep',
      parentMapName: 'parent',
      childrenMapName: 'children',
      pathsMapName: 'paths',
    };
    const result: Array<{ [key: string]: any }> = [];
    const inFn = (list: ReadonlyArray<{ [key: string]: any }>, parent: T | null, deep: number = 0) => {
      for (const i of list) {
        i[opt.deepMapName!] = deep;
        i[opt.parentMapName!] = parent;
        i.paths = [...(parent && Array.isArray(parent[opt.pathsMapName]) ? parent[opt.pathsMapName] : [])];
        if (i.path) {
          i.paths.push(i.path);
        }
        delete i[opt.parentMapName!];

        const children = i[opt.childrenMapName!];

        if (children != null && Array.isArray(children) && children.length > 0) {
          inFn(children, i as T, deep + 1);
        } else {
          result.push(i);
        }
      }
    };
    inFn(tree, null);
    return result as T[];
  }

  close() {
    this.activeModal.close();
  }
}
