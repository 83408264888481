import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePipe } from './date.pipe';
import { F1ACLDirective } from './f1-act.directive';
import { DeletePictureDirective, SelectPictureDirective } from './image/image.directive';
import { LetDirective } from './let.directive';
import { ValidatorDirective } from './validator/validator.directive';

const DIRECTIVES = [
  DatePipe,
  LetDirective,
  ValidatorDirective,
  SelectPictureDirective,
  DeletePictureDirective,
  F1ACLDirective,
];

@NgModule({
  declarations: DIRECTIVES,
  imports: [CommonModule],
  exports: DIRECTIVES,
})
export class F1DirectiveModule {}
