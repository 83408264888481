import { DatePipe } from './date.pipe';
import { DeletePictureDirective, SelectPictureDirective } from './image/image.directive';
import { LetDirective } from './let.directive';
import { ValidatorDirective } from './validator/validator.directive';

export const SHARED_DIRECTIVES = [
//   SelectPictureDirective,
//   DeletePictureDirective,
//   ValidatorDirective,
//   LetDirective,
//   DatePipe,
];
