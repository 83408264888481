import { Injectable, Injector, Inject } from '@angular/core';
import {
  NbAuthStrategy,
  NbPasswordAuthStrategyOptions,
  passwordStrategyOptions,
  NbAuthStrategyClass,
  NbAuthResult,
  NbAuthIllegalTokenError,
} from '@nebular/auth';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { urlSerialize } from '@shared';
import { AppService } from './app.service';
import { EncryptService } from './encrypt.service';
import { TokenService } from './auth/src/token/token.service';
import { DA_SERVICE_TOKEN, ITokenService } from './auth/src/token/interface';

@Injectable()
export class Fine1AuthStrategy extends NbAuthStrategy {
  protected defaultOptions: NbPasswordAuthStrategyOptions = passwordStrategyOptions;

  static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [Fine1AuthStrategy, options];
  }

  constructor(
    protected http: HttpClient,
    private encryptSrv: EncryptService,
    @Inject(DA_SERVICE_TOKEN) private tokenSrv: ITokenService,
    private route: ActivatedRoute,
    private injector: Injector,
  ) {
    super();
  }

  get appSrv() {
    return this.injector.get(AppService);
  }
  authenticate(data?: any): Observable<NbAuthResult> {
    const module = 'login';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
    const pData = {
      passdata: this.encryptSrv.rsaEncryptWithPk(
        JSON.stringify({
          username: data.username,
          password: data.password,
          code: data.code,
          key: data.key,
          comCode: this.appSrv.comCode,
          // comCode: 'cus000001cus000003',
          functionType: 1, //2020-08-13
          // comCode: 'cus000001cus000014'//38
          // comCode: 'cus000001cus000015'//99
        }),
        this.encryptSrv.signPublicKey,
      ),
    };
    return this.http
      .post(urlSerialize('pcUser/login?_allow_anonymous=true', this.appSrv.REQUEST_KEY.dashboard, false), pData)
      .pipe(
        map(res => {
          res['token'] = 'Bearer ' + res['accessToken'];
          res['comCode'] = res['customNo'];
          res['isButtonAuth'] = res['isButtonAuth']
          this.tokenSrv.set({ token: res['token'] });
          const encryptionIndex = {
            pubKey: res['pub_key'],
            splitlen: res['splitlen'],
            splitmode: res['splitmode'],
          };
          this.encryptSrv.encryptionIndex = encryptionIndex;
          return new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options),
            this.createToken(res['token'], requireValidToken),
          );
        }),
        catchError(res => {
    
          return this.handleResponseError(res, module);
        }),
      );
  }

  register(data?: any): Observable<NbAuthResult> {
    const module = 'register';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map(res => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse(data);
        }

        return res;
      }),
      map(res => {
        return new NbAuthResult(
          true,
          res,
          this.getOption(`${module}.redirect.success`),
          [],
          this.getOption('messages.getter')(module, res, this.options),
          this.createToken(this.getOption('token.getter')('login', res, this.options), requireValidToken),
        );
      }),
      catchError(res => {
        return this.handleResponseError(res, module);
      }),
    );
  }

  requestPassword(data?: any): Observable<NbAuthResult> {
    const module = 'requestPass';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map(res => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse();
        }

        return res;
      }),
      map(res => {
        return new NbAuthResult(
          true,
          res,
          this.getOption(`${module}.redirect.success`),
          [],
          this.getOption('messages.getter')(module, res, this.options),
        );
      }),
      catchError(res => {
        return this.handleResponseError(res, module);
      }),
    );
  }

  resetPassword(data: any = {}): Observable<NbAuthResult> {
    const module = 'resetPass';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const tokenKey = this.getOption(`${module}.resetPasswordTokenKey`);
    data[tokenKey] = this.route.snapshot.queryParams[tokenKey];
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map(res => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse();
        }

        return res;
      }),
      map(res => {
        return new NbAuthResult(
          true,
          res,
          this.getOption(`${module}.redirect.success`),
          [],
          this.getOption('messages.getter')(module, res, this.options),
        );
      }),
      catchError(res => {
        return this.handleResponseError(res, module);
      }),
    );
  }

  logout(): Observable<NbAuthResult> {
    const module = 'logout';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);

    return of({}).pipe(
      switchMap((res: any) => {
        if (!url) {
          return of(res);
        }
        return this.http.request(method, url, { observe: 'response' });
      }),
      map(res => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse();
        }

        return res;
      }),
      map(res => {
        return new NbAuthResult(
          true,
          res,
          this.getOption(`${module}.redirect.success`),
          [],
          this.getOption('messages.getter')(module, res, this.options),
        );
      }),
      catchError(res => {
        return this.handleResponseError(res, module);
      }),
    );
  }

  refreshToken(data?: any): Observable<NbAuthResult> {
    const module = 'refreshToken';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);

    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map(res => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse(data);
        }

        return res;
      }),
      map(res => {
        return new NbAuthResult(
          true,
          res,
          this.getOption(`${module}.redirect.success`),
          [],
          this.getOption('messages.getter')(module, res, this.options),
          this.createToken(this.getOption('token.getter')(module, res, this.options), requireValidToken),
        );
      }),
      catchError(res => {
        return this.handleResponseError(res, module);
      }),
    );
  }

  protected handleResponseError(res: any, module: string): Observable<NbAuthResult> {
    let errors = [];
    if (res instanceof HttpErrorResponse) {
      errors = this.getOption('errors.getter')(module, res, this.options);
    } else if (res instanceof NbAuthIllegalTokenError) {
      errors.push(res.message);
    } else {
      errors.push('Something went wrong.');
    }
    return of(new NbAuthResult(false, res, this.getOption(`${module}.redirect.failure`), errors));
  }
}
