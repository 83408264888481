/**
 * 图片管道
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'imagePipe'})
export class ImagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(imgBase64: string) {
    if (imgBase64 != null && imgBase64 != ''){
        return this.sanitizer.bypassSecurityTrustHtml('<img src="' + imgBase64 + '" style="width: 65px;height: 65px;"/>');
    }
    return imgBase64;
  }
}
