import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { F1EditorComponent } from './f1-editor.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ColorPickerModule } from 'ngx-color-picker';
import { F1CanvasComponent } from './f1-canvas/f1-canvas.component';
import { NzButtonModule, NzCardModule, NzInputModule, NzInputNumberModule, NzSelectModule, NzSliderModule, NzSwitchModule, NzToolTipModule } from 'ng-zorro-antd';


@NgModule({
  declarations: [F1EditorComponent, F1CanvasComponent],
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    NzButtonModule,
    NzInputModule,
    NzInputNumberModule,
    NzCardModule,
    NzSwitchModule,
    NzSelectModule,
    NzSliderModule,
    NzIconModule,
    NzToolTipModule
  ],
  exports: [F1EditorComponent, F1CanvasComponent]
})
export class F1EditorModule { }
