import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-component-tooltip',
  templateUrl: './tooltip.html',
  styleUrls: ['./tooltip.css'],
})
export class TooltipComponent implements OnInit {

  successMsg: string = '操作成功!'; //索引 为0
  failMsg: string = '操作失败!'; //索引 为1
  repetitionMsg: string = '操作失败,公司名称已经存在!'; //索引2
  deptDeleteMeg: string = '先删除子节点部门或公司员工,再删除本部门!'; //索引3
  billMeg: string = '已审核,不允许操作!'; //索引4
  workerMsg: string = '先在员工中添加数据,后添加作业工人!'; //索引5
  jobMsg: string = '已审核，不能删除!'; //索引6
  workerSameMsg: string = '作业工人手机号已存在!'; //索引7
  checkMsg: string = '已审核过了!'; //索引8
  billCheckMsg: string = '已审核过了!'; //索引9
  orderCheckMsg: string = '只有存储完,才算完成!'; //索引10
  makeBill: string = '单据不需要审核或者已审核!'; //索引11
  makeBillDetail: string = '从表不能全部删除!'; //索引12
  productBill: string = '单据已经处理，不能操作!'; //索引13
  productBillDetail: string = '请输入明细表信息!'; //索引14
  productBillDetail2: string = '成品不存在库存!'; //索引15
  proBillAuditWareUndefined: string = '单据填写的仓库不存在!'; //索引16
  proBillAuditMaterial: string = '单据物资不存在!'; //索引17
  proBillAuditWareMeterial: string = '仓库变动物资不存在!'; //索引18
  proBillAuditStatus: string = '单据状态已存在，不能重复操作!'; //索引19
  cropMsg: string = '当前作物，品种已存在!'; //索引20
  setMsg: string = '数据已存在，不能重复添加!'; //索引21
  land_massifMsg: string = '不能操作，已存在于园区!'; //索引22
  park_massifMsg: string = '不能操作，已存在于基地!'; //索引23
  warehouseTransportMsg: string = '调入/调出仓库不能为空!'; //索引24
  wareHouseTransportAudit1: string = '库存不存在，不能操作!'; //索引25
  wareHouseTransportAudit2: string = '调拨库存少于安全库存，不能调用!'; //索引26
  wareHouseTransportChange: string = '调入和调出仓库不能相同!'; //索引27
  transferChange: string = '调入调出仓库不能相同!'; //索引28
  hardwareTmp: string = '硬件设置数据为空!'; //索引29
  mobileUser: string = '没有员工信息,不能审核!'; //索引30
  acceptMsg: string = '只有存储完,才能验收!'; //索引31
  materialMsg: string = '物资使用中，不能删除!'; //索引32
  codeMsg: string = '无效的朔源上报码!'; //索引33
  facilityMsg: string = '正在使用，不能删除!'; //索引34
  landHareWareMsg: string = '该设备已存在于地块，不能重复添加!'; //索引35
  hardWareSet: string = '请先删除控制板!'; //索引36
  hardWareFacility: string = '请先删除硬件设施!'; //索引37
  cropTreeRecord: string = '您输入的二维码不存在,请输入真实的二维码!'; //索引38
  cropTreeBatch: string = '批次已存在！'; //索引39
  cropFruiterBatch: string = '二维码重复添加,请检查后重新添加！'; //索引40
  warehouseBill: string = '当前状态不能操作!'; //索引41
  companyDel: string = '先删除子节点公司、下级部门或公司员工,再删除本公司!'; //索引42
  autoCheck: string = '当前月份无盘点数据！'; //索引43
  warningMsg: string = '不能以自身作为条件硬件！'; //索引44
  proBill: string = '当前批次已入库，不能重复操作!'; //索引45
  declare1: string = '无效的动态码!'; //索引46
  declare2: string = '生产订单不存在，不能上报!'; //索引47
  warningInfo1: string = '请先添加公司、部门信息！'; //索引48
  warningInfo2: string = '请先添加公司信息！'; //索引49
  createQr: string = '失败，二维码不存在，不能打印!'; //索引50
  wareHouseName: string = '物料名称不能重复!'; //索引51
  valueMsg: string = '请检查是否满足该条件：库存数量 > 安全库存 > 最低库存'; //索引52
  dateMsg: string = '请检查是否满足该条件：生产日期 < 变动日期 < 过期日期'; //索引53
  materialRelaseMsg: string = '物料名称和品牌不能重复!'; //索引54
  ridMsg: string = '同一个控制板的rid不能重复!'; //索引55
  ridSameMsg: string = 'rid不能相同!'; //索引56
  hardWInfo: string = '使用中，不能删除!'; //索引57
  wareHouseDiffent: string = '调入、调出仓库不能相同!'; //索引58
  exportWarehouse: string = '调出仓库数量不足!'; //索引59
  indexNotNull: string = '排序不能为空!'; //索引60
  ridDiffent: string = 'rid不能重复!'; //索引61
  proBillMsg: string = '请在成品出入库单据从表添加附属信息!'; //索引62
  modalHeader: string = '温馨提示'; //提示框标题
  modalContent: string = ''; //提示框内容
  msgIndex: number = 0; //提示信息索引 默认为0


  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initData();
  }

  /**
   * 初始化数据
   */
  initData() {
    if (this.modalContent == '') {
      const arrTooltipMsg: string[] = [this.successMsg, this.failMsg, this.repetitionMsg, this.deptDeleteMeg, this.billMeg,
      this.workerMsg, this.jobMsg, this.workerSameMsg, this.checkMsg, this.billCheckMsg, this.orderCheckMsg, this.makeBill,
      this.makeBillDetail, this.productBill, this.productBillDetail, this.productBillDetail2, this.proBillAuditWareUndefined,
      this.proBillAuditMaterial, this.proBillAuditWareMeterial, this.proBillAuditStatus, this.cropMsg, this.setMsg, this.park_massifMsg,
      this.land_massifMsg, this.warehouseTransportMsg, this.wareHouseTransportAudit1, this.wareHouseTransportAudit2, this.wareHouseTransportChange,
      this.transferChange, this.hardwareTmp, this.mobileUser, this.acceptMsg, this.materialMsg, this.codeMsg, this.facilityMsg, this.landHareWareMsg,
      this.hardWareFacility, this.hardWareSet, this.cropTreeRecord, this.cropTreeBatch, this.cropFruiterBatch, this.warehouseBill, this.companyDel,
      this.autoCheck, this.warningMsg, this.proBill, this.declare1, this.declare2, this.warningInfo1, this.warningInfo2, this.createQr, this.wareHouseName,
      this.valueMsg, this.dateMsg, this.materialRelaseMsg, this.ridMsg, this.ridSameMsg, this.hardWInfo, this.wareHouseDiffent, this.exportWarehouse, this.indexNotNull, this.ridDiffent, this.proBillMsg];
      this.modalContent = arrTooltipMsg[this.msgIndex];
    }

    setTimeout(() => {
      this.closeModal();
    }, 3000); //设置3秒自动关闭提示信息
  }

  /**
   * 取消 关闭窗口
   */
  closeModal() {
    this.activeModal.close();
  }
}
