import { Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * echarts 折线图
 */
@Component({
  selector: 'c-echarts-line',
  templateUrl: './c-echarts-line.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CEChartsLine),
      multi: true,
    },
  ],
})
export class CEChartsLine implements ControlValueAccessor, OnInit {
  @Input() config: Object = {}; //图表配置参数
  @Input() markLine: Object = null; //图表基准线参数
  @Input() data: Object[] = []; //图表配置参数
  private innerValue: Object[] = [];
  private onTouchedCallback: () => void = function() {};
  private onChangeCallback: (_: any) => void = function() {};
  @ViewChild('chartsRef', {
    static: true,
  }) chartsRef: ElementRef;
  oECharts: any = null;
  constructor(@Inject('utilsService') private utilsService) {}

  ngOnInit() {
    this.initConfig();
    this.initECharts();
  }

  /**
   * 初始化配置
   */
  initConfig() {
    this.config = Object.assign(
      {
        width: 500,
        height: 350,
        title: '',
        legendData: [],
        yMin: null,
        yMax: null,
        markLineYAxisMin: 0,
        markLineYAxisMax: 0,
      },
      this.config,
    );

    if (this.markLine != null) {
      this.markLine = Object.assign(
        {
          symbol: 'none', //去掉警戒线最后面的箭头
          label: {
            position: 'end', //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
          },
          data: [
            {
              silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: 'dotted', //solid
                color: '#FA3934',
              },
              yAxis: 0,
            },
            {
              silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: 'dotted', //solid
                color: '#FA3934',
              },
              yAxis: 0,
            },
          ],
        },
        this.markLine,
      );
    }
  }

  /**
   * 初始化图表
   */
  initECharts() {
    this.oECharts = echarts.init(
      this.chartsRef.nativeElement as HTMLDivElement,
      {},
      {
        width: this.config['width'],
        height: this.config['height'],
      },
    );

    const option = {
      title: {
        text: this.config['title'],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        formatter: params => {
          return (
            this.utilsService.format(new Date(params[0]['value'][0]), 'yyyy-MM-dd hh:mm') +
            '<br/>' +
            params[0]['marker'] +
            params[0]['seriesName'] +
            '：' +
            Number(params[0]['value'][1]).toFixed(2)
          );
        },
      },
      legend: {
        data: this.config['legendData'],
        y: 'bottom',
      },
      xAxis: {
        type: 'time',
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true /*,
          formatter:(val)=> {
            return this.utilsService.format(new Date(val),'hh:mm');
          }*/,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        splitLine: {
          show: false,
        },
        min: this.config['yMin'],
        max: this.config['yMax'],
      },
      series: [
        {
          name: this.config['legendData'][0],
          type: 'line',
          showSymbol: false,
          hoverAnimation: false,
          data: this.data,
          markLine: this.markLine || {},
        },
      ],
    };
    this.oECharts.setOption(option);
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.changeData();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * 设置数据到图表
   */
  changeData() {
    if (this.oECharts != null) {
      const data: Object[] = this.innerValue || [];
      this.oECharts.setOption({
        series: [{ data: data }],
      });
    }
  }
}
