import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Page } from '@model/page';
import { AppService, NotificationService } from '@service/common';
import { urlSerialize } from '../../../utils/url';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'ngx-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss'],
})
export class MaterialSelectComponent implements OnInit {
  categoryData: any[] = [];
  typeData: any[] = [];

  start = 1; // 当前页
  limit = 20; // 每页显示条数 默认为20条
  data: any[] = []; // 表格数据
  queryParam: any = {}; // 页面查询对象
  loading = false; // 加载层
  nzFrontPagination = false; // 前端对数据进行分页
  totalSize = 0; // 总条数
  nzScroll: any = { y: '600px' }; // 滚动配置
  nzSize = 'small'; // 正常或迷你类型，default or small or middle
  indeterminate = false; // 设置 indeterminate 状态，只负责样式控制
  ngModelOptions: any = { standalone: true };

  constructor(
    private http: HttpClient,
    private modalSrv: NzModalService,
    private appSrv: AppService,
    private modalRef?: NzModalRef,
  ) {}

  ngOnInit() {
    this.getCategoryData();
    this.getTypeData();
    this.loadData();
  }

  /**
   * 物资类别数据
   */
  async getCategoryData() {
    const result = await this.http.post(urlSerialize('ProInfoPc/getAllproCategory', 'Base_four'), {}).toPromise();
    if (Array.isArray(result)) {
      this.categoryData = result;
    }
  }

  /**
   * 物资类型数据
   */
  async getTypeData() {
    let paramJson: Object = { start: 0, limit: 200 };
    const result = await this.http
      .post(urlSerialize('ProInfoPc/getAllproCategory', 'Base_four'), {
        paramJson,
      })
      .toPromise();
    if (Array.isArray(result)) {
      this.typeData = result;
    }
  }

  /**
   * 加载数据
   * @param pageInfo 当前页对象
   */
  loadData() {
    let paramJson: Object = Object.assign(
      {},
      { start: ((isNaN(this.start) ? 1 : this.start) - 1) * this.limit, limit: this.limit },
      this.queryParam,
    );

    this.http.post(urlSerialize('ProInfoPc/ProInfoPage', 'Base_four'), paramJson).subscribe((result: any) => {
      this.data = result.dataList;
      this.totalSize = result.totalSize;
    });
  }

  /**
   * 查询
   */
  onQuery() {
    this.start = 0; //初始化分页start值，解决查询和重置时偏移量问题
    this.loadData();
  }

  /**
   * 重置
   */
  onRest() {
    this.queryParam = {};
    this.onQuery();
  }

  onClose(): void {
    this.modalRef.close();
  }

  onChoose(item: any): void {
    this.modalRef.close(item);
  }
}
