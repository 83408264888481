import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { F1SimpleCalendar } from '../f1-simple-calendar';

@Component({
  selector: 'f1-calendar',
  templateUrl: './f1-calendar.component.html',
  styleUrls: ['./f1-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class F1CalendarComponent implements OnInit, AfterViewInit {
  f1Calendar = new F1SimpleCalendar();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.f1Calendar.init();
  }
}
