import Calendar from 'tui-calendar';
import { guid } from '../../utils/util';

export class F1SimpleCalendar {
  id = `calendar-${guid()}`;
  calendar: Calendar;

  init(): void {
    const el: any = document.querySelector(`#${this.id}`);
    if (!el) {
      return;
    }
    this.calendar = new Calendar(el, {
      defaultView: 'month',
      taskView: true,
      template: {
        monthDayname: function(dayname) {
          return '<span class="calendar-week-dayname-name">' + dayname.label + '</span>';
        },
      },
    });
  }
}
