import { Injectable, Injector, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from '@service/common/app.service';
import { CacheService } from '@service/common';
import { environment } from '@env/environment';
import { CommonService } from '@shared/utils/common.service';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    private httpClient: HttpClient,

    private cacheSrv: CacheService,
    private injector: Injector,
    private commonSrv: CommonService,
  ) {}

  async load(): Promise<void> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088

    return new Promise(async resolve => {
      zip(
        this.httpClient.get<any>(`assets/json/config.json?_allow_anonymous=true&v=${new Date().getTime()}`),
        this.httpClient.post(environment.dnsUrl + 'custom/getCustomsByRealmNameFromPc?_allow_anonymous=true', {
          realmNamePc: location.host,
        }),
      )
        .pipe(
          // Exception messages generated after receiving another interceptor
          catchError((config, recNo) => {
            // console.warn(`StartupService.load: Network request failed`, res);
            resolve(null);
            return [config, recNo];
          }),
        )
        .subscribe(
          ([appData, recNo]) => {
            try {
              const config = appData.interfaces;
              for (const key in config) {
                this.cacheSrv.set(key, config[key]);
              }

              this.commonSrv.setHardwareTypeMap(appData.hardwareTypeMaps);
              this.injector.get(AppService).comCode = recNo.recNo;
              this.injector.get(AppService).stylet = true;
            } catch (err) {}
            resolve();
          },
          err => {
            resolve();
          },
          () => {},
        );
    });
  }
}
