import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { InputBoolean } from '../utils/check';
import { BooleanInput } from '../utils/convert';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppService } from '@service/common';
export interface ACLType {
  /**
   * 角色
   */
  role?: string[];
  /**
   * 权限点
   */
  ability?: number[] | string[];
  /**
   * Validated against, default: `oneOf`
   * - `allOf` the value validates against all the roles or abilities
   * - `oneOf` the value validates against exactly one of the roles or abilities
   */
  mode?: 'allOf' | 'oneOf';
  /**
   * 是否取反，即结果为 `true` 时表示未授权
   */
  except?: boolean;
  [key: string]: any;
}
export declare type ACLCanType = number | number[] | string | string[] | ACLType;

@Directive({
  selector: '[f1Acl]',
  exportAs: 'f1Acl'
})
export class F1ACLDirective implements OnDestroy {
  static ngAcceptInputType_except: BooleanInput;

  private _value!: string;
  private _change$: Subscription;
  private _thenTemplateRef: TemplateRef<void> | null = null;
  private _elseTemplateRef: TemplateRef<void> | null = null;
  private _thenViewRef: EmbeddedViewRef<void> | null = null;
  private _elseViewRef: EmbeddedViewRef<void> | null = null;

  constructor(templateRef: TemplateRef<void>, private srv: AppService, private _viewContainer: ViewContainerRef) {
    this._change$ = this.srv.change.pipe(filter(r => r != null)).subscribe(() => this._updateView());
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set f1Acl(value: string) {
    this._value = value;
    this._updateView();
  }

  @Input()
  set f1AclThen(templateRef: TemplateRef<void> | null) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null;
    this._updateView();
  }

  @Input()
  set f1AclElse(templateRef: TemplateRef<void> | null) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;
    this._updateView();
  }

  @Input() @InputBoolean() except = false;

  protected _updateView(): void {
    const res = this.srv.can(this._value);
    if ((res && !this.except) || (!res && this.except)) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._change$.unsubscribe();
  }
}
