


/**
 *
 * @param url 地址
 * @param dashboard key值
 * @param isEncrypt 是否加密
 */
export function urlSerialize(url: string, dashboard: string, isEncrypt = true) {
  return `${url}#dashboard#${dashboard}#dashboard#${isEncrypt}`;
}
export function isUrlSerialize(url) {
  return url && url.includes('#dashboard#');
}
export function urlDeserialization(url: string) {
  if (isUrlSerialize(url)) {
    return url.split('#dashboard#');
  }
  return url;
}

/**
 * @param origin
 * @param pathname
 */
export function fine1Url(origin: string, pathname: string = ''): string {
  let url = '';
  let v = localStorage.getItem(origin) || '';
  let val: any = {};
  if (!v) {
    v = localStorage.getItem('httpApiaddress');
    if (v) {
      let urls: any[] = JSON.parse(v).v;
      const t = urls.find(u => Object.keys(u)[0] == origin);
      if (t) {
        url = t[origin];
      }
    }
  } else {
    val = JSON.parse(v);
    if (val.v) {
      val = val.v;
    }

    if (val) {
      if (val.test) {
        url = val.url + pathname;
      } else {
        url = val.pUrl + pathname;
      }
    }
  }

  return url;
}
