/**
 * An object used to get page information from the server
 */

export class Page {
  constructor(start = 0, limit = 20) {
    this.start = start;
    this.limit = limit;
  }
  start: number = 0; // 当前页
  limit: number = 20; // 每页显示条数 默认为20条
  totalSize: number = 0; // 总条数
  pageCount: number = 0; // 总页数
}

export class Page2 {
  constructor(_start = 0) {
    this.start = _start;
  }
  start: number = 1; // 当前页
  limit: number = 5; // 每页显示条数 默认为5条
  totalSize: number = 0; // 总条数
  pageCount: number = 0; // 总页数
}
