import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class NavGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    let paramUrl = '';
    let url = '';
    if (state.url.includes('?')) {
      paramUrl = state.url.substring(state.url.indexOf('?') + 1);
      url = state.url.substring(0, state.url.indexOf('?'));
    } else {
      url = state.url;
    }

    if (paramUrl.includes('lmsigna')) {
      return true;
    } else {
      this.router.navigateByUrl(
        url +
          '?lmsigna=' +
          encodeURIComponent(`${this.encryptSrv.rsaEncrypt(paramUrl)}`),
      );
      return false;
    }
  }

  constructor(private router: Router, private encryptSrv: EncryptService) {}
}
