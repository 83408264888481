import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { _HttpClient } from '@service/common';
import { getCenter, guid } from './util';
// 113.86765814887153
// 22.559384494357637
export class DrawMap {
  map: any;
  zoom = 16;

  mapId = `map-${guid()}`;

  isLoadedScript = true;
  isViewInit = false;

  showSearch = false;

  searchText = '';

  handler: any;

  isDrawLine = false;
  isEditDrawLine = false;

  /**
   * 删除模式
   */
  isDelMode = false;
  /**
   * 当前选中的对象
   */
  currentSelectedObj: any;

  tools = [
    // { name: '标点', type: 1, icon: 'iconfont icon-position', active: false },
    // { name: '画面', type: 2, icon: 'iconfont icon-xuanmianmianji', active: false },
    // { name: '测距', type: 3, icon: 'iconfont icon-zhixianjuli', active: false },
    // { name: '获取面图', type: 4, icon: 'iconfont icon-tuceng', active: false },
    { name: '连线', type: 5, icon: 'iconfont icon-xian', active: false },
    // { name: '删除', type: 6, icon: 'iconfont icon-qingchu', active: false },
    { name: '隐藏', type: 7, icon: 'iconfont icon-xian', active: false },
  ];

  delTool = [
    // { name: '清空', type: 9, icon: 'iconfont icon-qingchu', active: false },
    // { name: '关闭', type: 10, icon: 'iconfont icon-close', active: false },
  ];

  get isInit() {
    return this.isLoadedScript && this.isViewInit;
  }

  // 标准icon
  icon = new T.Icon({
    iconUrl: './assets/images/marker-icon.png',
    iconSize: new T.Point(12.5, 20.5),
    // iconAnchor: new T.Point(12.5, 12.5),
  });
  // 选中icon
  selectedIcon = new T.Icon({
    iconUrl: './assets/images/marker-icon-red.png',
    iconSize: new T.Point(12.5, 20.5),
    // iconAnchor: new T.Point(12.5, 12.5),
  });

  //创建标注工具对象
  markerTool: any;
  // 测面工具
  polygonTool: any;
  // 测距工具
  lineTool: any;
  /**
   * 当前标点
   */
  currentMarker: any;
  /**
   * 当前操作的line
   */
  currentLine: any;

  pointLines: any[] = [];
  /**
   * 地块信息
   */
  lands: any[] = [];

  /**
   * 当前标点的集合
   */
  markerPoints: any[] = [];

  /**
   * 当前标点的集合
   */
  addLabel: any[] = [];

  /**
   * 当前标点的集合
   */
  addMarker: any[] = [];

  /**
   * 当前标点是否展示
   */
  labelShow = true;
  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * areaRecNo: 地块recNo
   * deviceRecNo: 设备recNo
   * lat:
   * lng:
   *
   * }
   */
  drawMarkerCb = (item: any, params: any) => {};

  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * areaRecNo: 地块recNo
   * GroupNo: 分组recNo
   * lat:
   * lng:
   *
   * }
   */
  drawMarkerGroup = (item: any, params: any) => {};

  /**
   * 返回解析的数据
   * item:原始对象
   * params:{
   * cpoint: 当前硬件
   * ppoint: 父级硬件
   * path: 路径
   *
   * }
   */
  drawLineCb = (params: any) => {};

  /**
   * 删除回调
   * @param params 原始对象
   */
  deleteCb = (params: any) => {};

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone, private http: HttpClient) {}

  initMap(): void {
    if (!this.isInit) {
      return;
    }
    const el = document.querySelector(`#${this.mapId}`);
    if (!el) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.map = new T.Map(this.mapId);

      this.map.centerAndZoom(new T.LngLat(89.527895, 43.817011), this.zoom);

      this.map.setMaxZoom(16);
      // this.map.defaultMapType = TMAP_HYBRID_MAP;

      //允许鼠标滚轮缩放地图
      this.map.enableScrollWheelZoom();
      //创建缩放平移控件对象
      const control = new T.Control.Zoom();
      control.setPosition(T_ANCHOR_BOTTOM_RIGHT);
      this.map.addControl(control);
      // 地图类别空间
      // const mapCtrl = new T.Control.MapType();
      // mapCtrl.position = T_ANCHOR_BOTTOM_RIGHT;
      // this.map.addControl(mapCtrl);
      this.map.setMapType(TMAP_HYBRID_MAP);
      // const icon = new T.Icon({
      //   iconUrl: 'http://api.tianditu.gov.cn/v4.0/image/marker-icon.png',
      //   iconSize: new T.Point(12.5, 20.5),
      //   // iconAnchor: new T.Point(12.5, 12.5),
      // });
      this.markerTool = new T.MarkTool(this.map, {
        icon: this.icon,
        follow: true,
      });
      const config = {
        showLabel: true,
        color: 'blue',
        weight: 3,
        opacity: 0.5,
        fillColor: '#FFFFFF',
        fillOpacity: 0.5,
      };
      //测面工具
      this.polygonTool = new T.PolygonTool(this.map, config);

      //测距工具
      this.lineTool = new T.PolylineTool(this.map, config);
    });
  }

  search(): void {
    // http://api.tianditu.gov.cn/v2/search?postStr={"keyWord":"北京大学","level":12,"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":10}&type=query&tk=您的密钥
    this.http
      .get(
        `${location.protocol}//api.tianditu.gov.cn/v2/search?postStr={"keyWord":"${this.searchText}","level":${this.zoom},"mapBound":"116.02524,39.83833,116.65592,39.99185","queryType":1,"start":0,"count":1}&type=query&tk=${environment.mapKey}`,
      )
      .subscribe((res: any) => {
        let lonlat;
        switch (res.resultType) {
          case 1:
            if (Array.isArray(res.pois) && res.pois.length > 0) {
              lonlat = res.pois[0].lonlat;
            }
            break;
          case 3:
            if (res.area && res.area.lonlat) {
              lonlat = res.area.lonlat;
            }
            break;

          default:
            break;
        }
        if (lonlat) {
          const na = lonlat.split(',');
          this.map.panTo(new T.LngLat(na[0], na[1]), this.zoom);
        }
      });
  }

  canClickTypes = [1, 2, 3];
  clickTool(item: any) {
    this.tools.filter(t => t != item).forEach(t => (t.active = false));
    if (this.canClickTypes.includes(item.type)) {
      item.active = !item.active;
    }

    switch (item.type) {
      case 1:
        item.active ? this.openMarker() : this.closeMarker();
        break;

      case 2:
        item.active ? this.openPolygon() : this.closePolygon();
        break;

      case 3:
        item.active ? this.openLine() : this.closeLine();
        break;

      case 4:
        this.getPoints();
        break;

      case 5:
        this.isDrawLine = !this.isDrawLine;
        break;

      case 6:
        this.isDelMode = !this.isDelMode;
        break;

      case 7:
        this.showHideLabel();
        break;

      case 9:
        this.clear();
        break;

      case 10:
        // this.f1Close && this.f1Close();
        break;
      case 11:
        // this.f1Close && this.f1Close();
        break;

      default:
        break;
    }
  }

  /**
   * 编辑标点
   */
  editMarker(): void {
    var markers = this.markerTool.getMarkers();
    for (var i = 0; i < markers.length; i++) {
      markers[i].enableDragging();
    }
  }
  /**
   * 结束编辑标点
   */
  endeditMarker() {
    var markers = this.markerTool.getMarkers();
    for (var i = 0; i < markers.length; i++) {
      markers[i].disableDragging();
    }
  }

  /**
   * 打开标点
   */
  openMarker(): void {
    if (this.currentMarker) {
      try {
        this.setMarkerIcon(this.currentMarker, this.icon);
      } catch (error) {}
    }
    this.endeditMarker();
    this.markerTool.addEventListener('mouseup', (args: any) => {
      this.currentMarker.latitude = args.currentLnglat.lat;
      this.currentMarker.longitude = args.currentLnglat.lng;
      var label = new T.Label({
        text: `${this.currentMarker.hardwareName}`,
        position: args.currentLnglat,
        offset: new T.Point(-10, -10),
      });
      label.f1Type = 'label';
      label.recNo = this.currentMarker.recNo;
      args.currentMarker.recNo = this.currentMarker.recNo;
      args.currentMarker.label = label;
      args.currentMarker.f1Type = 'marker';
      args.currentMarker.cpoint = this.currentMarker;

      args.currentMarker.addEventListener('click', (args: any) => {
        if (this.isDrawLine) {
          const t = this.markerPoints.find(m => m.recNo == args.target.recNo);
          if (this.pointLines.length >= 1 && t) {
            this.pointLines.push(t);
            // 2个点一条线

            var points = [];
            points.push(new T.LngLat(this.pointLines[0].longitude, this.pointLines[0].latitude));
            points.push(new T.LngLat(this.pointLines[1].longitude, this.pointLines[1].latitude));
            //创建线对象
            const line = new T.Polyline(points, {
              color: '#1890ff',
              weight: 4,
            });
            line.cpoint = this.pointLines[1];
            line.ppoint = this.pointLines[0];
            line.f1Type = 'line';
            this.currentLine = line;
            //向地图上添加线
            this.map.addOverLay(line);
            this.isEditDrawLine = true;
            line.enableEdit();

            line.addEventListener('click', (args: any) => {
              this.currentSelectedObj = args.target;
            });
          } else {
            this.pointLines.push(t);
          }

          // this.pointLines.push(args.lnglat)
        } else {
          this.currentSelectedObj = args.target;
        }
      });

      this.map.addOverLay(label);
      this.closeMarker();
      this.closeSelected();

      const area = this.fineArea({ lat: args.currentLnglat.lat, lng: args.currentLnglat.lng });

      const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(args.currentLnglat.lng, args.currentLnglat.lat);

      this.drawMarkerCb(this.currentMarker, {
        areaRecNo: area ? area.recNo : '',
        deviceRecNo: this.currentMarker.recNo,
        lat: gcjo2[1],
        lng: gcjo2[0],
      });
      this.markerPoints.push(this.currentMarker);
      this.setMarkerIcon(this.currentMarker, this.icon);
      this.currentMarker = undefined;
    });
    this.markerTool.open();
  }

  /**
   * 关闭标点
   */
  closeMarker(): void {
    this.endeditMarker();
    this.markerTool.removeEventListener('mouseup');
    this.markerTool.close();
  }

  /**
   * 多边形工具
   */
  openRectangleTool(): void {
    if (this.handler) this.handler.close();
    this.handler = new T.RectangleTool(this.map, { follow: true });
    this.handler.open();
  }

  /**
   * 打开测面
   */
  openPolygon(): void {
    this.polygonTool.open();
    this.polygonTool.removeEventListener('draw');
    this.polygonTool.addEventListener('draw', (args: any) => {
      console.log(JSON.stringify(args.currentLnglats));
      console.log(args.currentArea);
      //   this.mapChange.emit({
      //     currentLnglats: args.currentLnglats,
      //     currentArea: args.currentArea,
      //   });
      this.closePolygon();
      this.closeSelected();
    });
  }

  /**
   * 关闭测面
   */
  closePolygon(): void {
    this.polygonTool.close();
    this.polygonTool.removeEventListener('draw');
  }

  /**
   * 打开测距
   */
  openLine(): void {
    this.lineTool.open();
    this.lineTool.removeEventListener('draw');
    this.lineTool.addEventListener('draw', (args: any) => {
      const ls = args.allPolylines.map((p: any) => {
        return p.getLngLats();
      });

      //   this.lineChange.emit({
      //     ls,
      //   });
      this.closeLine();
      this.closeSelected();
    });
  }

  /**
   * 关闭测距
   */
  closeLine(): void {
    this.lineTool.close();
    this.lineTool.removeEventListener('draw');
  }

  /**
   * 获取点位
   */
  getPoints(): void {
    const pgs = this.polygonTool.getPolygons();
    console.log(pgs);
  }
  /**
   * 清除所有图层
   */
  clear(): void {
    this.map.clearOverLays();
  }

  closeSelected(): void {
    this.tools.forEach(c => (c.active = false));
  }

  drawLand(landData: any[]) {
    // 准备模拟数据
    var points: any[] = [];
    let center: any[] = [];
    this.lands = landData;
    this.lands.forEach(c => {
      points = [];
      if (Array.isArray(c.lngLats)) {
        c.lngLats.forEach(c => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.lng, c.lat);
          points.push(new T.LngLat(wgs84[0], wgs84[1]));
        });
      }

      //创建面对象
      var polygon = new T.Polygon(points, {
        color: 'blue',
        weight: 3,
        opacity: 0.5,
        fillColor: '#FFFFFF',
        fillOpacity: 0.5,
      });

      this.map.addOverLay(polygon);
      if (points.length > 0) {
        var label = new T.Label({
          text: `${c.landName}`,
          position: points[0],
          offset: new T.Point(20, 20),
        });
        this.map.addOverLay(label);
      }
    });
  }

  drawMarker(item: any): void {
    if (this.currentMarker) {
      this.setMarkerIcon(this.currentMarker, this.icon);
    }
    this.currentMarker = item;
    this.openMarker();
  }

  drawGroupMarker(item: any): void {
    if (this.currentMarker) {
      this.setMarkerIcon(this.currentMarker, this.icon);
    }
    this.currentMarker = item;
    this.openGroupMarker();
  }

  /**
   * 打开标点
   */
  openGroupMarker(): void {
    if (this.currentMarker) {
      try {
        this.setMarkerIcon(this.currentMarker, this.icon);
      } catch (error) {}
    }
    this.endeditMarker();
    this.markerTool.addEventListener('mouseup', (args: any) => {
      this.currentMarker.latitude = args.currentLnglat.lat;
      this.currentMarker.longitude = args.currentLnglat.lng;
      // this.currentSelectedObj = args.target;
      var label = new T.Label({
        text: `${this.currentMarker.groupName}`,
        position: args.currentLnglat,
        offset: new T.Point(-10, -10),
      });
      label.f1Type = 'label';
      label.recNo = this.currentMarker.recNo;
      args.currentMarker.recNo = this.currentMarker.recNo;
      args.currentMarker.label = label;
      args.currentMarker.f1Type = 'marker';
      args.currentMarker.cpoint = this.currentMarker;

      args.currentMarker.addEventListener('click', (args: any) => {
        if (this.isDrawLine) {
          const t = this.markerPoints.find(m => m.recNo == args.target.recNo);
          if (this.pointLines.length >= 1 && t) {
            this.pointLines.push(t);
            // 2个点一条线

            var points = [];
            points.push(new T.LngLat(this.pointLines[0].longitude, this.pointLines[0].latitude));
            points.push(new T.LngLat(this.pointLines[1].longitude, this.pointLines[1].latitude));
            //创建线对象
            const line = new T.Polyline(points, {
              color: '#1890ff',
              weight: 4,
            });
            line.cpoint = this.pointLines[1];
            line.ppoint = this.pointLines[0];
            line.f1Type = 'line';
            this.currentLine = line;
            //向地图上添加线
            this.map.addOverLay(line);
            this.isEditDrawLine = true;
            line.enableEdit();

            line.addEventListener('click', (args: any) => {
              this.currentSelectedObj = args.target;
            });
          } else {
            this.pointLines.push(t);
          }

          // this.pointLines.push(args.lnglat)
        } else {
          this.currentSelectedObj = args.target;
        }
      });

      this.map.addOverLay(label);
      this.closeMarker();
      this.closeSelected();

      const area = this.fineArea({ lat: args.currentLnglat.lat, lng: args.currentLnglat.lng });

      const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(args.currentLnglat.lng, args.currentLnglat.lat);

      this.drawMarkerGroup(this.currentMarker, {
        areaRecNo: area ? area.recNo : '',
        groupNo: this.currentMarker.recNo,
        lat: gcjo2[1],
        lng: gcjo2[0],
      });
      this.markerPoints.push(this.currentMarker);
      this.setMarkerIcon(this.currentMarker, this.icon);
      this.currentMarker = undefined;
    });
    this.markerTool.open();
  }

  hardwareHighlighting(item: any): void {
    console.log(this.currentMarker);
    console.log(this.icon);
    if (this.currentMarker) {
      this.setMarkerIcon(this.currentMarker, this.icon);
    }
    // 先移动地图到指定点
    this.map.panTo(new T.LngLat(item.longitude, item.latitude), this.zoom);
    var markers = this.markerTool.getMarkers();
    console.log(markers);
    let mk = markers.find(c => c.recNo == item.recNo);
    if (!mk) {
      const layers = this.map.getOverlays();
      console.log(layers);
      mk = layers.find(c => c.recNo == item.recNo && c.f1Type == 'marker');
      console.log(mk);
    }
    if (mk) {
      mk.setIcon(this.selectedIcon);
      this.currentMarker = item;
    }
  }

  editOneMarker(item: any): void {
    console.log(item);

    if (this.currentMarker) {
      this.setMarkerIcon(this.currentMarker, this.icon);
    }
    // 先移动地图到指定点
    this.map.panTo(new T.LngLat(item.longitude, item.latitude), this.zoom);
    var markers = this.markerTool.getMarkers();
    let mk = markers.find(c => c.recNo == item.recNo);
    if (!mk) {
      const layers = this.map.getOverlays();
      mk = layers.find(c => c.recNo == item.recNo && c.f1Type == 'marker');
    }
    if (mk) {
      mk.setIcon(this.selectedIcon);
      mk.enableDragging();
      mk.addEventListener('drag', (args: any) => {
        mk.label.setLngLat(args.lnglat);
      });
      mk.addEventListener('dragend', (args: any) => {
        this.currentMarker.latitude = args.lnglat.lat;
        this.currentMarker.longitude = args.lnglat.lng;
        mk.removeEventListener('drag');
        mk.removeEventListener('dragend');
        this.closeMarker();
        this.closeSelected();

        const area = this.fineArea({ lat: args.lnglat.lat, lng: args.lnglat.lng });

        const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(args.lnglat.lng, args.lnglat.lat);

        this.drawMarkerCb(this.currentMarker, {
          areaRecNo: area ? area.recNo : '',
          deviceRecNo: this.currentMarker.recNo,
          lat: gcjo2[1],
          lng: gcjo2[0],
        });

        this.setMarkerIcon(this.currentMarker, this.icon);
        this.currentMarker = undefined;
      });
      this.currentMarker = item;
    }
  }

  fineArea(pt: any) {
    let a: any;
    for (let index = 0; index < this.lands.length; index++) {
      const l = this.lands[index];
      if (this.isInsidePolygon(pt, l.lngLats)) {
        a = l;
        break;
      }
    }
    return a;
  }

  isInsidePolygon(checkPoint: any, polygonPoints: any[]) {
    var counter = 0;
    var i;
    var xinters;
    var p1, p2;
    var pointCount = polygonPoints.length;
    p1 = polygonPoints[0];

    for (i = 1; i <= pointCount; i++) {
      p2 = polygonPoints[i % pointCount];
      if (checkPoint.lng > Math.min(p1.lng, p2.lng) && checkPoint.lng <= Math.max(p1.lng, p2.lng)) {
        if (checkPoint.lat <= Math.max(p1.lat, p2.lat)) {
          if (p1.lng != p2.lng) {
            xinters = ((checkPoint.lng - p1.lng) * (p2.lat - p1.lat)) / (p2.lng - p1.lng) + p1.lat;
            if (p1.lat == p2.lat || checkPoint.lat <= xinters) {
              counter++;
            }
          }
        }
      }
      p1 = p2;
    }
    if (counter % 2 == 0) {
      return false;
    } else {
      return true;
    }
  }

  clearLine() {
    this.pointLines = [];
    if (this.currentLine) {
      this.currentLine.disableEdit();
      this.map.removeOverLay(this.currentLine);
    }
  }

  finishEditDrawLine() {
    this.isEditDrawLine = false;
    this.isDrawLine = false;
    const lnglats = this.currentLine.getLngLats().map(x => {
      const gcjo2 = FMapSdk.CoordTransform.WGS84ToGCJ02(x.lng, x.lat);
      return {
        lng: gcjo2[0],
        lat: gcjo2[1],
      };
    });

    this.drawLineCb({
      cpoint: this.currentLine.cpoint,
      ppoint: this.currentLine.ppoint,
      path: lnglats,
    });
    this.currentLine.disableEdit();
    this.currentLine = undefined;
    this.pointLines = [];
  }

  deleteSelected() {
    this.deleteCb(this.currentSelectedObj);
    if (this.currentSelectedObj) {
      if (this.currentSelectedObj.f1Type === 'marker') {
        this.map.removeOverLay(this.currentSelectedObj.label);
      }

      this.map.removeOverLay(this.currentSelectedObj);
    }
    this.currentSelectedObj = undefined;
  }

  subFailure(item) {
    var markers = this.markerTool.getMarkers();
    let mk = markers.find(c => c.recNo == item.groupNo);
    if (mk) {
      if (mk.f1Type === 'marker') {
        this.map.removeOverLay(mk.label);
      }
      this.map.removeOverLay(mk);
    }
  }

  editSelected() {
    // console.log(this.currentSelectedObj);
    // const Overlays = this.map.getOverlays();
    // this.editOneMarker(this.currentSelectedObj.cpoint);
    // Overlays.find(d => d.f1Type === 'line' && d.crs.recNo == this.currentSelectedObj.crs.recNo);
    // this.map.removeOverLay();
  }

  unSelected() {
    this.currentSelectedObj = null;
  }

  /**
   * 绘制硬件坐标
   */
  drawCoordinate(hc: any[]) {
    hc.filter(c => c.latitude && !c.detailNo).forEach(c => {
      const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(c.longitude, c.latitude);
      c.longitude = wgs84[0];
      c.latitude = wgs84[1];
      //创建标注对象
      if (c.groupName) {
        var label = new T.Label({
          text: `${c.groupName}`,
          position: new T.LngLat(c.longitude, c.latitude),
          offset: new T.Point(-10, -10),
        });
      } else {
        var label = new T.Label({
          text: `${c.hardwareName}`,
          position: new T.LngLat(c.longitude, c.latitude),
          offset: new T.Point(-10, -10),
        });
      }

      var marker = new T.Marker(new T.LngLat(c.longitude, c.latitude), {
        icon: this.icon,
        follow: true,
      });
      label.f1Type = 'label';
      label.recNo = c.recNo;
      marker.recNo = c.recNo;
      marker.label = label;
      marker.f1Type = 'marker';
      marker.cpoint = c;
      marker.addEventListener('click', (args: any) => {
        if (this.isDrawLine) {
          const t = this.markerPoints.find(m => m.recNo == args.target.recNo);
          if (this.pointLines.length >= 1 && t) {
            this.pointLines.push(t);
            // 2个点一条线

            var points = [];
            points.push(new T.LngLat(this.pointLines[0].longitude, this.pointLines[0].latitude));
            points.push(new T.LngLat(this.pointLines[1].longitude, this.pointLines[1].latitude));
            //创建线对象
            const line = new T.Polyline(points, {
              color: '#1890ff',
              weight: 4,
            });
            line.cpoint = this.pointLines[1];
            line.ppoint = this.pointLines[0];
            line.f1Type = 'line';
            this.currentLine = line;
            //向地图上添加线
            this.map.addOverLay(line);
            this.isEditDrawLine = true;
            line.enableEdit();

            line.addEventListener('click', (args: any) => {
              this.currentSelectedObj = args.target;
            });
          } else {
            this.pointLines.push(t);
          }

          // this.pointLines.push(args.lnglat)
        } else {
          this.currentSelectedObj = args.target;
        }
      });
      //向地图上添加标注
      this.map.addOverLay(label);
      this.map.addOverLay(marker);
      this.addLabel.push(label);
      this.addMarker.push(marker);
      this.markerPoints.push(c);
    });
  }

  /**
   * 绘制关系
   */
  drawRelationship(mapRs: any[]) {
    mapRs
      .filter(c => c.path)
      .forEach(c => {
        let path: any[];
        try {
          path = JSON.parse(c.path);
        } catch (error) {
          return;
        }
        var points = [];
        path.forEach(p => {
          const wgs84 = FMapSdk.CoordTransform.GCJ02ToWGS84(p.lng, p.lat);
          points.push(new T.LngLat(wgs84[0], wgs84[1]));
        });

        //创建线对象
        const line = new T.Polyline(points, {
          color: '#108ee9',
          weight: 4,
        });
        line.crs = c;
        line.f1Type = 'line';
        //向地图上添加线
        this.map.addOverLay(line);
        line.addEventListener('click', (args: any) => {
          this.currentSelectedObj = args.target;
        });
      });
  }

  setMarkerIcon(item: any, icon: any) {
    var markers = this.markerTool.getMarkers();
    let mk = markers.find(c => c.recNo == item.recNo);
    if (!mk) {
      const layers = this.map.getOverlays();
      mk = layers.find(c => c.recNo == item.recNo && c.f1Type == 'marker');
    }
    if (mk) {
      mk.setIcon(icon);
    }
    return mk;
  }

  showHideLabel() {
    if (this.labelShow) {
      const Overlays = this.map.getOverlays();
      Overlays.forEach(element => {
        if (element.f1Type == 'label' || element.f1Type == 'marker') {
          this.map.removeOverLay(element);
        }
      });
    } else {
      this.addLabel.forEach(element => {
        this.map.addOverLay(element);
      });
    }
    this.labelShow = !this.labelShow;
  }
}
