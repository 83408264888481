import { toNumber } from '@service/common';
import { addMinutes, format, subHours, subDays, subWeeks, subMonths, addHours, addDays } from 'date-fns';

export function guid() {
  return 'xxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const geoJsonTmp = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: '',
        center: '',
        height: 0,
        color: '',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[]],
      },
    },
  ],
};

export function toGeoJson(name: string, coordinates: any[], properties: any = {}) {
  const json = { ...geoJsonTmp };
  json.features[0].properties.name = name;
  json.features[0].properties = { ...json.features[0].properties, ...properties };
  json.features[0].geometry.coordinates[0] = [...coordinates];
  return json;
}

/**
 * 平方米转亩
 * @param n
 * @returns
 */
export function m2ToMu(n: number) {
  return roundNum(0.0015 * n);
}

export function roundNum(val: number | string, n = 2) {
  val = Number.parseFloat(val.toString());
  if (n <= 0) return Math.round(val);
  // eslint-disable-next-line no-restricted-properties
  val = Math.round(Math.round(val * Math.pow(10, n + 1)) / Math.pow(10, 1)) / Math.pow(10, n);
  return val;
}

/**
 * 数字简写格式化
 * @param num 原始数字
 * @param places 保留位数
 * @param prefix 前缀
 * @returns
 */
export function numberAbbr(n: any, places: number = 2, prefix: string = '') {
  let num = Number.parseFloat(n);
  if (Number.isNaN(num)) {
    return n;
  }
  let suffix = '';
  if (num >= 1e15) {
    num /= 1e15;
    suffix = 'Q';
  } else if (num >= 1e12) {
    num /= 1e12;
    suffix = 'T';
  } else if (num >= 1e9) {
    num /= 1e9;
    suffix = 'B';
  } else if (num >= 1e6) {
    num /= 1e6;
    suffix = 'M';
  } else if (num >= 1e3) {
    num /= 1e3;
    suffix = 'K';
  }
  return `${prefix} ${num.toFixed(places)}${suffix}`;
}

/**
 * 获取坐标中心
 * @param pointArray
 * @returns
 */
export function getCenter(pointArray: any) {
  //console.log(JSON.stringify(pointArray));
  var sortedLongitudeArray = pointArray.map(item => item.lng).sort(); //首先对经度进行排序，红色部分是array中经度的名称
  var sortedLatitudeArray = pointArray.map(item => item.lat).sort(); //对纬度进行排序，红色部分是array中纬度的名称
  var centerLongitude = (
    (parseFloat(sortedLongitudeArray[0]) + parseFloat(sortedLongitudeArray[sortedLongitudeArray.length - 1])) /
    2
  ).toFixed(4);
  const centerLatitude = (
    (parseFloat(sortedLatitudeArray[0]) + parseFloat(sortedLatitudeArray[sortedLatitudeArray.length - 1])) /
    2
  ).toFixed(4);

  return [centerLongitude, centerLatitude];
}

export function numToArray(n: number): number[] {
  const target: number[] = [];
  for (let index = 0; index < n; index++) {
    target.push(index);
  }
  return target;
}

/**
 * 平均值
 * @param type 1:日，2:周，3:月
 * @returns
 */
export function genMockData(type: 1 | 2 | 3, max: number, min: number, ave?: number) {
  // 月、周、日
  const date: any[] = [];
  const data: any[] = [];
  const aves: any[] = [];
  const now = new Date();
  let l = now;
  let i = 1;
  let num = 1;
  if (ave == undefined) {
    ave = (max + min) / 2;
  }

  if (type == 1) {
    num = 24;
    l = subHours(now, num);

    // 按2小时来累加
    do {
      date.push(format(l, 'MM/DD HH:mm'));
      data.push(ave + (i == 1 ? 0 : randSub()));
      aves.push(ave);
      l = addHours(l, 2);
      i += 2;
    } while (l <= now);
  } else if (type == 2) {
    // 最大值最小值，分为5份
    num = 5;
    l = subDays(now, num);

    // 按1天来累加
    do {
      let a = (max * i) / num;
      date.push(format(l, 'MM/DD'));
      data.push(ave + (i == 1 ? 0 : randSub()));
      aves.push(ave);
      l = addDays(l, 1);
      i += 1;
    } while (l <= now);
  } else if (type == 3) {
    // 最大值最小值，分为30份
    num = 30;
    l = subDays(now, num);

    // 按2天来累加
    do {
      let a = (max * i) / num;
      date.push(format(l, 'MM/DD'));
      data.push(ave + (i == 1 ? 0 : randSub()));
      aves.push(ave);
      l = addDays(l, 2);
      i += 2;
    } while (l <= now);
  }

  return {
    date,
    data,
    aves,
  };
}

/**
 * 随机加减
 * @returns
 */
export function randAddSub() {
  return randNum(0, 10) % 2 ? +randNum(0, 5) : -randNum(0, 5);
}

export function randSub() {
  return -randNum(0, 5);
}

// Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
export const randNum = (min: number = 0, max: number = 6) => Math.floor(Math.random() * (max - min + 1) + min);

export function remToPx(rem: number) {
  var fontSize = document.documentElement.style.fontSize;
  return Math.floor(rem * toNumber(fontSize.replace('px', '')));
}

export function omit(obj: any, fields: string[]): any {
  // eslint-disable-next-line prefer-object-spread
  const shallowCopy = Object.assign({}, obj);

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < fields.length; i += 1) {
    const key = fields[i];
    delete shallowCopy[key];
  }

  return shallowCopy;
}

export async function asyncForEach(arr: any[], cb: (el: any, i: number) => void) {
  if (Array.isArray(arr)) {
    for (let index = 0; index < arr.length; index++) {
      await cb(arr[index], index);
    }
  }
}
